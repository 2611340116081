export const data = [
  {
    name: "kuvio-6.6",
    names: ["Vuosimuutos, %"],
    height: 450,
    width: "100%",
    xAxisLabel: "Kuukausi",
    yAxisLabel: "%",
    yDomain: [-5, 15],
    manualTicksX: [
      "1980M08",
      "1985M08",
      "1990M08",
      "1995M08",
      "2000M08",
      "2005M08",
      "2010M08",
      "2015M08",
      "2020M08",
      "2022M08",
    ],
    manualTicksY: [-5, -3, -1, 1, 3, 5, 7, 9, 11, 13, 15],
    type: ["category", "number"],
    yAxisOffset: 0,
    xAxisOffset: 0,
    xAxisPadding: 20,
    minTickGapX: 5,
    xTickCount: 15,
    yTickCount: 15,
    yInterval: 0,
    xInterval: 0,
    xAxisHeight: 100,
    yAxisWidth: 80,
    margins: [25, 35, 0, 5],
    tickDx: 0,
    tickDy: 35,
    xLabelPosition: "insideBottom",
    xAxisLabelSideways: 90,
    fontSize: "0.8rem",
    xTickFontSize: "1rem",
    data: [
      {
        name: "1980M01",
        line1: 8.9,
      },
      {
        name: "1980M02",
        line1: 9.3,
      },
      {
        name: "1980M03",
        line1: 9.7,
      },
      {
        name: "1980M04",
        line1: 10.7,
      },
      {
        name: "1980M05",
        line1: 11.6,
      },
      {
        name: "1980M06",
        line1: 11.2,
      },
      {
        name: "1980M07",
        line1: 11.3,
      },
      {
        name: "1980M08",
        line1: 12.3,
      },
      {
        name: "1980M09",
        line1: 12.9,
      },
      {
        name: "1980M10",
        line1: 13.3,
      },
      {
        name: "1980M11",
        line1: 13.5,
      },
      {
        name: "1980M12",
        line1: 13.7,
      },
      {
        name: "1981M01",
        line1: 13.5,
      },
      {
        name: "1981M02",
        line1: 12.8,
      },
      {
        name: "1981M03",
        line1: 13.1,
      },
      {
        name: "1981M04",
        line1: 12.5,
      },
      {
        name: "1981M05",
        line1: 12.7,
      },
      {
        name: "1981M06",
        line1: 12.9,
      },
      {
        name: "1981M07",
        line1: 12.6,
      },
      {
        name: "1981M08",
        line1: 11.5,
      },
      {
        name: "1981M09",
        line1: 11.4,
      },
      {
        name: "1981M10",
        line1: 11.3,
      },
      {
        name: "1981M11",
        line1: 10.4,
      },
      {
        name: "1981M12",
        line1: 10,
      },
      {
        name: "1982M01",
        line1: 10.7,
      },
      {
        name: "1982M02",
        line1: 10.3,
      },
      {
        name: "1982M03",
        line1: 10.2,
      },
      {
        name: "1982M04",
        line1: 9.6,
      },
      {
        name: "1982M05",
        line1: 9.8,
      },
      {
        name: "1982M06",
        line1: 9.4,
      },
      {
        name: "1982M07",
        line1: 9.8,
      },
      {
        name: "1982M08",
        line1: 9.3,
      },
      {
        name: "1982M09",
        line1: 8.1,
      },
      {
        name: "1982M10",
        line1: 7.6,
      },
      {
        name: "1982M11",
        line1: 7.9,
      },
      {
        name: "1982M12",
        line1: 9,
      },
      {
        name: "1983M01",
        line1: 8.2,
      },
      {
        name: "1983M02",
        line1: 8.2,
      },
      {
        name: "1983M03",
        line1: 7.5,
      },
      {
        name: "1983M04",
        line1: 8,
      },
      {
        name: "1983M05",
        line1: 7.7,
      },
      {
        name: "1983M06",
        line1: 8.9,
      },
      {
        name: "1983M07",
        line1: 8.5,
      },
      {
        name: "1983M08",
        line1: 9,
      },
      {
        name: "1983M09",
        line1: 9.4,
      },
      {
        name: "1983M10",
        line1: 9.1,
      },
      {
        name: "1983M11",
        line1: 9.8,
      },
      {
        name: "1983M12",
        line1: 8.6,
      },
      {
        name: "1984M01",
        line1: 8.3,
      },
      {
        name: "1984M02",
        line1: 8.2,
      },
      {
        name: "1984M03",
        line1: 8.1,
      },
      {
        name: "1984M04",
        line1: 8,
      },
      {
        name: "1984M05",
        line1: 7.4,
      },
      {
        name: "1984M06",
        line1: 6.4,
      },
      {
        name: "1984M07",
        line1: 6.4,
      },
      {
        name: "1984M08",
        line1: 6.7,
      },
      {
        name: "1984M09",
        line1: 6.6,
      },
      {
        name: "1984M10",
        line1: 6.9,
      },
      {
        name: "1984M11",
        line1: 6,
      },
      {
        name: "1984M12",
        line1: 6.1,
      },
      {
        name: "1985M01",
        line1: 6.4,
      },
      {
        name: "1985M02",
        line1: 6.4,
      },
      {
        name: "1985M03",
        line1: 6.6,
      },
      {
        name: "1985M04",
        line1: 6.5,
      },
      {
        name: "1985M05",
        line1: 6.4,
      },
      {
        name: "1985M06",
        line1: 6.4,
      },
      {
        name: "1985M07",
        line1: 6.1,
      },
      {
        name: "1985M08",
        line1: 5.8,
      },
      {
        name: "1985M09",
        line1: 5.3,
      },
      {
        name: "1985M10",
        line1: 4.9,
      },
      {
        name: "1985M11",
        line1: 4.9,
      },
      {
        name: "1985M12",
        line1: 5,
      },
      {
        name: "1986M01",
        line1: 4.5,
      },
      {
        name: "1986M02",
        line1: 4.6,
      },
      {
        name: "1986M03",
        line1: 3.8,
      },
      {
        name: "1986M04",
        line1: 3.7,
      },
      {
        name: "1986M05",
        line1: 3,
      },
      {
        name: "1986M06",
        line1: 3.3,
      },
      {
        name: "1986M07",
        line1: 3.3,
      },
      {
        name: "1986M08",
        line1: 3.4,
      },
      {
        name: "1986M09",
        line1: 3.3,
      },
      {
        name: "1986M10",
        line1: 3.5,
      },
      {
        name: "1986M11",
        line1: 3.3,
      },
      {
        name: "1986M12",
        line1: 3.4,
      },
      {
        name: "1987M01",
        line1: 3.7,
      },
      {
        name: "1987M02",
        line1: 3.5,
      },
      {
        name: "1987M03",
        line1: 3.9,
      },
      {
        name: "1987M04",
        line1: 3.9,
      },
      {
        name: "1987M05",
        line1: 3.7,
      },
      {
        name: "1987M06",
        line1: 3.5,
      },
      {
        name: "1987M07",
        line1: 3.4,
      },
      {
        name: "1987M08",
        line1: 3.4,
      },
      {
        name: "1987M09",
        line1: 3.9,
      },
      {
        name: "1987M10",
        line1: 3.7,
      },
      {
        name: "1987M11",
        line1: 3.7,
      },
      {
        name: "1987M12",
        line1: 3.7,
      },
      {
        name: "1988M01",
        line1: 4.2,
      },
      {
        name: "1988M02",
        line1: 4.1,
      },
      {
        name: "1988M03",
        line1: 4.1,
      },
      {
        name: "1988M04",
        line1: 4.4,
      },
      {
        name: "1988M05",
        line1: 5.1,
      },
      {
        name: "1988M06",
        line1: 5.3,
      },
      {
        name: "1988M07",
        line1: 5.2,
      },
      {
        name: "1988M08",
        line1: 5.2,
      },
      {
        name: "1988M09",
        line1: 5.6,
      },
      {
        name: "1988M10",
        line1: 5.6,
      },
      {
        name: "1988M11",
        line1: 5.9,
      },
      {
        name: "1988M12",
        line1: 6.5,
      },
      {
        name: "1989M01",
        line1: 5.8,
      },
      {
        name: "1989M02",
        line1: 6,
      },
      {
        name: "1989M03",
        line1: 6.6,
      },
      {
        name: "1989M04",
        line1: 6.9,
      },
      {
        name: "1989M05",
        line1: 6.4,
      },
      {
        name: "1989M06",
        line1: 6.8,
      },
      {
        name: "1989M07",
        line1: 6.7,
      },
      {
        name: "1989M08",
        line1: 6.6,
      },
      {
        name: "1989M09",
        line1: 6.8,
      },
      {
        name: "1989M10",
        line1: 7.1,
      },
      {
        name: "1989M11",
        line1: 6.8,
      },
      {
        name: "1989M12",
        line1: 6.5,
      },
      {
        name: "1990M01",
        line1: 7.5,
      },
      {
        name: "1990M02",
        line1: 7.4,
      },
      {
        name: "1990M03",
        line1: 6.5,
      },
      {
        name: "1990M04",
        line1: 6.2,
      },
      {
        name: "1990M05",
        line1: 6.2,
      },
      {
        name: "1990M06",
        line1: 5.6,
      },
      {
        name: "1990M07",
        line1: 5.9,
      },
      {
        name: "1990M08",
        line1: 6.2,
      },
      {
        name: "1990M09",
        line1: 5.6,
      },
      {
        name: "1990M10",
        line1: 5.5,
      },
      {
        name: "1990M11",
        line1: 5.6,
      },
      {
        name: "1990M12",
        line1: 4.9,
      },
      {
        name: "1991M01",
        line1: 4.9,
      },
      {
        name: "1991M02",
        line1: 5,
      },
      {
        name: "1991M03",
        line1: 4.8,
      },
      {
        name: "1991M04",
        line1: 4.6,
      },
      {
        name: "1991M05",
        line1: 4.6,
      },
      {
        name: "1991M06",
        line1: 4.2,
      },
      {
        name: "1991M07",
        line1: 4,
      },
      {
        name: "1991M08",
        line1: 3.6,
      },
      {
        name: "1991M09",
        line1: 3.3,
      },
      {
        name: "1991M10",
        line1: 3.2,
      },
      {
        name: "1991M11",
        line1: 3.3,
      },
      {
        name: "1991M12",
        line1: 3.9,
      },
      {
        name: "1992M01",
        line1: 2.9,
      },
      {
        name: "1992M02",
        line1: 2.6,
      },
      {
        name: "1992M03",
        line1: 2.8,
      },
      {
        name: "1992M04",
        line1: 2.8,
      },
      {
        name: "1992M05",
        line1: 2.4,
      },
      {
        name: "1992M06",
        line1: 2.7,
      },
      {
        name: "1992M07",
        line1: 2.6,
      },
      {
        name: "1992M08",
        line1: 2.4,
      },
      {
        name: "1992M09",
        line1: 2.6,
      },
      {
        name: "1992M10",
        line1: 2.7,
      },
      {
        name: "1992M11",
        line1: 2.8,
      },
      {
        name: "1992M12",
        line1: 2.1,
      },
      {
        name: "1993M01",
        line1: 2.9,
      },
      {
        name: "1993M02",
        line1: 2.9,
      },
      {
        name: "1993M03",
        line1: 2.7,
      },
      {
        name: "1993M04",
        line1: 2.6,
      },
      {
        name: "1993M05",
        line1: 2.6,
      },
      {
        name: "1993M06",
        line1: 2.1,
      },
      {
        name: "1993M07",
        line1: 2.1,
      },
      {
        name: "1993M08",
        line1: 2.1,
      },
      {
        name: "1993M09",
        line1: 1.8,
      },
      {
        name: "1993M10",
        line1: 1.6,
      },
      {
        name: "1993M11",
        line1: 1.3,
      },
      {
        name: "1993M12",
        line1: 1.5,
      },
      {
        name: "1994M01",
        line1: 0.2,
      },
      {
        name: "1994M02",
        line1: 0.3,
      },
      {
        name: "1994M03",
        line1: 0.4,
      },
      {
        name: "1994M04",
        line1: 0.2,
      },
      {
        name: "1994M05",
        line1: 0.2,
      },
      {
        name: "1994M06",
        line1: 1.3,
      },
      {
        name: "1994M07",
        line1: 1.6,
      },
      {
        name: "1994M08",
        line1: 1.9,
      },
      {
        name: "1994M09",
        line1: 1.9,
      },
      {
        name: "1994M10",
        line1: 1.9,
      },
      {
        name: "1994M11",
        line1: 1.7,
      },
      {
        name: "1994M12",
        line1: 1.6,
      },
      {
        name: "1995M01",
        line1: 1.9,
      },
      {
        name: "1995M02",
        line1: 1.8,
      },
      {
        name: "1995M03",
        line1: 1.7,
      },
      {
        name: "1995M04",
        line1: 1.5,
      },
      {
        name: "1995M05",
        line1: 1.6,
      },
      {
        name: "1995M06",
        line1: 0.9,
      },
      {
        name: "1995M07",
        line1: 0.8,
      },
      {
        name: "1995M08",
        line1: 0.5,
      },
      {
        name: "1995M09",
        line1: 0.3,
      },
      {
        name: "1995M10",
        line1: 0.3,
      },
      {
        name: "1995M11",
        line1: 0.3,
      },
      {
        name: "1995M12",
        line1: 0.3,
      },
      {
        name: "1996M01",
        line1: 0.5,
      },
      {
        name: "1996M02",
        line1: 0.5,
      },
      {
        name: "1996M03",
        line1: 0.6,
      },
      {
        name: "1996M04",
        line1: 0.7,
      },
      {
        name: "1996M05",
        line1: 0.7,
      },
      {
        name: "1996M06",
        line1: 0.4,
      },
      {
        name: "1996M07",
        line1: 0.5,
      },
      {
        name: "1996M08",
        line1: 0.4,
      },
      {
        name: "1996M09",
        line1: 0.5,
      },
      {
        name: "1996M10",
        line1: 0.7,
      },
      {
        name: "1996M11",
        line1: 0.7,
      },
      {
        name: "1996M12",
        line1: 0.8,
      },
      {
        name: "1997M01",
        line1: 0.6,
      },
      {
        name: "1997M02",
        line1: 0.4,
      },
      {
        name: "1997M03",
        line1: 0.6,
      },
      {
        name: "1997M04",
        line1: 1,
      },
      {
        name: "1997M05",
        line1: 1,
      },
      {
        name: "1997M06",
        line1: 1.2,
      },
      {
        name: "1997M07",
        line1: 1.2,
      },
      {
        name: "1997M08",
        line1: 1.6,
      },
      {
        name: "1997M09",
        line1: 1.6,
      },
      {
        name: "1997M10",
        line1: 1.7,
      },
      {
        name: "1997M11",
        line1: 1.9,
      },
      {
        name: "1997M12",
        line1: 1.9,
      },
      {
        name: "1998M01",
        line1: 1.9,
      },
      {
        name: "1998M02",
        line1: 1.9,
      },
      {
        name: "1998M03",
        line1: 1.8,
      },
      {
        name: "1998M04",
        line1: 1.8,
      },
      {
        name: "1998M05",
        line1: 1.5,
      },
      {
        name: "1998M06",
        line1: 1.5,
      },
      {
        name: "1998M07",
        line1: 1.1,
      },
      {
        name: "1998M08",
        line1: 1.1,
      },
      {
        name: "1998M09",
        line1: 1.3,
      },
      {
        name: "1998M10",
        line1: 1.1,
      },
      {
        name: "1998M11",
        line1: 0.9,
      },
      {
        name: "1998M12",
        line1: 0.8,
      },
      {
        name: "1999M01",
        line1: 0.5,
      },
      {
        name: "1999M02",
        line1: 0.8,
      },
      {
        name: "1999M03",
        line1: 0.8,
      },
      {
        name: "1999M04",
        line1: 1.1,
      },
      {
        name: "1999M05",
        line1: 1.4,
      },
      {
        name: "1999M06",
        line1: 1.1,
      },
      {
        name: "1999M07",
        line1: 1.2,
      },
      {
        name: "1999M08",
        line1: 1.1,
      },
      {
        name: "1999M09",
        line1: 1.1,
      },
      {
        name: "1999M10",
        line1: 1.3,
      },
      {
        name: "1999M11",
        line1: 1.6,
      },
      {
        name: "1999M12",
        line1: 2,
      },
      {
        name: "2000M01",
        line1: 2.2,
      },
      {
        name: "2000M02",
        line1: 2.7,
      },
      {
        name: "2000M03",
        line1: 3.1,
      },
      {
        name: "2000M04",
        line1: 2.7,
      },
      {
        name: "2000M05",
        line1: 2.9,
      },
      {
        name: "2000M06",
        line1: 3.5,
      },
      {
        name: "2000M07",
        line1: 3.7,
      },
      {
        name: "2000M08",
        line1: 3.8,
      },
      {
        name: "2000M09",
        line1: 4.2,
      },
      {
        name: "2000M10",
        line1: 4.1,
      },
      {
        name: "2000M11",
        line1: 4,
      },
      {
        name: "2000M12",
        line1: 3.5,
      },
      {
        name: "2001M01",
        line1: 3.3,
      },
      {
        name: "2001M02",
        line1: 3.1,
      },
      {
        name: "2001M03",
        line1: 2.9,
      },
      {
        name: "2001M04",
        line1: 3,
      },
      {
        name: "2001M05",
        line1: 3.4,
      },
      {
        name: "2001M06",
        line1: 3,
      },
      {
        name: "2001M07",
        line1: 2.5,
      },
      {
        name: "2001M08",
        line1: 2.4,
      },
      {
        name: "2001M09",
        line1: 2.2,
      },
      {
        name: "2001M10",
        line1: 1.9,
      },
      {
        name: "2001M11",
        line1: 1.6,
      },
      {
        name: "2001M12",
        line1: 1.6,
      },
      {
        name: "2002M01",
        line1: 2.3,
      },
      {
        name: "2002M02",
        line1: 1.8,
      },
      {
        name: "2002M03",
        line1: 1.8,
      },
      {
        name: "2002M04",
        line1: 1.8,
      },
      {
        name: "2002M05",
        line1: 1.3,
      },
      {
        name: "2002M06",
        line1: 1.1,
      },
      {
        name: "2002M07",
        line1: 1.7,
      },
      {
        name: "2002M08",
        line1: 1.4,
      },
      {
        name: "2002M09",
        line1: 1,
      },
      {
        name: "2002M10",
        line1: 1.5,
      },
      {
        name: "2002M11",
        line1: 1.6,
      },
      {
        name: "2002M12",
        line1: 1.7,
      },
      {
        name: "2003M01",
        line1: 1.4,
      },
      {
        name: "2003M02",
        line1: 1.9,
      },
      {
        name: "2003M03",
        line1: 1.6,
      },
      {
        name: "2003M04",
        line1: 1.1,
      },
      {
        name: "2003M05",
        line1: 0.7,
      },
      {
        name: "2003M06",
        line1: 0.8,
      },
      {
        name: "2003M07",
        line1: 0.5,
      },
      {
        name: "2003M08",
        line1: 0.6,
      },
      {
        name: "2003M09",
        line1: 0.7,
      },
      {
        name: "2003M10",
        line1: 0.3,
      },
      {
        name: "2003M11",
        line1: 0.5,
      },
      {
        name: "2003M12",
        line1: 0.6,
      },
      {
        name: "2004M01",
        line1: 0.2,
      },
      {
        name: "2004M02",
        line1: 0.1,
      },
      {
        name: "2004M03",
        line1: -0.5,
      },
      {
        name: "2004M04",
        line1: -0.4,
      },
      {
        name: "2004M05",
        line1: -0.1,
      },
      {
        name: "2004M06",
        line1: -0.1,
      },
      {
        name: "2004M07",
        line1: 0.3,
      },
      {
        name: "2004M08",
        line1: 0.4,
      },
      {
        name: "2004M09",
        line1: 0.4,
      },
      {
        name: "2004M10",
        line1: 0.9,
      },
      {
        name: "2004M11",
        line1: 0.5,
      },
      {
        name: "2004M12",
        line1: 0.4,
      },
      {
        name: "2005M01",
        line1: 0.2,
      },
      {
        name: "2005M02",
        line1: 0.2,
      },
      {
        name: "2005M03",
        line1: 1,
      },
      {
        name: "2005M04",
        line1: 1.2,
      },
      {
        name: "2005M05",
        line1: 0.8,
      },
      {
        name: "2005M06",
        line1: 1.1,
      },
      {
        name: "2005M07",
        line1: 1,
      },
      {
        name: "2005M08",
        line1: 1,
      },
      {
        name: "2005M09",
        line1: 1.2,
      },
      {
        name: "2005M10",
        line1: 0.8,
      },
      {
        name: "2005M11",
        line1: 0.9,
      },
      {
        name: "2005M12",
        line1: 1,
      },
      {
        name: "2006M01",
        line1: 0.8,
      },
      {
        name: "2006M02",
        line1: 0.9,
      },
      {
        name: "2006M03",
        line1: 0.9,
      },
      {
        name: "2006M04",
        line1: 1.3,
      },
      {
        name: "2006M05",
        line1: 1.7,
      },
      {
        name: "2006M06",
        line1: 1.7,
      },
      {
        name: "2006M07",
        line1: 1.9,
      },
      {
        name: "2006M08",
        line1: 1.9,
      },
      {
        name: "2006M09",
        line1: 1.5,
      },
      {
        name: "2006M10",
        line1: 1.8,
      },
      {
        name: "2006M11",
        line1: 2.1,
      },
      {
        name: "2006M12",
        line1: 2.2,
      },
      {
        name: "2007M01",
        line1: 2.3,
      },
      {
        name: "2007M02",
        line1: 2.2,
      },
      {
        name: "2007M03",
        line1: 2.6,
      },
      {
        name: "2007M04",
        line1: 2.6,
      },
      {
        name: "2007M05",
        line1: 2.4,
      },
      {
        name: "2007M06",
        line1: 2.4,
      },
      {
        name: "2007M07",
        line1: 2.6,
      },
      {
        name: "2007M08",
        line1: 2.3,
      },
      {
        name: "2007M09",
        line1: 2.6,
      },
      {
        name: "2007M10",
        line1: 2.7,
      },
      {
        name: "2007M11",
        line1: 2.9,
      },
      {
        name: "2007M12",
        line1: 2.6,
      },
      {
        name: "2008M01",
        line1: 3.8,
      },
      {
        name: "2008M02",
        line1: 3.7,
      },
      {
        name: "2008M03",
        line1: 3.9,
      },
      {
        name: "2008M04",
        line1: 3.5,
      },
      {
        name: "2008M05",
        line1: 4.2,
      },
      {
        name: "2008M06",
        line1: 4.4,
      },
      {
        name: "2008M07",
        line1: 4.4,
      },
      {
        name: "2008M08",
        line1: 4.7,
      },
      {
        name: "2008M09",
        line1: 4.7,
      },
      {
        name: "2008M10",
        line1: 4.4,
      },
      {
        name: "2008M11",
        line1: 3.6,
      },
      {
        name: "2008M12",
        line1: 3.5,
      },
      {
        name: "2009M01",
        line1: 2.2,
      },
      {
        name: "2009M02",
        line1: 1.7,
      },
      {
        name: "2009M03",
        line1: 0.9,
      },
      {
        name: "2009M04",
        line1: 0.8,
      },
      {
        name: "2009M05",
        line1: 0,
      },
      {
        name: "2009M06",
        line1: -0.1,
      },
      {
        name: "2009M07",
        line1: -0.6,
      },
      {
        name: "2009M08",
        line1: -0.7,
      },
      {
        name: "2009M09",
        line1: -1,
      },
      {
        name: "2009M10",
        line1: -1.5,
      },
      {
        name: "2009M11",
        line1: -0.9,
      },
      {
        name: "2009M12",
        line1: -0.5,
      },
      {
        name: "2010M01",
        line1: -0.2,
      },
      {
        name: "2010M02",
        line1: 0.1,
      },
      {
        name: "2010M03",
        line1: 0.6,
      },
      {
        name: "2010M04",
        line1: 0.9,
      },
      {
        name: "2010M05",
        line1: 1,
      },
      {
        name: "2010M06",
        line1: 0.9,
      },
      {
        name: "2010M07",
        line1: 1.1,
      },
      {
        name: "2010M08",
        line1: 1.2,
      },
      {
        name: "2010M09",
        line1: 1.4,
      },
      {
        name: "2010M10",
        line1: 2.3,
      },
      {
        name: "2010M11",
        line1: 2.5,
      },
      {
        name: "2010M12",
        line1: 2.9,
      },
      {
        name: "2011M01",
        line1: 3,
      },
      {
        name: "2011M02",
        line1: 3.3,
      },
      {
        name: "2011M03",
        line1: 3.3,
      },
      {
        name: "2011M04",
        line1: 3.2,
      },
      {
        name: "2011M05",
        line1: 3.3,
      },
      {
        name: "2011M06",
        line1: 3.5,
      },
      {
        name: "2011M07",
        line1: 4,
      },
      {
        name: "2011M08",
        line1: 3.8,
      },
      {
        name: "2011M09",
        line1: 3.7,
      },
      {
        name: "2011M10",
        line1: 3.5,
      },
      {
        name: "2011M11",
        line1: 3.4,
      },
      {
        name: "2011M12",
        line1: 2.9,
      },
      {
        name: "2012M01",
        line1: 3.2,
      },
      {
        name: "2012M02",
        line1: 3.1,
      },
      {
        name: "2012M03",
        line1: 2.9,
      },
      {
        name: "2012M04",
        line1: 3.1,
      },
      {
        name: "2012M05",
        line1: 3.1,
      },
      {
        name: "2012M06",
        line1: 2.8,
      },
      {
        name: "2012M07",
        line1: 2.9,
      },
      {
        name: "2012M08",
        line1: 2.7,
      },
      {
        name: "2012M09",
        line1: 2.7,
      },
      {
        name: "2012M10",
        line1: 2.6,
      },
      {
        name: "2012M11",
        line1: 2.2,
      },
      {
        name: "2012M12",
        line1: 2.4,
      },
      {
        name: "2013M01",
        line1: 1.6,
      },
      {
        name: "2013M02",
        line1: 1.7,
      },
      {
        name: "2013M03",
        line1: 1.7,
      },
      {
        name: "2013M04",
        line1: 1.5,
      },
      {
        name: "2013M05",
        line1: 1.6,
      },
      {
        name: "2013M06",
        line1: 1.4,
      },
      {
        name: "2013M07",
        line1: 1.6,
      },
      {
        name: "2013M08",
        line1: 1.2,
      },
      {
        name: "2013M09",
        line1: 1.2,
      },
      {
        name: "2013M10",
        line1: 1.2,
      },
      {
        name: "2013M11",
        line1: 1.4,
      },
      {
        name: "2013M12",
        line1: 1.6,
      },
      {
        name: "2014M01",
        line1: 1.6,
      },
      {
        name: "2014M02",
        line1: 1.3,
      },
      {
        name: "2014M03",
        line1: 1.1,
      },
      {
        name: "2014M04",
        line1: 1.1,
      },
      {
        name: "2014M05",
        line1: 0.8,
      },
      {
        name: "2014M06",
        line1: 0.9,
      },
      {
        name: "2014M07",
        line1: 0.8,
      },
      {
        name: "2014M08",
        line1: 1.1,
      },
      {
        name: "2014M09",
        line1: 1.3,
      },
      {
        name: "2014M10",
        line1: 1,
      },
      {
        name: "2014M11",
        line1: 1,
      },
      {
        name: "2014M12",
        line1: 0.5,
      },
      {
        name: "2015M01",
        line1: -0.2,
      },
      {
        name: "2015M02",
        line1: -0.1,
      },
      {
        name: "2015M03",
        line1: -0.1,
      },
      {
        name: "2015M04",
        line1: -0.2,
      },
      {
        name: "2015M05",
        line1: 0,
      },
      {
        name: "2015M06",
        line1: -0.1,
      },
      {
        name: "2015M07",
        line1: -0.2,
      },
      {
        name: "2015M08",
        line1: -0.2,
      },
      {
        name: "2015M09",
        line1: -0.6,
      },
      {
        name: "2015M10",
        line1: -0.3,
      },
      {
        name: "2015M11",
        line1: -0.2,
      },
      {
        name: "2015M12",
        line1: -0.2,
      },
      {
        name: "2016M01",
        line1: 0,
      },
      {
        name: "2016M02",
        line1: -0.1,
      },
      {
        name: "2016M03",
        line1: 0,
      },
      {
        name: "2016M04",
        line1: 0.3,
      },
      {
        name: "2016M05",
        line1: 0.3,
      },
      {
        name: "2016M06",
        line1: 0.4,
      },
      {
        name: "2016M07",
        line1: 0.5,
      },
      {
        name: "2016M08",
        line1: 0.4,
      },
      {
        name: "2016M09",
        line1: 0.4,
      },
      {
        name: "2016M10",
        line1: 0.5,
      },
      {
        name: "2016M11",
        line1: 0.7,
      },
      {
        name: "2016M12",
        line1: 1,
      },
      {
        name: "2017M01",
        line1: 0.8,
      },
      {
        name: "2017M02",
        line1: 1.2,
      },
      {
        name: "2017M03",
        line1: 0.8,
      },
      {
        name: "2017M04",
        line1: 0.8,
      },
      {
        name: "2017M05",
        line1: 0.7,
      },
      {
        name: "2017M06",
        line1: 0.7,
      },
      {
        name: "2017M07",
        line1: 0.5,
      },
      {
        name: "2017M08",
        line1: 0.7,
      },
      {
        name: "2017M09",
        line1: 0.8,
      },
      {
        name: "2017M10",
        line1: 0.5,
      },
      {
        name: "2017M11",
        line1: 0.8,
      },
      {
        name: "2017M12",
        line1: 0.5,
      },
      {
        name: "2018M01",
        line1: 0.8,
      },
      {
        name: "2018M02",
        line1: 0.6,
      },
      {
        name: "2018M03",
        line1: 0.8,
      },
      {
        name: "2018M04",
        line1: 0.8,
      },
      {
        name: "2018M05",
        line1: 1,
      },
      {
        name: "2018M06",
        line1: 1.2,
      },
      {
        name: "2018M07",
        line1: 1.4,
      },
      {
        name: "2018M08",
        line1: 1.3,
      },
      {
        name: "2018M09",
        line1: 1.3,
      },
      {
        name: "2018M10",
        line1: 1.5,
      },
      {
        name: "2018M11",
        line1: 1.3,
      },
      {
        name: "2018M12",
        line1: 1.2,
      },
      {
        name: "2019M01",
        line1: 1.1,
      },
      {
        name: "2019M02",
        line1: 1.3,
      },
      {
        name: "2019M03",
        line1: 1.1,
      },
      {
        name: "2019M04",
        line1: 1.5,
      },
      {
        name: "2019M05",
        line1: 1.2,
      },
      {
        name: "2019M06",
        line1: 1,
      },
      {
        name: "2019M07",
        line1: 0.8,
      },
      {
        name: "2019M08",
        line1: 1.1,
      },
      {
        name: "2019M09",
        line1: 0.9,
      },
      {
        name: "2019M10",
        line1: 0.7,
      },
      {
        name: "2019M11",
        line1: 0.7,
      },
      {
        name: "2019M12",
        line1: 0.9,
      },
      {
        name: "2020M01",
        line1: 1,
      },
      {
        name: "2020M02",
        line1: 0.8,
      },
      {
        name: "2020M03",
        line1: 0.6,
      },
      {
        name: "2020M04",
        line1: -0.3,
      },
      {
        name: "2020M05",
        line1: -0.2,
      },
      {
        name: "2020M06",
        line1: 0,
      },
      {
        name: "2020M07",
        line1: 0.6,
      },
      {
        name: "2020M08",
        line1: 0.2,
      },
      {
        name: "2020M09",
        line1: 0.2,
      },
      {
        name: "2020M10",
        line1: 0.2,
      },
      {
        name: "2020M11",
        line1: 0.2,
      },
      {
        name: "2020M12",
        line1: 0.2,
      },
      {
        name: "2021M01",
        line1: 0.9,
      },
      {
        name: "2021M02",
        line1: 0.9,
      },
      {
        name: "2021M03",
        line1: 1.3,
      },
      {
        name: "2021M04",
        line1: 2.1,
      },
      {
        name: "2021M05",
        line1: 2.2,
      },
      {
        name: "2021M06",
        line1: 2,
      },
      {
        name: "2021M07",
        line1: 1.9,
      },
      {
        name: "2021M08",
        line1: 2.2,
      },
      {
        name: "2021M09",
        line1: 2.5,
      },
      {
        name: "2021M10",
        line1: 3.2,
      },
      {
        name: "2021M11",
        line1: 3.7,
      },
      {
        name: "2021M12",
        line1: 3.5,
      },
      {
        name: "2022M01",
        line1: 4.4,
      },
      {
        name: "2022M02",
        line1: 4.5,
      },
      {
        name: "2022M03",
        line1: 5.8,
      },
      {
        name: "2022M04",
        line1: 5.7,
      },
      {
        name: "2022M05",
        line1: 7,
      },
      {
        name: "2022M06",
        line1: 7.8,
      },
      {
        name: "2022M07",
        line1: 7.8,
      },
      {
        name: "2022M08",
        line1: 7.6,
      },
    ],
  },
  {
    name: "kuvio-3.12",
    source:
      "Kuvio 12. Lorenz-käyrä Suomessa kahtena eri vuonna. Lähde: Marja Riihelän laskelmat tulonjakotilaston pohjalta. Kuluttajahintaindeksi, Tilastokeskus",
    xAxisLabel: "Väestöosuus",
    yAxisLabel: "Tulo-osuus",
    yAxisOffset: 40,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 50,
    yAxisWidth: 50,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    chooseReference: 1,
    names: ["Tasajakosuora", "Lorenz-kärä 1987", "Lorenz-käyrä 2018"],
    manualTicksX: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    type: ["number", "number"],
    xDomain: [0, 100],
    data: [
      {
        name: 1,
        line1: 1,
        line2: 0.198296062,
        line3: 0.19471898,
      },
      {
        name: 2,
        line1: 2,
        line2: 0.531620742,
        line3: 0.515650434,
      },
      {
        name: 3,
        line1: 3,
        line2: 0.935339439,
        line3: 0.883663411,
      },
      {
        name: 4,
        line1: 4,
        line2: 1.385002513,
        line3: 1.271300542,
      },
      {
        name: 5,
        line1: 5,
        line2: 1.864039642,
        line3: 1.693332207,
      },
      {
        name: 6,
        line1: 6,
        line2: 2.37872994,
        line3: 2.127547574,
      },
      {
        name: 7,
        line1: 7,
        line2: 2.907042834,
        line3: 2.595959755,
      },
      {
        name: 8,
        line1: 8,
        line2: 3.482447308,
        line3: 3.057982458,
      },
      {
        name: 9,
        line1: 9,
        line2: 4.024959425,
        line3: 3.540093463,
      },
      {
        name: 10,
        line1: 10,
        line2: 4.602961265,
        line3: 4.035317397,
      },
      {
        name: 11,
        line1: 11,
        line2: 5.198253575,
        line3: 4.5459794,
      },
      {
        name: 12,
        line1: 12,
        line2: 5.805352586,
        line3: 5.07050372,
      },
      {
        name: 13,
        line1: 13,
        line2: 6.424699188,
        line3: 5.596295849,
      },
      {
        name: 14,
        line1: 14,
        line2: 7.052185177,
        line3: 6.131957017,
      },
      {
        name: 15,
        line1: 15,
        line2: 7.684869762,
        line3: 6.68280056,
      },
      {
        name: 16,
        line1: 16,
        line2: 8.332946454,
        line3: 7.238186861,
      },
      {
        name: 17,
        line1: 17,
        line2: 8.993253089,
        line3: 7.823399606,
      },
      {
        name: 18,
        line1: 18,
        line2: 9.660155443,
        line3: 8.382692665,
      },
      {
        name: 19,
        line1: 19,
        line2: 10.34937769,
        line3: 8.970376814,
      },
      {
        name: 20,
        line1: 20,
        line2: 11.03836887,
        line3: 9.564676613,
      },
      {
        name: 21,
        line1: 21,
        line2: 11.7365333,
        line3: 10.17118628,
      },
      {
        name: 22,
        line1: 22,
        line2: 12.45256087,
        line3: 10.78887648,
      },
      {
        name: 23,
        line1: 23,
        line2: 13.17042268,
        line3: 11.41926633,
      },
      {
        name: 24,
        line1: 24,
        line2: 13.90423963,
        line3: 12.04659661,
      },
      {
        name: 25,
        line1: 25,
        line2: 14.63685345,
        line3: 12.69247733,
      },
      {
        name: 26,
        line1: 26,
        line2: 15.39119079,
        line3: 13.33847862,
      },
      {
        name: 27,
        line1: 27,
        line2: 16.14593251,
        line3: 14.00344881,
      },
      {
        name: 28,
        line1: 28,
        line2: 16.91060585,
        line3: 14.67943854,
      },
      {
        name: 29,
        line1: 29,
        line2: 17.68435955,
        line3: 15.35837628,
      },
      {
        name: 30,
        line1: 30,
        line2: 18.47453273,
        line3: 16.04684127,
      },
      {
        name: 31,
        line1: 31,
        line2: 19.26944328,
        line3: 16.74044618,
      },
      {
        name: 32,
        line1: 32,
        line2: 20.06846636,
        line3: 17.45050728,
      },
      {
        name: 33,
        line1: 33,
        line2: 20.88181234,
        line3: 18.1715855,
      },
      {
        name: 34,
        line1: 34,
        line2: 21.71223422,
        line3: 18.89107806,
      },
      {
        name: 35,
        line1: 35,
        line2: 22.5299062,
        line3: 19.61998172,
      },
      {
        name: 36,
        line1: 36,
        line2: 23.3756732,
        line3: 20.35759679,
      },
      {
        name: 37,
        line1: 37,
        line2: 24.212688,
        line3: 21.11090758,
      },
      {
        name: 38,
        line1: 38,
        line2: 25.07915355,
        line3: 21.86713625,
      },
      {
        name: 39,
        line1: 39,
        line2: 25.93714751,
        line3: 22.64439684,
      },
      {
        name: 40,
        line1: 40,
        line2: 26.82124793,
        line3: 23.42798995,
      },
      {
        name: 41,
        line1: 41,
        line2: 27.70717393,
        line3: 24.2001501,
      },
      {
        name: 42,
        line1: 42,
        line2: 28.59297942,
        line3: 25.02251632,
      },
      {
        name: 43,
        line1: 43,
        line2: 29.48331393,
        line3: 25.80470733,
      },
      {
        name: 44,
        line1: 44,
        line2: 30.39657546,
        line3: 26.62677384,
      },
      {
        name: 45,
        line1: 45,
        line2: 31.30223041,
        line3: 27.44500266,
      },
      {
        name: 46,
        line1: 46,
        line2: 32.23781891,
        line3: 28.26875282,
      },
      {
        name: 47,
        line1: 47,
        line2: 33.16492343,
        line3: 29.0984708,
      },
      {
        name: 48,
        line1: 48,
        line2: 34.09883871,
        line3: 29.97113784,
      },
      {
        name: 49,
        line1: 49,
        line2: 35.04603689,
        line3: 30.80452048,
      },
      {
        name: 50,
        line1: 50,
        line2: 35.99878752,
        line3: 31.67000358,
      },
      {
        name: 51,
        line1: 51,
        line2: 36.96506794,
        line3: 32.54232166,
      },
      {
        name: 52,
        line1: 52,
        line2: 37.93400095,
        line3: 33.43081336,
      },
      {
        name: 53,
        line1: 53,
        line2: 38.91553774,
        line3: 34.33108703,
      },
      {
        name: 54,
        line1: 54,
        line2: 39.90614812,
        line3: 35.22092059,
      },
      {
        name: 55,
        line1: 55,
        line2: 40.9097743,
        line3: 36.16184724,
      },
      {
        name: 56,
        line1: 56,
        line2: 41.9244481,
        line3: 37.07069905,
      },
      {
        name: 57,
        line1: 57,
        line2: 42.93202709,
        line3: 38.00201212,
      },
      {
        name: 58,
        line1: 58,
        line2: 43.96607315,
        line3: 38.94660176,
      },
      {
        name: 59,
        line1: 59,
        line2: 45.00284062,
        line3: 39.90892238,
      },
      {
        name: 60,
        line1: 60,
        line2: 46.03596579,
        line3: 40.90043261,
      },
      {
        name: 61,
        line1: 61,
        line2: 47.091642,
        line3: 41.8729365,
      },
      {
        name: 62,
        line1: 62,
        line2: 48.14923012,
        line3: 42.85808598,
      },
      {
        name: 63,
        line1: 63,
        line2: 49.21207919,
        line3: 43.86950295,
      },
      {
        name: 64,
        line1: 64,
        line2: 50.28667932,
        line3: 44.88806232,
      },
      {
        name: 65,
        line1: 65,
        line2: 51.36995248,
        line3: 45.91528421,
      },
      {
        name: 66,
        line1: 66,
        line2: 52.46387206,
        line3: 46.97273524,
      },
      {
        name: 67,
        line1: 67,
        line2: 53.57093436,
        line3: 48.03162563,
      },
      {
        name: 68,
        line1: 68,
        line2: 54.68340523,
        line3: 49.0831948,
      },
      {
        name: 69,
        line1: 69,
        line2: 55.81174379,
        line3: 50.16228753,
      },
      {
        name: 70,
        line1: 70,
        line2: 56.95011297,
        line3: 51.25543951,
      },
      {
        name: 71,
        line1: 71,
        line2: 58.08863163,
        line3: 52.35737405,
      },
      {
        name: 72,
        line1: 72,
        line2: 59.24088617,
        line3: 53.49835336,
      },
      {
        name: 73,
        line1: 73,
        line2: 60.40579395,
        line3: 54.61811732,
      },
      {
        name: 74,
        line1: 74,
        line2: 61.58412255,
        line3: 55.76468856,
      },
      {
        name: 75,
        line1: 75,
        line2: 62.77197327,
        line3: 56.92321231,
      },
      {
        name: 76,
        line1: 76,
        line2: 63.96764207,
        line3: 58.09828007,
      },
      {
        name: 77,
        line1: 77,
        line2: 65.17500093,
        line3: 59.29003229,
      },
      {
        name: 78,
        line1: 78,
        line2: 66.39482367,
        line3: 60.49982534,
      },
      {
        name: 79,
        line1: 79,
        line2: 67.63632919,
        line3: 61.72188056,
      },
      {
        name: 80,
        line1: 80,
        line2: 68.87643754,
        line3: 62.97176975,
      },
      {
        name: 81,
        line1: 81,
        line2: 70.15206998,
        line3: 64.25004193,
      },
      {
        name: 82,
        line1: 82,
        line2: 71.41549753,
        line3: 65.51814444,
      },
      {
        name: 83,
        line1: 83,
        line2: 72.7186633,
        line3: 66.82288499,
      },
      {
        name: 84,
        line1: 84,
        line2: 74.02334071,
        line3: 68.14239119,
      },
      {
        name: 85,
        line1: 85,
        line2: 75.35562774,
        line3: 69.49200799,
      },
      {
        name: 86,
        line1: 86,
        line2: 76.70185601,
        line3: 70.86552476,
      },
      {
        name: 87,
        line1: 87,
        line2: 78.07389123,
        line3: 72.26613323,
      },
      {
        name: 88,
        line1: 88,
        line2: 79.46797737,
        line3: 73.70229411,
      },
      {
        name: 89,
        line1: 89,
        line2: 80.88078804,
        line3: 75.1687307,
      },
      {
        name: 90,
        line1: 90,
        line2: 82.32687383,
        line3: 76.75090638,
      },
      {
        name: 91,
        line1: 91,
        line2: 83.79334945,
        line3: 78.2191909,
      },
      {
        name: 92,
        line1: 92,
        line2: 85.29995594,
        line3: 79.84232298,
      },
      {
        name: 93,
        line1: 93,
        line2: 86.82475656,
        line3: 81.46966911,
      },
      {
        name: 94,
        line1: 94,
        line2: 88.3949599,
        line3: 83.21196503,
      },
      {
        name: 95,
        line1: 95,
        line2: 90.00693846,
        line3: 85.04711214,
      },
      {
        name: 96,
        line1: 96,
        line2: 91.68424986,
        line3: 86.98203537,
      },
      {
        name: 97,
        line1: 97,
        line2: 93.42414893,
        line3: 89.08234827,
      },
      {
        name: 98,
        line1: 98,
        line2: 95.28305985,
        line3: 91.46660689,
      },
      {
        name: 99,
        line1: 99,
        line2: 97.31487676,
        line3: 94.31165709,
      },
      {
        name: 100,
        line1: 100,
        line2: 99.99999951,
        line3: 99.99999992,
      },
    ],
  },
  {
    name: "kuvio-3.14",
    source:
      "Kuvio 14. Ylimmän yhden prosentin osuus käytettävissä olevista tuloista Suomessa. Lähde: Marja Riihelän laskelmat tulonjakotilaston pohjalta",
    height: 500,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "Ylimmän 1 prosentin tulo-osuus, %",
    yAxisOffset: 140,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 50,
    yAxisWidth: 65,
    margins: [0, 15, 15, 5],
    tickDx: -15,
    fontSize: "0.8rem",
    xTickFontSize: "1rem",
    names: ["Tulo-osuus, %"],
    data: [
      {
        name: 1987,
        line1: 2.685066126,
      },
      {
        name: 1988,
        line1: 2.903243527,
      },
      {
        name: 1989,
        line1: 2.994403616,
      },
      {
        name: 1990,
        line1: 2.870773152,
      },
      {
        name: 1991,
        line1: 2.87019331,
      },
      {
        name: 1992,
        line1: 3.00633125,
      },
      {
        name: 1993,
        line1: 3.496885672,
      },
      {
        name: 1994,
        line1: 3.321105614,
      },
      {
        name: 1995,
        line1: 3.777952492,
      },
      {
        name: 1996,
        line1: 3.764165193,
      },
      {
        name: 1997,
        line1: 4.497595131,
      },
      {
        name: 1998,
        line1: 5.209495872,
      },
      {
        name: 1999,
        line1: 6.427272409,
      },
      {
        name: 2000,
        line1: 6.786768883,
      },
      {
        name: 2001,
        line1: 5.911187828,
      },
      {
        name: 2002,
        line1: 5.71404174,
      },
      {
        name: 2003,
        line1: 5.868733674,
      },
      {
        name: 2004,
        line1: 6.464598328,
      },
      {
        name: 2005,
        line1: 5.954931304,
      },
      {
        name: 2006,
        line1: 6.367299706,
      },
      {
        name: 2007,
        line1: 6.663575768,
      },
      {
        name: 2008,
        line1: 5.975692347,
      },
      {
        name: 2009,
        line1: 5.114578083,
      },
      {
        name: 2010,
        line1: 5.473225191,
      },
      {
        name: 2011,
        line1: 5.383351073,
      },
      {
        name: 2012,
        line1: 4.660708457,
      },
      {
        name: 2013,
        line1: 5.117438734,
      },
      {
        name: 2014,
        line1: 4.741704091,
      },
      {
        name: 2015,
        line1: 5.227823183,
      },
      {
        name: 2016,
        line1: 5.218168721,
      },
      {
        name: 2017,
        line1: 5.200559646,
      },
      {
        name: 2018,
        line1: 5.688342825,
      },
    ],
  },
  {
    name: "kuvio-3.15",
    height: 400,
    width: "100%",
    source:
      "Kuvio 15. Tuloerot Suomessa. Tuloerot kaventuivat 70- ja 80-luvuilla ja nousivat 90-luvulla. 2000-luvulla erot ovat vakiintuneet. Lähde: Tilastokeskus.",
    xAxisLabel: "Vuosi",
    yAxisLabel: "Gini, %",
    xTickFontSize: "1rem",
    yAxisOffset: 40,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 40,
    yAxisWidth: 50,
    margins: [0, 25, 15, 5],
    tickDx: 0,
    names: ["Käytettävissä olevat rahatulot", "Tuotannontekijätulot"],
    category: ["number", "number"],
    xDomain: ["dataMin", "dataMax"],
    data: [
      {
        name: 1966,
        line1: 31.1,
        line2: 39.7,
      },
      {
        name: 1971,
        line1: 27,
        line2: 39.8,
      },
      {
        name: 197,
        line1: 21.5,
        line2: 36.3,
      },
      {
        name: 1981,
        line1: 20.9,
        line2: 36.9,
      },
      {
        name: 1986,
        line1: 20.4,
        line2: 38.9,
      },
      {
        name: 1987,
        line1: 20.3,
        line2: 39.4,
      },
      {
        name: 1988,
        line1: 20.7,
        line2: 40.2,
      },
      {
        name: 1989,
        line1: 21.1,
        line2: 40.8,
      },
      {
        name: 1990,
        line1: 20.9,
        line2: 40.5,
      },
      {
        name: 1991,
        line1: 20.7,
        line2: 41.5,
      },
      {
        name: 1992,
        line1: 20.5,
        line2: 44.6,
      },
      {
        name: 1993,
        line1: 21.3,
        line2: 47.7,
      },
      {
        name: 1994,
        line1: 21.3,
        line2: 48.7,
      },
      {
        name: 1995,
        line1: 22.2,
        line2: 48.8,
      },
      {
        name: 1996,
        line1: 22.6,
        line2: 48.6,
      },
      {
        name: 1997,
        line1: 24,
        line2: 48.3,
      },
      {
        name: 1998,
        line1: 25.2,
        line2: 48.6,
      },
      {
        name: 1999,
        line1: 27,
        line2: 49.9,
      },
      {
        name: 2000,
        line1: 28.4,
        line2: 50.4,
      },
      {
        name: 2001,
        line1: 26.9,
        line2: 48.9,
      },
      {
        name: 2002,
        line1: 26.8,
        line2: 48.8,
      },
      {
        name: 2003,
        line1: 27.2,
        line2: 48.9,
      },
      {
        name: 2004,
        line1: 28.2,
        line2: 49.6,
      },
      {
        name: 2005,
        line1: 28.1,
        line2: 49.5,
      },
      {
        name: 2006,
        line1: 28.7,
        line2: 49.8,
      },
      {
        name: 2007,
        line1: 29.5,
        line2: 49.8,
      },
      {
        name: 2008,
        line1: 28.4,
        line2: 48.9,
      },
      {
        name: 2009,
        line1: 27.6,
        line2: 49.4,
      },
      {
        name: 2010,
        line1: 27.9,
        line2: 50.1,
      },
      {
        name: 2011,
        line1: 28.2,
        line2: 50.2,
      },
      {
        name: 2012,
        line1: 26.9,
        line2: 49.7,
      },
      {
        name: 2013,
        line1: 27.2,
        line2: 50.8,
      },
      {
        name: 2014,
        line1: 27,
        line2: 51.1,
      },
      {
        name: 2015,
        line1: 27.3,
        line2: 51.9,
      },
      {
        name: 2016,
        line1: 27.2,
        line2: 52,
      },
      {
        name: 2017,
        line1: 27.7,
        line2: 52.3,
      },
      {
        name: 2018,
        line1: 28.1,
        line2: 52.2,
      },
    ],
  },
  {
    name: "kuvio-5.3a",
    source: "",
    xAxisLabel: "Vuosi",
    yAxisLabel: "",
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 8,
    yAxisWidth: 60,
    xTickCount: 8,
    margins: [15, 15, 0, 5],
    tickDx: -20,
    connectNulls: true,
    height: 550,
    yDomain: [0, 55000],
    manualTicksY: [
      0, 5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000, 50000,
      55000, 60000,
    ],
    manualTicksX: [
      1820, 1840, 1860, 1880, 1900, 1920, 1940, 1960, 1980, 2000, 2020,
    ],
    names: [
      "Yhdysvallat",
      "Ruotsi",
      "Suomi",
      "Iso-Britannia",
      "Japani",
      "Venäjä",
      "Argentiina",
      "Maailma",
      "Kiina",
      "Intia",
      "Kenia",
    ],
    data: [
      {
        name: 1820,
        line1: 2674,
        line2: 1415,
        line3: 1313,
        line4: 3306,
        line5: 1317,

        line7: 1591,
        line8: 1102,
        line9: 882,
        line10: 937,
      },
      {
        name: 1821,
        line1: 2715,
        line2: 1457,

        line4: 3308,
      },
      {
        name: 1822,
        line1: 2791,
        line2: 1465,

        line4: 3315,
      },
      {
        name: 1823,
        line1: 2760,
        line2: 1449,

        line4: 3359,
      },
      {
        name: 1824,
        line1: 2836,
        line2: 1487,

        line4: 3492,
      },
      {
        name: 1825,
        line1: 2898,
        line2: 1527,

        line4: 3521,
      },
      {
        name: 1826,
        line1: 2918,
        line2: 1532,

        line4: 3290,
      },
      {
        name: 1827,
        line1: 2930,
        line2: 1458,

        line4: 3499,
      },
      {
        name: 1828,
        line1: 2930,
        line2: 1486,

        line4: 3461,
      },
      {
        name: 1829,
        line1: 2833,
        line2: 1506,

        line4: 3443,
      },
      {
        name: 1830,
        line1: 3039,
        line2: 1468,

        line4: 3550,

        line9: 846,
        line10: 945,
      },
      {
        name: 1831,
        line1: 3226,
        line2: 1497,

        line4: 3545,
      },
      {
        name: 1832,
        line1: 3343,
        line2: 1457,

        line4: 3612,
      },
      {
        name: 1833,
        line1: 3460,
        line2: 1503,

        line4: 3599,
      },
      {
        name: 1834,
        line1: 3295,
        line2: 1522,

        line4: 3639,
      },
      {
        name: 1835,
        line1: 3420,
        line2: 1517,

        line4: 3861,
      },
      {
        name: 1836,
        line1: 3470,
        line2: 1565,

        line4: 3937,
      },
      {
        name: 1837,
        line1: 3361,
        line2: 1554,

        line4: 3857,
      },
      {
        name: 1838,
        line1: 3330,
        line2: 1529,

        line4: 3963,
      },
      {
        name: 1839,
        line1: 3492,
        line2: 1546,

        line4: 3881,
      },
      {
        name: 1840,
        line1: 3319,
        line2: 1568,

        line4: 4018,

        line9: 849,
        line10: 944,
      },
      {
        name: 1841,
        line1: 3253,
        line2: 1549,

        line4: 3894,
      },
      {
        name: 1842,
        line1: 3223,
        line2: 1476,

        line4: 3808,
      },
      {
        name: 1843,
        line1: 3274,
        line2: 1508,

        line4: 3950,
      },
      {
        name: 1844,
        line1: 3453,
        line2: 1573,

        line4: 4245,
      },
      {
        name: 1845,
        line1: 3506,
        line2: 1610,

        line4: 4387,
      },
      {
        name: 1846,
        line1: 3526,
        line2: 1554,

        line4: 4334,
        line5: 1436,
      },
      {
        name: 1847,
        line1: 3619,
        line2: 1594,

        line4: 4234,
      },
      {
        name: 1848,
        line1: 3713,
        line2: 1612,

        line4: 4361,
      },
      {
        name: 1849,
        line1: 3616,
        line2: 1672,

        line4: 4422,
      },
      {
        name: 1850,
        line1: 3632,
        line2: 1715,
        line3: 1519,
        line4: 4332,
        line5: 1436,

        line7: 1994,
        line8: 1225,
        line9: 858,
        line10: 947,
      },
      {
        name: 1851,
        line1: 3778,
        line2: 1682,

        line4: 4477,
      },
      {
        name: 1852,
        line1: 3971,
        line2: 1645,

        line4: 4626,
      },
      {
        name: 1853,
        line1: 4242,
        line2: 1667,

        line4: 4734,
      },
      {
        name: 1854,
        line1: 4254,
        line2: 1685,

        line4: 4909,
      },
      {
        name: 1855,
        line1: 4161,
        line2: 1788,

        line4: 4748,
      },
      {
        name: 1856,
        line1: 4248,
        line2: 1771,

        line4: 5013,
      },
      {
        name: 1857,
        line1: 4156,
        line2: 1819,

        line4: 5019,
      },
      {
        name: 1858,
        line1: 4177,
        line2: 1841,

        line4: 4879,
      },
      {
        name: 1859,
        line1: 4289,
        line2: 1906,

        line4: 5031,
      },
      {
        name: 1860,
        line1: 4402,
        line2: 1941,
        line3: 1522,
        line4: 5086,

        line7: 2160,

        line10: 896,
      },
      {
        name: 1861,
        line1: 4312,
        line2: 1865,
        line3: 1527,
        line4: 5024,
      },
      {
        name: 1862,
        line1: 4447,
        line2: 1808,
        line3: 1428,
        line4: 4764,
      },
      {
        name: 1863,
        line1: 4734,
        line2: 1886,
        line3: 1527,
        line4: 5165,
      },
      {
        name: 1864,
        line1: 4889,
        line2: 1919,
        line3: 1545,
        line4: 5255,
      },
      {
        name: 1865,
        line1: 4637,
        line2: 1953,
        line3: 1516,
        line4: 5333,
      },
      {
        name: 1866,
        line1: 4600,
        line2: 1921,
        line3: 1527,
        line4: 5451,
      },
      {
        name: 1867,
        line1: 4752,
        line2: 1949,
        line3: 1412,
        line4: 5424,
      },
      {
        name: 1868,
        line1: 4816,
        line2: 1774,
        line3: 1599,
        line4: 5636,
      },
      {
        name: 1869,
        line1: 4943,
        line2: 1902,
        line3: 1755,
        line4: 5635,
      },
      {
        name: 1870,
        line1: 4803,
        line2: 2144,
        line3: 1817,
        line4: 5829,
        line5: 1580,

        line7: 2340,
        line8: 1498,
        line9: 945,
        line10: 850,
      },
      {
        name: 1871,
        line1: 4919,
        line2: 2219,
        line3: 1796,
        line4: 5797,
      },
      {
        name: 1872,
        line1: 4992,
        line2: 2259,
        line3: 1825,
        line4: 5769,
      },
      {
        name: 1873,
        line1: 5117,
        line2: 2284,
        line3: 1902,
        line4: 5842,
      },
      {
        name: 1874,
        line1: 4965,
        line2: 2370,
        line3: 1919,
        line4: 5874,
        line5: 1607,
      },
      {
        name: 1875,
        line1: 5105,
        line2: 2286,
        line3: 1930,
        line4: 5950,

        line7: 2606,
      },
      {
        name: 1876,
        line1: 5050,
        line2: 2431,
        line3: 2007,
        line4: 5938,

        line7: 2611,
      },
      {
        name: 1877,
        line1: 5099,
        line2: 2377,
        line3: 1930,
        line4: 5923,

        line7: 2820,
      },
      {
        name: 1878,
        line1: 5199,
        line2: 2291,
        line3: 1870,
        line4: 5879,

        line7: 2619,
      },
      {
        name: 1879,
        line1: 5716,
        line2: 2416,
        line3: 1860,
        line4: 5786,

        line7: 2664,
      },
      {
        name: 1880,
        line1: 6256,
        line2: 2359,
        line3: 1841,
        line4: 5997,

        line7: 2557,
      },
      {
        name: 1881,
        line1: 6317,
        line2: 2444,
        line3: 1769,
        line4: 6146,

        line7: 2541,
      },
      {
        name: 1882,
        line1: 6558,
        line2: 2354,
        line3: 1918,
        line4: 6269,

        line7: 3108,
      },
      {
        name: 1883,
        line1: 6559,
        line2: 2531,
        line3: 1961,
        line4: 6264,

        line7: 3373,
      },
      {
        name: 1884,
        line1: 6523,
        line2: 2477,
        line3: 1943,
        line4: 6220,

        line7: 3486,

        line10: 878,
      },
      {
        name: 1885,
        line1: 6424,
        line2: 2522,
        line3: 1962,
        line4: 6132,
        line5: 1729,

        line7: 3904,

        line10: 904,
      },
      {
        name: 1886,
        line1: 6472,
        line2: 2547,
        line3: 2034,
        line4: 6170,
        line5: 1832,

        line7: 3771,

        line10: 874,
      },
      {
        name: 1887,
        line1: 6617,
        line2: 2471,
        line3: 2034,
        line4: 6358,
        line5: 1848,

        line7: 3841,

        line9: 912,
        line10: 912,
      },
      {
        name: 1888,
        line1: 6447,
        line2: 2511,
        line3: 2075,
        line4: 6585,
        line5: 1724,

        line7: 4219,

        line10: 918,
      },
      {
        name: 1889,
        line1: 6706,
        line2: 2549,
        line3: 2115,
        line4: 6876,
        line5: 1755,

        line7: 4288,

        line10: 891,
      },
      {
        name: 1890,
        line1: 6665,
        line2: 2606,
        line3: 2201,
        line4: 6845,
        line5: 1854,

        line7: 3851,

        line9: 964,
        line10: 931,
      },
      {
        name: 1891,
        line1: 6811,
        line2: 2748,
        line3: 2152,
        line4: 6781,
        line5: 1750,

        line7: 3631,

        line10: 843,
      },
      {
        name: 1892,
        line1: 7324,
        line2: 2713,
        line3: 2040,
        line4: 6553,
        line5: 1842,

        line7: 4251,

        line10: 910,
      },
      {
        name: 1893,
        line1: 6834,
        line2: 2778,
        line3: 2138,
        line4: 6486,
        line5: 1832,

        line7: 4409,

        line10: 931,
      },
      {
        name: 1894,
        line1: 6510,
        line2: 2791,
        line3: 2230,
        line4: 6851,
        line5: 2016,

        line7: 4968,

        line10: 944,
      },
      {
        name: 1895,
        line1: 7160,
        line2: 2927,
        line3: 2378,
        line4: 6996,
        line5: 2022,

        line7: 5384,

        line10: 920,
      },
      {
        name: 1896,
        line1: 6886,
        line2: 3003,
        line3: 2503,
        line4: 7211,
        line5: 1904,

        line7: 5716,

        line10: 850,
      },
      {
        name: 1897,
        line1: 7406,
        line2: 3121,
        line3: 2589,
        line4: 7229,
        line5: 1905,

        line7: 4500,

        line10: 1004,
      },
      {
        name: 1898,
        line1: 7427,
        line2: 3228,
        line3: 2659,
        line4: 7500,
        line5: 2233,

        line7: 4741,

        line10: 1004,
      },
      {
        name: 1899,
        line1: 7959,
        line2: 3308,
        line3: 2562,
        line4: 7729,
        line5: 2105,

        line7: 5412,

        line10: 925,
      },
      {
        name: 1900,
        line1: 8038,
        line2: 3320,
        line3: 2659,
        line4: 7594,
        line5: 2123,

        line7: 4583,
        line8: 2212,
        line9: 972,
        line10: 955,
      },
      {
        name: 1901,
        line1: 8770,
        line2: 3406,
        line3: 2608,
        line4: 7516,
        line5: 2162,

        line7: 4591,

        line10: 969,
      },
      {
        name: 1902,
        line1: 8684,
        line2: 3362,
        line3: 2536,
        line4: 7635,
        line5: 2013,

        line7: 4331,

        line10: 1044,
      },
      {
        name: 1903,
        line1: 8941,
        line2: 3539,
        line3: 2687,
        line4: 7482,
        line5: 2204,

        line7: 4769,

        line10: 1052,
      },
      {
        name: 1904,
        line1: 8664,
        line2: 3599,
        line3: 2759,
        line4: 7455,
        line5: 2179,

        line7: 5086,

        line10: 1050,
      },
      {
        name: 1905,
        line1: 9121,
        line2: 3547,
        line3: 2777,
        line4: 7603,
        line5: 2111,

        line7: 5545,

        line10: 1025,
      },
      {
        name: 1906,
        line1: 9980,
        line2: 3830,
        line3: 2860,
        line4: 7782,
        line5: 2329,

        line7: 5608,

        line10: 1047,
      },
      {
        name: 1907,
        line1: 9951,
        line2: 4085,
        line3: 2923,
        line4: 7855,
        line5: 2358,

        line7: 5514,

        line10: 979,
      },
      {
        name: 1908,
        line1: 8976,
        line2: 3959,
        line3: 2915,
        line4: 7461,
        line5: 2357,

        line7: 5829,

        line10: 987,
      },
      {
        name: 1909,
        line1: 9798,
        line2: 3980,
        line3: 3003,
        line4: 7557,
        line5: 2333,

        line7: 5896,

        line10: 1116,
      },
      {
        name: 1910,
        line1: 9637,
        line2: 4053,
        line3: 3038,
        line4: 7718,
        line5: 2317,

        line7: 6092,

        line10: 1111,
      },
      {
        name: 1911,
        line1: 9736,
        line2: 4154,
        line3: 3091,
        line4: 7874,
        line5: 2400,

        line7: 5971,

        line9: 905,
        line10: 1101,
      },
      {
        name: 1912,
        line1: 9977,
        line2: 4312,
        line3: 3223,
        line4: 7954,
        line5: 2420,

        line7: 6223,

        line10: 1098,
      },
      {
        name: 1913,
        line1: 10108,
        line2: 4581,
        line3: 3365,
        line4: 8212,
        line5: 2431,

        line7: 6052,

        line9: 985,
        line10: 1073,
      },
      {
        name: 1914,
        line1: 9096,
        line2: 4678,
        line3: 3190,
        line4: 8131,
        line5: 2358,

        line7: 5263,

        line10: 1130,
      },
      {
        name: 1915,
        line1: 9164,
        line2: 4841,
        line3: 3000,
        line4: 8639,
        line5: 2512,

        line7: 5171,

        line10: 1101,
      },
      {
        name: 1916,
        line1: 10222,
        line2: 5109,
        line3: 3017,
        line4: 8702,
        line5: 2829,

        line7: 4927,

        line10: 1132,
      },
      {
        name: 1917,
        line1: 9769,
        line2: 4799,
        line3: 2520,
        line4: 8665,
        line5: 2941,

        line7: 4447,

        line10: 1111,
      },
      {
        name: 1918,
        line1: 10471,
        line2: 4434,
        line3: 2184,
        line4: 8630,
        line5: 2973,

        line7: 5177,

        line10: 968,
      },
      {
        name: 1919,
        line1: 10450,
        line2: 4503,
        line3: 2643,
        line4: 7605,
        line5: 3219,

        line7: 5271,

        line10: 1100,
      },
      {
        name: 1920,
        line1: 10153,
        line2: 4788,
        line3: 2942,
        line4: 7017,
        line5: 2974,

        line7: 5536,
        line8: 2241,

        line10: 1012,
      },
      {
        name: 1921,
        line1: 9675,
        line2: 4543,
        line3: 3003,
        line4: 6792,
        line5: 3244,

        line7: 5533,

        line10: 1082,
      },
      {
        name: 1922,
        line1: 10010,
        line2: 4868,
        line3: 3280,
        line4: 7391,
        line5: 3244,

        line7: 5796,

        line10: 1117,
      },
      {
        name: 1923,
        line1: 11071,
        line2: 5023,
        line3: 3486,
        line4: 7587,
        line5: 3244,

        line7: 6213,

        line10: 1070,
      },
      {
        name: 1924,
        line1: 11127,
        line2: 5365,
        line3: 3545,
        line4: 7844,
        line5: 3316,

        line7: 6464,

        line10: 1111,
      },
      {
        name: 1925,
        line1: 11150,
        line2: 5488,
        line3: 3711,
        line4: 8199,
        line5: 3414,

        line7: 6247,

        line10: 1113,
      },
      {
        name: 1926,
        line1: 11648,
        line2: 5756,
        line3: 3813,
        line4: 7868,
        line5: 3359,

        line7: 6366,

        line10: 1137,
      },
      {
        name: 1927,
        line1: 11532,
        line2: 5942,
        line3: 4076,
        line4: 8472,
        line5: 3347,

        line7: 6625,

        line10: 1125,
      },
      {
        name: 1928,
        line1: 11451,
        line2: 6113,
        line3: 4315,
        line4: 8539,
        line5: 3554,

        line7: 6840,

        line10: 1125,
      },
      {
        name: 1929,
        line1: 11954,
        line2: 6476,
        line3: 4331,
        line4: 8772,
        line5: 3665,

        line7: 6961,

        line9: 1003,
        line10: 1160,
      },
      {
        name: 1930,
        line1: 10695,
        line2: 6755,
        line3: 4250,
        line4: 8673,
        line5: 3334,

        line7: 6503,

        line9: 1012,
        line10: 1157,
      },
      {
        name: 1931,
        line1: 9931,
        line2: 6582,
        line3: 4114,
        line4: 8190,
        line5: 3321,

        line7: 5917,

        line9: 1015,
        line10: 1133,
      },
      {
        name: 1932,
        line1: 8381,
        line2: 6325,
        line3: 4065,
        line4: 8206,
        line5: 3529,

        line7: 5614,

        line9: 1039,
        line10: 1130,
      },
      {
        name: 1933,
        line1: 8048,
        line2: 6429,
        line3: 4307,
        line4: 8411,
        line5: 3787,

        line7: 5772,

        line9: 923,
        line10: 1116,
      },
      {
        name: 1934,
        line1: 8667,
        line2: 6845,
        line3: 4763,
        line4: 8939,
        line5: 3756,

        line7: 6129,

        line9: 937,
        line10: 1111,
      },
      {
        name: 1935,
        line1: 9681,
        line2: 7160,
        line3: 4930,
        line4: 9244,
        line5: 3825,

        line7: 6296,

        line9: 1007,
        line10: 1084,
      },
      {
        name: 1936,
        line1: 10568,
        line2: 7396,
        line3: 5227,
        line4: 9620,
        line5: 3986,

        line7: 6236,

        line9: 1065,
        line10: 1111,
      },
      {
        name: 1937,
        line1: 11295,
        line2: 7579,
        line3: 5485,
        line4: 9911,
        line5: 4075,

        line7: 6575,

        line9: 1034,
        line10: 1078,
      },
      {
        name: 1938,
        line1: 10526,
        line2: 7812,
        line3: 5721,
        line4: 9988,
        line5: 4257,

        line7: 6491,

        line9: 1003,
        line10: 1065,
      },
      {
        name: 1939,
        line1: 11171,
        line2: 8370,
        line3: 5432,
        line4: 9982,
        line5: 4804,

        line7: 6612,

        line10: 1074,
      },
      {
        name: 1940,
        line1: 12005,
        line2: 7739,
        line3: 5133,
        line4: 10928,
        line5: 4882,

        line7: 6633,
        line8: 3133,

        line10: 1093,
      },
      {
        name: 1941,
        line1: 13553,
        line2: 7511,
        line3: 5295,
        line4: 11926,
        line5: 4986,

        line7: 6861,

        line10: 1101,
      },
      {
        name: 1942,
        line1: 14870,
        line2: 7557,
        line3: 5303,
        line4: 12176,
        line5: 4939,

        line7: 6829,

        line10: 1082,
      },
      {
        name: 1943,
        line1: 16050,
        line2: 7779,
        line3: 5893,
        line4: 12344,
        line5: 4826,

        line7: 6666,

        line10: 1113,
      },
      {
        name: 1944,
        line1: 16999,
        line2: 7987,
        line3: 5874,
        line4: 11803,
        line5: 4816,

        line7: 7299,

        line10: 1089,
      },
      {
        name: 1945,
        line1: 16478,
        line2: 8201,
        line3: 5499,
        line4: 11247,
        line5: 3678,

        line7: 6943,

        line10: 1058,
      },
      {
        name: 1946,
        line1: 14822,
        line2: 9000,
        line3: 5871,
        line4: 10751,
        line5: 2771,

        line7: 7436,

        line10: 991,
      },
      {
        name: 1947,
        line1: 14312,
        line2: 9709,
        line3: 5925,
        line4: 10527,
        line5: 2711,

        line7: 8112,

        line10: 985,
      },
      {
        name: 1948,
        line1: 14734,
        line2: 9822,
        line3: 6307,
        line4: 10753,
        line5: 2857,

        line7: 8372,

        line10: 983,
      },
      {
        name: 1949,
        line1: 14197,
        line2: 10127,
        line3: 6604,
        line4: 11088,
        line5: 2867,

        line7: 8045,

        line10: 995,
      },
      {
        name: 1950,
        line1: 15240,
        line2: 10742,
        line3: 6779,
        line4: 11061,
        line5: 3062,

        line7: 7949,
        line8: 3351,
        line9: 799,
        line10: 987,
        line11: 1038,
      },
      {
        name: 1951,
        line1: 16125,
        line2: 11077,
        line3: 7286,
        line4: 11354,
        line5: 3389,

        line7: 8086,

        line9: 950,
        line10: 993,
        line11: 1229,
      },
      {
        name: 1952,
        line1: 16444,
        line2: 11152,
        line3: 7450,
        line4: 11303,
        line5: 3724,

        line7: 7519,

        line9: 1047,
        line10: 1003,
        line11: 1063,
      },
      {
        name: 1953,
        line1: 16917,
        line2: 11389,
        line3: 7415,
        line4: 11709,
        line5: 3944,

        line7: 7769,

        line9: 1157,
        line10: 1047,
        line11: 1009,
      },
      {
        name: 1954,
        line1: 16512,
        line2: 11799,
        line3: 7973,
        line4: 12145,
        line5: 4116,

        line7: 7938,

        line9: 1049,
        line10: 1071,
        line11: 1095,
      },
      {
        name: 1955,
        line1: 17370,
        line2: 12060,
        line3: 8284,
        line4: 12541,
        line5: 4417,

        line7: 8348,

        line9: 1119,
        line10: 1078,
        line11: 1144,
      },
      {
        name: 1956,
        line1: 17397,
        line2: 12428,
        line3: 8440,
        line4: 12639,
        line5: 4699,

        line7: 8424,

        line9: 1189,
        line10: 1117,
        line11: 1173,
      },
      {
        name: 1957,
        line1: 17406,
        line2: 12899,
        line3: 8751,
        line4: 12779,
        line5: 4999,

        line7: 8705,

        line9: 1195,
        line10: 1084,
        line11: 1176,
      },
      {
        name: 1958,
        line1: 16946,
        line2: 12884,
        line3: 8725,
        line4: 12698,
        line5: 5243,

        line7: 9083,

        line9: 1173,
        line10: 1141,
        line11: 1156,
      },
      {
        name: 1959,
        line1: 17900,
        line2: 13211,
        line3: 9172,
        line4: 13134,
        line5: 5665,

        line7: 8354,

        line9: 1117,
        line10: 1143,
        line11: 1148,
      },
      {
        name: 1960,
        line1: 18057,
        line2: 13849,
        line3: 9931,
        line4: 13780,
        line5: 6354,
        line6: 5557,
        line7: 8861,
        line8: 4386,
        line9: 1057,
        line10: 1200,
        line11: 1157,
      },
      {
        name: 1961,
        line1: 18175,
        line2: 14564,
        line3: 10613,
        line4: 14118,
        line5: 7055,
        line6: 5874,
        line7: 9344,

        line9: 874,
        line10: 1208,
        line11: 1093,
      },
      {
        name: 1962,
        line1: 18976,
        line2: 15093,
        line3: 10869,
        line4: 14131,
        line5: 7614,
        line6: 6229,
        line7: 9049,

        line9: 926,
        line10: 1208,
        line11: 1117,
      },
      {
        name: 1963,
        line1: 19514,
        line2: 15808,
        line3: 11148,
        line4: 14583,
        line5: 8176,
        line6: 6405,
        line7: 8695,

        line9: 1034,
        line10: 1242,
        line11: 1138,
      },
      {
        name: 1964,
        line1: 20360,
        line2: 16761,
        line3: 11647,
        line4: 15251,
        line5: 9035,
        line6: 6754,
        line7: 9446,

        line9: 1152,
        line10: 1309,
        line11: 1208,
      },
      {
        name: 1965,
        line1: 21390,
        line2: 17239,
        line3: 12226,
        line4: 15545,
        line5: 9459,
        line6: 7068,
        line7: 10155,

        line9: 1253,
        line10: 1229,
        line11: 1184,
      },
      {
        name: 1966,
        line1: 22529,
        line2: 17432,
        line3: 12471,
        line4: 15757,
        line5: 10370,
        line6: 7517,
        line7: 10076,

        line9: 1261,
        line10: 1215,
        line11: 1294,
      },
      {
        name: 1967,
        line1: 22842,
        line2: 17883,
        line3: 12667,
        line4: 16018,
        line5: 11400,
        line6: 7943,
        line7: 10200,

        line9: 1237,
        line10: 1286,
        line11: 1317,
      },
      {
        name: 1968,
        line1: 23691,
        line2: 18428,
        line3: 12900,
        line4: 16593,
        line5: 12725,
        line6: 8391,
        line7: 10485,

        line9: 1178,
        line10: 1290,
        line11: 1366,
      },
      {
        name: 1969,
        line1: 24195,
        line2: 19215,
        line3: 14151,
        line4: 16820,
        line5: 14145,
        line6: 8563,
        line7: 11217,

        line9: 1264,
        line10: 1347,
        line11: 1404,
      },
      {
        name: 1970,
        line1: 23958,
        line2: 20269,
        line3: 15266,
        line4: 17162,
        line5: 15484,
        line6: 9234,
        line7: 11639,
        line8: 5952,
        line9: 1398,
        line10: 1384,
        line11: 1458,
      },
      {
        name: 1971,
        line1: 24394,
        line2: 20320,
        line3: 15565,
        line4: 17440,
        line5: 16004,
        line6: 9567,
        line7: 12003,

        line9: 1446,
        line10: 1364,
        line11: 1500,
      },
      {
        name: 1972,
        line1: 25414,
        line2: 20725,
        line3: 16654,
        line4: 18002,
        line5: 17110,
        line6: 9785,
        line7: 12170,

        line9: 1431,
        line10: 1329,
        line11: 1524,
      },
      {
        name: 1973,
        line1: 26602,
        line2: 21509,
        line3: 17669,
        line4: 19168,
        line5: 18226,
        line6: 10492,
        line7: 12691,

        line9: 1513,
        line10: 1360,
        line11: 1546,
      },
      {
        name: 1974,
        line1: 26286,
        line2: 22132,
        line3: 18109,
        line4: 18903,
        line5: 17765,
        line6: 10801,
        line7: 13284,

        line9: 1519,
        line10: 1344,
        line11: 1564,
      },
      {
        name: 1975,
        line1: 25956,
        line2: 22607,
        line3: 18237,
        line4: 18884,
        line5: 18082,
        line6: 11164,
        line7: 12946,

        line9: 1594,
        line10: 1430,
        line11: 1502,
      },
      {
        name: 1976,
        line1: 27058,
        line2: 22765,
        line3: 18104,
        line4: 19311,
        line5: 18600,
        line6: 11426,
        line7: 12696,

        line9: 1519,
        line10: 1417,
        line11: 1503,
      },
      {
        name: 1977,
        line1: 28001,
        line2: 22322,
        line3: 18100,
        line4: 19740,
        line5: 19230,
        line6: 11692,
        line7: 13236,

        line9: 1583,
        line10: 1494,
        line11: 1580,
      },
      {
        name: 1978,
        line1: 29286,
        line2: 22646,
        line3: 18425,
        line4: 20448,
        line5: 20060,
        line6: 11829,
        line7: 12444,

        line9: 1744,
        line10: 1540,
        line11: 1656,
      },
      {
        name: 1979,
        line1: 29949,
        line2: 23465,
        line3: 19657,
        line4: 20988,
        line5: 20982,
        line6: 11867,
        line7: 13114,

        line9: 1859,
        line10: 1427,
        line11: 1651,
      },
      {
        name: 1980,
        line1: 29611,
        line2: 23809,
        line3: 20640,
        line4: 20612,
        line5: 21404,
        line6: 12204,
        line7: 13080,
        line8: 7233,
        line9: 1930,
        line10: 1495,
        line11: 1675,
      },
      {
        name: 1981,
        line1: 30056,
        line2: 23777,
        line3: 20935,
        line4: 20319,
        line5: 21924,
        line6: 12274,
        line7: 12125,

        line9: 1962,
        line10: 1557,
        line11: 1647,
      },
      {
        name: 1982,
        line1: 29210,
        line2: 24002,
        line3: 21495,
        line4: 20650,
        line5: 22440,
        line6: 12267,
        line7: 11550,

        line9: 2128,
        line10: 1570,
        line11: 1680,
      },
      {
        name: 1983,
        line1: 30158,
        line2: 24412,
        line3: 21944,
        line4: 21366,
        line5: 22805,
        line6: 12420,
        line7: 11775,

        line9: 2227,
        line10: 1663,
        line11: 1627,
      },
      {
        name: 1984,
        line1: 32076,
        line2: 25357,
        line3: 22486,
        line4: 21869,
        line5: 23548,
        line6: 12379,
        line7: 11837,

        line9: 2444,
        line10: 1690,
        line11: 1594,
      },
      {
        name: 1985,
        line1: 33023,
        line2: 25805,
        line3: 23148,
        line4: 22579,
        line5: 24437,
        line6: 12475,
        line7: 10895,

        line9: 2619,
        line10: 1720,
        line11: 1604,
      },
      {
        name: 1986,
        line1: 33850,
        line2: 26309,
        line3: 23621,
        line4: 23498,
        line5: 24992,
        line6: 12694,
        line7: 11515,

        line9: 2748,
        line10: 1755,
        line11: 1658,
      },
      {
        name: 1987,
        line1: 34730,
        line2: 27016,
        line3: 24519,
        line4: 24536,
        line5: 25904,
        line6: 12597,
        line7: 11633,

        line9: 2931,
        line10: 1793,
        line11: 1698,
      },
      {
        name: 1988,
        line1: 35863,
        line2: 27468,
        line3: 25644,
        line4: 25679,
        line5: 27393,
        line6: 12720,
        line7: 11244,

        line9: 3045,
        line10: 1938,
        line11: 1741,
      },
      {
        name: 1989,
        line1: 36756,
        line2: 27933,
        line3: 27012,
        line4: 26164,
        line5: 28601,
        line6: 12766,
        line7: 10393,

        line9: 3022,
        line10: 2024,
        line11: 1761,
      },
      {
        name: 1990,
        line1: 36982,
        line2: 28068,
        line3: 26884,
        line4: 26189,
        line5: 29949,
        line6: 12400,
        line7: 10254,
        line8: 8222,
        line9: 2982,
        line10: 2087,
        line11: 1780,
      },
      {
        name: 1991,
        line1: 36464,
        line2: 27614,
        line3: 25175,
        line4: 25792,
        line5: 30837,
        line6: 12012,
        line7: 11224,

        line9: 3051,
        line10: 2062,
        line11: 1778,
      },
      {
        name: 1992,
        line1: 37240,
        line2: 27199,
        line3: 24220,
        line4: 25790,
        line5: 30978,
        line6: 10488,
        line7: 12267,

        line9: 3228,
        line10: 2115,
        line11: 1725,
      },
      {
        name: 1993,
        line1: 37762,
        line2: 26559,
        line3: 23944,
        line4: 26349,
        line5: 30921,
        line6: 9789,
        line7: 12927,

        line9: 3506,
        line10: 2176,
        line11: 1698,
      },
      {
        name: 1994,
        line1: 38807,
        line2: 27506,
        line3: 24798,
        line4: 27278,
        line5: 31135,
        line6: 8744,
        line7: 13571,

        line9: 3730,
        line10: 2255,
        line11: 1730,
      },
      {
        name: 1995,
        line1: 39391,
        line2: 28519,
        line3: 25762,
        line4: 27861,
        line5: 31887,
        line6: 8586,
        line7: 13086,

        line9: 4000,
        line10: 2356,
        line11: 1790,
      },
      {
        name: 1996,
        line1: 40413,
        line2: 29012,
        line3: 26637,
        line4: 28472,
        line5: 32784,
        line6: 8479,
        line7: 13715,

        line9: 4220,
        line10: 2476,
        line11: 1851,
      },
      {
        name: 1997,
        line1: 41723,
        line2: 29863,
        line3: 28240,
        line4: 29260,
        line5: 33038,
        line6: 8813,
        line7: 14723,

        line9: 4311,
        line10: 2514,
        line11: 1846,
      },
      {
        name: 1998,
        line1: 43073,
        line2: 31187,
        line3: 29715,
        line4: 30077,
        line5: 32558,
        line6: 8557,
        line7: 15186,

        line9: 4310,
        line10: 2612,
        line11: 1890,
      },
      {
        name: 1999,
        line1: 44576,
        line2: 32705,
        line3: 30987,
        line4: 30933,
        line5: 32395,
        line6: 9340,
        line7: 14578,

        line9: 4467,
        line10: 2708,
        line11: 1921,
      },
      {
        name: 2000,
        line1: 45886,
        line2: 34203,
        line3: 32690,
        line4: 31946,
        line5: 33211,
        line6: 10553,
        line7: 14369,
        line8: 9915,
        line9: 4730,
        line10: 2753,
        line11: 1915,
      },
      {
        name: 2001,
        line1: 45878,
        line2: 34667,
        line3: 33482,
        line4: 32660,
        line5: 33246,
        line6: 11391,
        line7: 13652,

        line9: 4995,
        line10: 2838,
        line11: 1972,
      },
      {
        name: 2002,
        line1: 46266,
        line2: 35570,
        line3: 33987,
        line4: 33271,
        line5: 33195,
        line6: 12259,
        line7: 12095,

        line9: 5343,
        line10: 2871,
        line11: 1962,
      },
      {
        name: 2003,
        line1: 47158,
        line2: 36436,
        line3: 34607,
        line4: 34232,
        line5: 33620,
        line6: 13521,
        line7: 13089,

        line9: 5649,
        line10: 3036,
        line11: 1998,
      },
      {
        name: 2004,
        line1: 48493,
        line2: 38016,
        line3: 35889,
        line4: 34866,
        line5: 34314,
        line6: 14881,
        line7: 14183,

        line9: 6068,
        line10: 3213,
        line11: 2066,
      },
      {
        name: 2005,
        line1: 49655,
        line2: 39259,
        line3: 36787,
        line4: 35624,
        line5: 34858,
        line6: 16243,
        line7: 15344,

        line9: 6627,
        line10: 3397,
        line11: 2158,
      },
      {
        name: 2006,
        line1: 50490,
        line2: 40992,
        line3: 38161,
        line4: 36214,
        line5: 35333,
        line6: 18008,
        line7: 16490,

        line9: 7271,
        line10: 3629,
        line11: 2255,
      },
      {
        name: 2007,
        line1: 50902,
        line2: 42400,
        line3: 39999,
        line4: 36817,
        line5: 35893,
        line6: 20018,
        line7: 17892,

        line9: 7896,
        line10: 3871,
        line11: 2381,
      },
      {
        name: 2008,
        line1: 50276,
        line2: 42190,
        line3: 40130,
        line4: 36265,
        line5: 35496,
        line6: 21563,
        line7: 18520,

        line9: 8190,
        line10: 4031,
        line11: 2357,
      },
      {
        name: 2009,
        line1: 48453,
        line2: 40116,
        line3: 36663,
        line4: 34402,
        line5: 33590,
        line6: 20336,
        line7: 17329,

        line9: 8734,
        line10: 4271,
        line11: 2405,
      },
      {
        name: 2010,
        line1: 49267,
        line2: 42635,
        line3: 37615,
        line4: 34754,
        line5: 35011,
        line6: 21737,
        line7: 18980,
        line8: 13179,
        line9: 9658,
        line10: 4526,
        line11: 2580,
      },
      {
        name: 2011,
        line1: 49675,
        line2: 42079,
        line3: 38432,
        line4: 34971,
        line5: 34979,
        line6: 23130,
        line7: 20003,

        line9: 10221,
        line10: 4768,
        line11: 2712,
      },
      {
        name: 2012,
        line1: 50394,
        line2: 41650,
        line3: 37704,
        line4: 35185,
        line5: 35580,
        line6: 23931,
        line7: 19599,

        line9: 10680,
        line10: 4932,
        line11: 2765,
      },
      {
        name: 2013,
        line1: 50863,
        line2: 41811,
        line3: 37246,
        line4: 35618,
        line5: 36354,
        line6: 24224,
        line7: 19873,

        line9: 11328,
        line10: 5172,
        line11: 2854,
      },
      {
        name: 2014,
        line1: 51664,
        line2: 42476,
        line3: 36858,
        line4: 36436,
        line5: 36537,
        line6: 24387,
        line7: 19183,

        line9: 11944,
        line10: 5458,
        line11: 2942,
      },
      {
        name: 2015,
        line1: 52591,
        line2: 43746,
        line3: 36836,
        line4: 36941,
        line5: 37031,
        line6: 23691,
        line7: 19502,

        line9: 12244,
        line10: 5794,
        line11: 3046,
      },
      {
        name: 2016,
        line1: 53015,
        line2: 44659,
        line3: 37239,
        line4: 37334,
        line5: 37465,
        line6: 23635,
        line7: 18875,
        line8: 14700,
        line9: 12569,
        line10: 6125,
        line11: 3169,
      },
      {
        name: 2017,
        line1: 54008,
        line2: 45193,
        line3: 38367,
        line4: 37783,
        line5: 38415,
        line6: 24043,
        line7: 19201,
        line8: 14944,
        line9: 12734,
        line10: 6449,
        line11: 3250,
      },
      {
        name: 2018,
        line1: 55335,
        line2: 45542,
        line3: 38897,
        line4: 38058,
        line5: 38674,
        line6: 24669,
        line7: 18556,
        line8: 15212,
        line9: 13102,
        line10: 6806,
        line11: 3377,
      },
      {
        name: 2019,
      },
      {
        name: 2020,
      },
    ],
  },
  {
    name: "kuvio-5.3b",
    source: "",
    xAxisLabel: "Vuosi",
    yAxisLabel: "",
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 8,
    yAxisWidth: 60,
    xTickCount: 8,
    margins: [15, 15, 0, 5],
    tickDx: -20,
    connectNulls: true,
    height: 550,
    scale: "log",
    type: ["category", "number"],
    yDomain: [500, 64000],
    xDomain: ["dataMin", "dataMax"],
    manualTicksY: [500, 1000, 2000, 4000, 8000, 16000, 32000, 64000],
    manualTicksX: [
      1820, 1840, 1860, 1880, 1900, 1920, 1940, 1960, 1980, 2000, 2020,
    ],

    names: [
      "Yhdysvallat",
      "Ruotsi",
      "Suomi",
      "Iso-Britannia",
      "Japani",
      "Venäjä",
      "Argentiina",
      "Maailma",
      "Kiina",
      "Intia",
      "Kenia",
    ],
    data: [
      {
        name: 1820,
        line1: 2674,
        line2: 1415,
        line3: 1313,
        line4: 3306,
        line5: 1317,

        line7: 1591,
        line8: 1102,
        line9: 882,
        line10: 937,
      },
      {
        name: 1821,
        line1: 2715,
        line2: 1457,

        line4: 3308,
      },
      {
        name: 1822,
        line1: 2791,
        line2: 1465,

        line4: 3315,
      },
      {
        name: 1823,
        line1: 2760,
        line2: 1449,

        line4: 3359,
      },
      {
        name: 1824,
        line1: 2836,
        line2: 1487,

        line4: 3492,
      },
      {
        name: 1825,
        line1: 2898,
        line2: 1527,

        line4: 3521,
      },
      {
        name: 1826,
        line1: 2918,
        line2: 1532,

        line4: 3290,
      },
      {
        name: 1827,
        line1: 2930,
        line2: 1458,

        line4: 3499,
      },
      {
        name: 1828,
        line1: 2930,
        line2: 1486,

        line4: 3461,
      },
      {
        name: 1829,
        line1: 2833,
        line2: 1506,

        line4: 3443,
      },
      {
        name: 1830,
        line1: 3039,
        line2: 1468,

        line4: 3550,

        line9: 846,
        line10: 945,
      },
      {
        name: 1831,
        line1: 3226,
        line2: 1497,

        line4: 3545,
      },
      {
        name: 1832,
        line1: 3343,
        line2: 1457,

        line4: 3612,
      },
      {
        name: 1833,
        line1: 3460,
        line2: 1503,

        line4: 3599,
      },
      {
        name: 1834,
        line1: 3295,
        line2: 1522,

        line4: 3639,
      },
      {
        name: 1835,
        line1: 3420,
        line2: 1517,

        line4: 3861,
      },
      {
        name: 1836,
        line1: 3470,
        line2: 1565,

        line4: 3937,
      },
      {
        name: 1837,
        line1: 3361,
        line2: 1554,

        line4: 3857,
      },
      {
        name: 1838,
        line1: 3330,
        line2: 1529,

        line4: 3963,
      },
      {
        name: 1839,
        line1: 3492,
        line2: 1546,

        line4: 3881,
      },
      {
        name: 1840,
        line1: 3319,
        line2: 1568,

        line4: 4018,

        line9: 849,
        line10: 944,
      },
      {
        name: 1841,
        line1: 3253,
        line2: 1549,

        line4: 3894,
      },
      {
        name: 1842,
        line1: 3223,
        line2: 1476,

        line4: 3808,
      },
      {
        name: 1843,
        line1: 3274,
        line2: 1508,

        line4: 3950,
      },
      {
        name: 1844,
        line1: 3453,
        line2: 1573,

        line4: 4245,
      },
      {
        name: 1845,
        line1: 3506,
        line2: 1610,

        line4: 4387,
      },
      {
        name: 1846,
        line1: 3526,
        line2: 1554,

        line4: 4334,
        line5: 1436,
      },
      {
        name: 1847,
        line1: 3619,
        line2: 1594,

        line4: 4234,
      },
      {
        name: 1848,
        line1: 3713,
        line2: 1612,

        line4: 4361,
      },
      {
        name: 1849,
        line1: 3616,
        line2: 1672,

        line4: 4422,
      },
      {
        name: 1850,
        line1: 3632,
        line2: 1715,
        line3: 1519,
        line4: 4332,
        line5: 1436,

        line7: 1994,
        line8: 1225,
        line9: 858,
        line10: 947,
      },
      {
        name: 1851,
        line1: 3778,
        line2: 1682,

        line4: 4477,
      },
      {
        name: 1852,
        line1: 3971,
        line2: 1645,

        line4: 4626,
      },
      {
        name: 1853,
        line1: 4242,
        line2: 1667,

        line4: 4734,
      },
      {
        name: 1854,
        line1: 4254,
        line2: 1685,

        line4: 4909,
      },
      {
        name: 1855,
        line1: 4161,
        line2: 1788,

        line4: 4748,
      },
      {
        name: 1856,
        line1: 4248,
        line2: 1771,

        line4: 5013,
      },
      {
        name: 1857,
        line1: 4156,
        line2: 1819,

        line4: 5019,
      },
      {
        name: 1858,
        line1: 4177,
        line2: 1841,

        line4: 4879,
      },
      {
        name: 1859,
        line1: 4289,
        line2: 1906,

        line4: 5031,
      },
      {
        name: 1860,
        line1: 4402,
        line2: 1941,
        line3: 1522,
        line4: 5086,

        line7: 2160,

        line10: 896,
      },
      {
        name: 1861,
        line1: 4312,
        line2: 1865,
        line3: 1527,
        line4: 5024,
      },
      {
        name: 1862,
        line1: 4447,
        line2: 1808,
        line3: 1428,
        line4: 4764,
      },
      {
        name: 1863,
        line1: 4734,
        line2: 1886,
        line3: 1527,
        line4: 5165,
      },
      {
        name: 1864,
        line1: 4889,
        line2: 1919,
        line3: 1545,
        line4: 5255,
      },
      {
        name: 1865,
        line1: 4637,
        line2: 1953,
        line3: 1516,
        line4: 5333,
      },
      {
        name: 1866,
        line1: 4600,
        line2: 1921,
        line3: 1527,
        line4: 5451,
      },
      {
        name: 1867,
        line1: 4752,
        line2: 1949,
        line3: 1412,
        line4: 5424,
      },
      {
        name: 1868,
        line1: 4816,
        line2: 1774,
        line3: 1599,
        line4: 5636,
      },
      {
        name: 1869,
        line1: 4943,
        line2: 1902,
        line3: 1755,
        line4: 5635,
      },
      {
        name: 1870,
        line1: 4803,
        line2: 2144,
        line3: 1817,
        line4: 5829,
        line5: 1580,

        line7: 2340,
        line8: 1498,
        line9: 945,
        line10: 850,
      },
      {
        name: 1871,
        line1: 4919,
        line2: 2219,
        line3: 1796,
        line4: 5797,
      },
      {
        name: 1872,
        line1: 4992,
        line2: 2259,
        line3: 1825,
        line4: 5769,
      },
      {
        name: 1873,
        line1: 5117,
        line2: 2284,
        line3: 1902,
        line4: 5842,
      },
      {
        name: 1874,
        line1: 4965,
        line2: 2370,
        line3: 1919,
        line4: 5874,
        line5: 1607,
      },
      {
        name: 1875,
        line1: 5105,
        line2: 2286,
        line3: 1930,
        line4: 5950,

        line7: 2606,
      },
      {
        name: 1876,
        line1: 5050,
        line2: 2431,
        line3: 2007,
        line4: 5938,

        line7: 2611,
      },
      {
        name: 1877,
        line1: 5099,
        line2: 2377,
        line3: 1930,
        line4: 5923,

        line7: 2820,
      },
      {
        name: 1878,
        line1: 5199,
        line2: 2291,
        line3: 1870,
        line4: 5879,

        line7: 2619,
      },
      {
        name: 1879,
        line1: 5716,
        line2: 2416,
        line3: 1860,
        line4: 5786,

        line7: 2664,
      },
      {
        name: 1880,
        line1: 6256,
        line2: 2359,
        line3: 1841,
        line4: 5997,

        line7: 2557,
      },
      {
        name: 1881,
        line1: 6317,
        line2: 2444,
        line3: 1769,
        line4: 6146,

        line7: 2541,
      },
      {
        name: 1882,
        line1: 6558,
        line2: 2354,
        line3: 1918,
        line4: 6269,

        line7: 3108,
      },
      {
        name: 1883,
        line1: 6559,
        line2: 2531,
        line3: 1961,
        line4: 6264,

        line7: 3373,
      },
      {
        name: 1884,
        line1: 6523,
        line2: 2477,
        line3: 1943,
        line4: 6220,

        line7: 3486,

        line10: 878,
      },
      {
        name: 1885,
        line1: 6424,
        line2: 2522,
        line3: 1962,
        line4: 6132,
        line5: 1729,

        line7: 3904,

        line10: 904,
      },
      {
        name: 1886,
        line1: 6472,
        line2: 2547,
        line3: 2034,
        line4: 6170,
        line5: 1832,

        line7: 3771,

        line10: 874,
      },
      {
        name: 1887,
        line1: 6617,
        line2: 2471,
        line3: 2034,
        line4: 6358,
        line5: 1848,

        line7: 3841,

        line9: 912,
        line10: 912,
      },
      {
        name: 1888,
        line1: 6447,
        line2: 2511,
        line3: 2075,
        line4: 6585,
        line5: 1724,

        line7: 4219,

        line10: 918,
      },
      {
        name: 1889,
        line1: 6706,
        line2: 2549,
        line3: 2115,
        line4: 6876,
        line5: 1755,

        line7: 4288,

        line10: 891,
      },
      {
        name: 1890,
        line1: 6665,
        line2: 2606,
        line3: 2201,
        line4: 6845,
        line5: 1854,

        line7: 3851,

        line9: 964,
        line10: 931,
      },
      {
        name: 1891,
        line1: 6811,
        line2: 2748,
        line3: 2152,
        line4: 6781,
        line5: 1750,

        line7: 3631,

        line10: 843,
      },
      {
        name: 1892,
        line1: 7324,
        line2: 2713,
        line3: 2040,
        line4: 6553,
        line5: 1842,

        line7: 4251,

        line10: 910,
      },
      {
        name: 1893,
        line1: 6834,
        line2: 2778,
        line3: 2138,
        line4: 6486,
        line5: 1832,

        line7: 4409,

        line10: 931,
      },
      {
        name: 1894,
        line1: 6510,
        line2: 2791,
        line3: 2230,
        line4: 6851,
        line5: 2016,

        line7: 4968,

        line10: 944,
      },
      {
        name: 1895,
        line1: 7160,
        line2: 2927,
        line3: 2378,
        line4: 6996,
        line5: 2022,

        line7: 5384,

        line10: 920,
      },
      {
        name: 1896,
        line1: 6886,
        line2: 3003,
        line3: 2503,
        line4: 7211,
        line5: 1904,

        line7: 5716,

        line10: 850,
      },
      {
        name: 1897,
        line1: 7406,
        line2: 3121,
        line3: 2589,
        line4: 7229,
        line5: 1905,

        line7: 4500,

        line10: 1004,
      },
      {
        name: 1898,
        line1: 7427,
        line2: 3228,
        line3: 2659,
        line4: 7500,
        line5: 2233,

        line7: 4741,

        line10: 1004,
      },
      {
        name: 1899,
        line1: 7959,
        line2: 3308,
        line3: 2562,
        line4: 7729,
        line5: 2105,

        line7: 5412,

        line10: 925,
      },
      {
        name: 1900,
        line1: 8038,
        line2: 3320,
        line3: 2659,
        line4: 7594,
        line5: 2123,

        line7: 4583,
        line8: 2212,
        line9: 972,
        line10: 955,
      },
      {
        name: 1901,
        line1: 8770,
        line2: 3406,
        line3: 2608,
        line4: 7516,
        line5: 2162,

        line7: 4591,

        line10: 969,
      },
      {
        name: 1902,
        line1: 8684,
        line2: 3362,
        line3: 2536,
        line4: 7635,
        line5: 2013,

        line7: 4331,

        line10: 1044,
      },
      {
        name: 1903,
        line1: 8941,
        line2: 3539,
        line3: 2687,
        line4: 7482,
        line5: 2204,

        line7: 4769,

        line10: 1052,
      },
      {
        name: 1904,
        line1: 8664,
        line2: 3599,
        line3: 2759,
        line4: 7455,
        line5: 2179,

        line7: 5086,

        line10: 1050,
      },
      {
        name: 1905,
        line1: 9121,
        line2: 3547,
        line3: 2777,
        line4: 7603,
        line5: 2111,

        line7: 5545,

        line10: 1025,
      },
      {
        name: 1906,
        line1: 9980,
        line2: 3830,
        line3: 2860,
        line4: 7782,
        line5: 2329,

        line7: 5608,

        line10: 1047,
      },
      {
        name: 1907,
        line1: 9951,
        line2: 4085,
        line3: 2923,
        line4: 7855,
        line5: 2358,

        line7: 5514,

        line10: 979,
      },
      {
        name: 1908,
        line1: 8976,
        line2: 3959,
        line3: 2915,
        line4: 7461,
        line5: 2357,

        line7: 5829,

        line10: 987,
      },
      {
        name: 1909,
        line1: 9798,
        line2: 3980,
        line3: 3003,
        line4: 7557,
        line5: 2333,

        line7: 5896,

        line10: 1116,
      },
      {
        name: 1910,
        line1: 9637,
        line2: 4053,
        line3: 3038,
        line4: 7718,
        line5: 2317,

        line7: 6092,

        line10: 1111,
      },
      {
        name: 1911,
        line1: 9736,
        line2: 4154,
        line3: 3091,
        line4: 7874,
        line5: 2400,

        line7: 5971,

        line9: 905,
        line10: 1101,
      },
      {
        name: 1912,
        line1: 9977,
        line2: 4312,
        line3: 3223,
        line4: 7954,
        line5: 2420,

        line7: 6223,

        line10: 1098,
      },
      {
        name: 1913,
        line1: 10108,
        line2: 4581,
        line3: 3365,
        line4: 8212,
        line5: 2431,

        line7: 6052,

        line9: 985,
        line10: 1073,
      },
      {
        name: 1914,
        line1: 9096,
        line2: 4678,
        line3: 3190,
        line4: 8131,
        line5: 2358,

        line7: 5263,

        line10: 1130,
      },
      {
        name: 1915,
        line1: 9164,
        line2: 4841,
        line3: 3000,
        line4: 8639,
        line5: 2512,

        line7: 5171,

        line10: 1101,
      },
      {
        name: 1916,
        line1: 10222,
        line2: 5109,
        line3: 3017,
        line4: 8702,
        line5: 2829,

        line7: 4927,

        line10: 1132,
      },
      {
        name: 1917,
        line1: 9769,
        line2: 4799,
        line3: 2520,
        line4: 8665,
        line5: 2941,

        line7: 4447,

        line10: 1111,
      },
      {
        name: 1918,
        line1: 10471,
        line2: 4434,
        line3: 2184,
        line4: 8630,
        line5: 2973,

        line7: 5177,

        line10: 968,
      },
      {
        name: 1919,
        line1: 10450,
        line2: 4503,
        line3: 2643,
        line4: 7605,
        line5: 3219,

        line7: 5271,

        line10: 1100,
      },
      {
        name: 1920,
        line1: 10153,
        line2: 4788,
        line3: 2942,
        line4: 7017,
        line5: 2974,

        line7: 5536,
        line8: 2241,

        line10: 1012,
      },
      {
        name: 1921,
        line1: 9675,
        line2: 4543,
        line3: 3003,
        line4: 6792,
        line5: 3244,

        line7: 5533,

        line10: 1082,
      },
      {
        name: 1922,
        line1: 10010,
        line2: 4868,
        line3: 3280,
        line4: 7391,
        line5: 3244,

        line7: 5796,

        line10: 1117,
      },
      {
        name: 1923,
        line1: 11071,
        line2: 5023,
        line3: 3486,
        line4: 7587,
        line5: 3244,

        line7: 6213,

        line10: 1070,
      },
      {
        name: 1924,
        line1: 11127,
        line2: 5365,
        line3: 3545,
        line4: 7844,
        line5: 3316,

        line7: 6464,

        line10: 1111,
      },
      {
        name: 1925,
        line1: 11150,
        line2: 5488,
        line3: 3711,
        line4: 8199,
        line5: 3414,

        line7: 6247,

        line10: 1113,
      },
      {
        name: 1926,
        line1: 11648,
        line2: 5756,
        line3: 3813,
        line4: 7868,
        line5: 3359,

        line7: 6366,

        line10: 1137,
      },
      {
        name: 1927,
        line1: 11532,
        line2: 5942,
        line3: 4076,
        line4: 8472,
        line5: 3347,

        line7: 6625,

        line10: 1125,
      },
      {
        name: 1928,
        line1: 11451,
        line2: 6113,
        line3: 4315,
        line4: 8539,
        line5: 3554,

        line7: 6840,

        line10: 1125,
      },
      {
        name: 1929,
        line1: 11954,
        line2: 6476,
        line3: 4331,
        line4: 8772,
        line5: 3665,

        line7: 6961,

        line9: 1003,
        line10: 1160,
      },
      {
        name: 1930,
        line1: 10695,
        line2: 6755,
        line3: 4250,
        line4: 8673,
        line5: 3334,

        line7: 6503,

        line9: 1012,
        line10: 1157,
      },
      {
        name: 1931,
        line1: 9931,
        line2: 6582,
        line3: 4114,
        line4: 8190,
        line5: 3321,

        line7: 5917,

        line9: 1015,
        line10: 1133,
      },
      {
        name: 1932,
        line1: 8381,
        line2: 6325,
        line3: 4065,
        line4: 8206,
        line5: 3529,

        line7: 5614,

        line9: 1039,
        line10: 1130,
      },
      {
        name: 1933,
        line1: 8048,
        line2: 6429,
        line3: 4307,
        line4: 8411,
        line5: 3787,

        line7: 5772,

        line9: 923,
        line10: 1116,
      },
      {
        name: 1934,
        line1: 8667,
        line2: 6845,
        line3: 4763,
        line4: 8939,
        line5: 3756,

        line7: 6129,

        line9: 937,
        line10: 1111,
      },
      {
        name: 1935,
        line1: 9681,
        line2: 7160,
        line3: 4930,
        line4: 9244,
        line5: 3825,

        line7: 6296,

        line9: 1007,
        line10: 1084,
      },
      {
        name: 1936,
        line1: 10568,
        line2: 7396,
        line3: 5227,
        line4: 9620,
        line5: 3986,

        line7: 6236,

        line9: 1065,
        line10: 1111,
      },
      {
        name: 1937,
        line1: 11295,
        line2: 7579,
        line3: 5485,
        line4: 9911,
        line5: 4075,

        line7: 6575,

        line9: 1034,
        line10: 1078,
      },
      {
        name: 1938,
        line1: 10526,
        line2: 7812,
        line3: 5721,
        line4: 9988,
        line5: 4257,

        line7: 6491,

        line9: 1003,
        line10: 1065,
      },
      {
        name: 1939,
        line1: 11171,
        line2: 8370,
        line3: 5432,
        line4: 9982,
        line5: 4804,

        line7: 6612,

        line10: 1074,
      },
      {
        name: 1940,
        line1: 12005,
        line2: 7739,
        line3: 5133,
        line4: 10928,
        line5: 4882,

        line7: 6633,
        line8: 3133,

        line10: 1093,
      },
      {
        name: 1941,
        line1: 13553,
        line2: 7511,
        line3: 5295,
        line4: 11926,
        line5: 4986,

        line7: 6861,

        line10: 1101,
      },
      {
        name: 1942,
        line1: 14870,
        line2: 7557,
        line3: 5303,
        line4: 12176,
        line5: 4939,

        line7: 6829,

        line10: 1082,
      },
      {
        name: 1943,
        line1: 16050,
        line2: 7779,
        line3: 5893,
        line4: 12344,
        line5: 4826,

        line7: 6666,

        line10: 1113,
      },
      {
        name: 1944,
        line1: 16999,
        line2: 7987,
        line3: 5874,
        line4: 11803,
        line5: 4816,

        line7: 7299,

        line10: 1089,
      },
      {
        name: 1945,
        line1: 16478,
        line2: 8201,
        line3: 5499,
        line4: 11247,
        line5: 3678,

        line7: 6943,

        line10: 1058,
      },
      {
        name: 1946,
        line1: 14822,
        line2: 9000,
        line3: 5871,
        line4: 10751,
        line5: 2771,

        line7: 7436,

        line10: 991,
      },
      {
        name: 1947,
        line1: 14312,
        line2: 9709,
        line3: 5925,
        line4: 10527,
        line5: 2711,

        line7: 8112,

        line10: 985,
      },
      {
        name: 1948,
        line1: 14734,
        line2: 9822,
        line3: 6307,
        line4: 10753,
        line5: 2857,

        line7: 8372,

        line10: 983,
      },
      {
        name: 1949,
        line1: 14197,
        line2: 10127,
        line3: 6604,
        line4: 11088,
        line5: 2867,

        line7: 8045,

        line10: 995,
      },
      {
        name: 1950,
        line1: 15240,
        line2: 10742,
        line3: 6779,
        line4: 11061,
        line5: 3062,

        line7: 7949,
        line8: 3351,
        line9: 799,
        line10: 987,
        line11: 1038,
      },
      {
        name: 1951,
        line1: 16125,
        line2: 11077,
        line3: 7286,
        line4: 11354,
        line5: 3389,

        line7: 8086,

        line9: 950,
        line10: 993,
        line11: 1229,
      },
      {
        name: 1952,
        line1: 16444,
        line2: 11152,
        line3: 7450,
        line4: 11303,
        line5: 3724,

        line7: 7519,

        line9: 1047,
        line10: 1003,
        line11: 1063,
      },
      {
        name: 1953,
        line1: 16917,
        line2: 11389,
        line3: 7415,
        line4: 11709,
        line5: 3944,

        line7: 7769,

        line9: 1157,
        line10: 1047,
        line11: 1009,
      },
      {
        name: 1954,
        line1: 16512,
        line2: 11799,
        line3: 7973,
        line4: 12145,
        line5: 4116,

        line7: 7938,

        line9: 1049,
        line10: 1071,
        line11: 1095,
      },
      {
        name: 1955,
        line1: 17370,
        line2: 12060,
        line3: 8284,
        line4: 12541,
        line5: 4417,

        line7: 8348,

        line9: 1119,
        line10: 1078,
        line11: 1144,
      },
      {
        name: 1956,
        line1: 17397,
        line2: 12428,
        line3: 8440,
        line4: 12639,
        line5: 4699,

        line7: 8424,

        line9: 1189,
        line10: 1117,
        line11: 1173,
      },
      {
        name: 1957,
        line1: 17406,
        line2: 12899,
        line3: 8751,
        line4: 12779,
        line5: 4999,

        line7: 8705,

        line9: 1195,
        line10: 1084,
        line11: 1176,
      },
      {
        name: 1958,
        line1: 16946,
        line2: 12884,
        line3: 8725,
        line4: 12698,
        line5: 5243,

        line7: 9083,

        line9: 1173,
        line10: 1141,
        line11: 1156,
      },
      {
        name: 1959,
        line1: 17900,
        line2: 13211,
        line3: 9172,
        line4: 13134,
        line5: 5665,

        line7: 8354,

        line9: 1117,
        line10: 1143,
        line11: 1148,
      },
      {
        name: 1960,
        line1: 18057,
        line2: 13849,
        line3: 9931,
        line4: 13780,
        line5: 6354,
        line6: 5557,
        line7: 8861,
        line8: 4386,
        line9: 1057,
        line10: 1200,
        line11: 1157,
      },
      {
        name: 1961,
        line1: 18175,
        line2: 14564,
        line3: 10613,
        line4: 14118,
        line5: 7055,
        line6: 5874,
        line7: 9344,

        line9: 874,
        line10: 1208,
        line11: 1093,
      },
      {
        name: 1962,
        line1: 18976,
        line2: 15093,
        line3: 10869,
        line4: 14131,
        line5: 7614,
        line6: 6229,
        line7: 9049,

        line9: 926,
        line10: 1208,
        line11: 1117,
      },
      {
        name: 1963,
        line1: 19514,
        line2: 15808,
        line3: 11148,
        line4: 14583,
        line5: 8176,
        line6: 6405,
        line7: 8695,

        line9: 1034,
        line10: 1242,
        line11: 1138,
      },
      {
        name: 1964,
        line1: 20360,
        line2: 16761,
        line3: 11647,
        line4: 15251,
        line5: 9035,
        line6: 6754,
        line7: 9446,

        line9: 1152,
        line10: 1309,
        line11: 1208,
      },
      {
        name: 1965,
        line1: 21390,
        line2: 17239,
        line3: 12226,
        line4: 15545,
        line5: 9459,
        line6: 7068,
        line7: 10155,

        line9: 1253,
        line10: 1229,
        line11: 1184,
      },
      {
        name: 1966,
        line1: 22529,
        line2: 17432,
        line3: 12471,
        line4: 15757,
        line5: 10370,
        line6: 7517,
        line7: 10076,

        line9: 1261,
        line10: 1215,
        line11: 1294,
      },
      {
        name: 1967,
        line1: 22842,
        line2: 17883,
        line3: 12667,
        line4: 16018,
        line5: 11400,
        line6: 7943,
        line7: 10200,

        line9: 1237,
        line10: 1286,
        line11: 1317,
      },
      {
        name: 1968,
        line1: 23691,
        line2: 18428,
        line3: 12900,
        line4: 16593,
        line5: 12725,
        line6: 8391,
        line7: 10485,

        line9: 1178,
        line10: 1290,
        line11: 1366,
      },
      {
        name: 1969,
        line1: 24195,
        line2: 19215,
        line3: 14151,
        line4: 16820,
        line5: 14145,
        line6: 8563,
        line7: 11217,

        line9: 1264,
        line10: 1347,
        line11: 1404,
      },
      {
        name: 1970,
        line1: 23958,
        line2: 20269,
        line3: 15266,
        line4: 17162,
        line5: 15484,
        line6: 9234,
        line7: 11639,
        line8: 5952,
        line9: 1398,
        line10: 1384,
        line11: 1458,
      },
      {
        name: 1971,
        line1: 24394,
        line2: 20320,
        line3: 15565,
        line4: 17440,
        line5: 16004,
        line6: 9567,
        line7: 12003,

        line9: 1446,
        line10: 1364,
        line11: 1500,
      },
      {
        name: 1972,
        line1: 25414,
        line2: 20725,
        line3: 16654,
        line4: 18002,
        line5: 17110,
        line6: 9785,
        line7: 12170,

        line9: 1431,
        line10: 1329,
        line11: 1524,
      },
      {
        name: 1973,
        line1: 26602,
        line2: 21509,
        line3: 17669,
        line4: 19168,
        line5: 18226,
        line6: 10492,
        line7: 12691,

        line9: 1513,
        line10: 1360,
        line11: 1546,
      },
      {
        name: 1974,
        line1: 26286,
        line2: 22132,
        line3: 18109,
        line4: 18903,
        line5: 17765,
        line6: 10801,
        line7: 13284,

        line9: 1519,
        line10: 1344,
        line11: 1564,
      },
      {
        name: 1975,
        line1: 25956,
        line2: 22607,
        line3: 18237,
        line4: 18884,
        line5: 18082,
        line6: 11164,
        line7: 12946,

        line9: 1594,
        line10: 1430,
        line11: 1502,
      },
      {
        name: 1976,
        line1: 27058,
        line2: 22765,
        line3: 18104,
        line4: 19311,
        line5: 18600,
        line6: 11426,
        line7: 12696,

        line9: 1519,
        line10: 1417,
        line11: 1503,
      },
      {
        name: 1977,
        line1: 28001,
        line2: 22322,
        line3: 18100,
        line4: 19740,
        line5: 19230,
        line6: 11692,
        line7: 13236,

        line9: 1583,
        line10: 1494,
        line11: 1580,
      },
      {
        name: 1978,
        line1: 29286,
        line2: 22646,
        line3: 18425,
        line4: 20448,
        line5: 20060,
        line6: 11829,
        line7: 12444,

        line9: 1744,
        line10: 1540,
        line11: 1656,
      },
      {
        name: 1979,
        line1: 29949,
        line2: 23465,
        line3: 19657,
        line4: 20988,
        line5: 20982,
        line6: 11867,
        line7: 13114,

        line9: 1859,
        line10: 1427,
        line11: 1651,
      },
      {
        name: 1980,
        line1: 29611,
        line2: 23809,
        line3: 20640,
        line4: 20612,
        line5: 21404,
        line6: 12204,
        line7: 13080,
        line8: 7233,
        line9: 1930,
        line10: 1495,
        line11: 1675,
      },
      {
        name: 1981,
        line1: 30056,
        line2: 23777,
        line3: 20935,
        line4: 20319,
        line5: 21924,
        line6: 12274,
        line7: 12125,

        line9: 1962,
        line10: 1557,
        line11: 1647,
      },
      {
        name: 1982,
        line1: 29210,
        line2: 24002,
        line3: 21495,
        line4: 20650,
        line5: 22440,
        line6: 12267,
        line7: 11550,

        line9: 2128,
        line10: 1570,
        line11: 1680,
      },
      {
        name: 1983,
        line1: 30158,
        line2: 24412,
        line3: 21944,
        line4: 21366,
        line5: 22805,
        line6: 12420,
        line7: 11775,

        line9: 2227,
        line10: 1663,
        line11: 1627,
      },
      {
        name: 1984,
        line1: 32076,
        line2: 25357,
        line3: 22486,
        line4: 21869,
        line5: 23548,
        line6: 12379,
        line7: 11837,

        line9: 2444,
        line10: 1690,
        line11: 1594,
      },
      {
        name: 1985,
        line1: 33023,
        line2: 25805,
        line3: 23148,
        line4: 22579,
        line5: 24437,
        line6: 12475,
        line7: 10895,

        line9: 2619,
        line10: 1720,
        line11: 1604,
      },
      {
        name: 1986,
        line1: 33850,
        line2: 26309,
        line3: 23621,
        line4: 23498,
        line5: 24992,
        line6: 12694,
        line7: 11515,

        line9: 2748,
        line10: 1755,
        line11: 1658,
      },
      {
        name: 1987,
        line1: 34730,
        line2: 27016,
        line3: 24519,
        line4: 24536,
        line5: 25904,
        line6: 12597,
        line7: 11633,

        line9: 2931,
        line10: 1793,
        line11: 1698,
      },
      {
        name: 1988,
        line1: 35863,
        line2: 27468,
        line3: 25644,
        line4: 25679,
        line5: 27393,
        line6: 12720,
        line7: 11244,

        line9: 3045,
        line10: 1938,
        line11: 1741,
      },
      {
        name: 1989,
        line1: 36756,
        line2: 27933,
        line3: 27012,
        line4: 26164,
        line5: 28601,
        line6: 12766,
        line7: 10393,

        line9: 3022,
        line10: 2024,
        line11: 1761,
      },
      {
        name: 1990,
        line1: 36982,
        line2: 28068,
        line3: 26884,
        line4: 26189,
        line5: 29949,
        line6: 12400,
        line7: 10254,
        line8: 8222,
        line9: 2982,
        line10: 2087,
        line11: 1780,
      },
      {
        name: 1991,
        line1: 36464,
        line2: 27614,
        line3: 25175,
        line4: 25792,
        line5: 30837,
        line6: 12012,
        line7: 11224,

        line9: 3051,
        line10: 2062,
        line11: 1778,
      },
      {
        name: 1992,
        line1: 37240,
        line2: 27199,
        line3: 24220,
        line4: 25790,
        line5: 30978,
        line6: 10488,
        line7: 12267,

        line9: 3228,
        line10: 2115,
        line11: 1725,
      },
      {
        name: 1993,
        line1: 37762,
        line2: 26559,
        line3: 23944,
        line4: 26349,
        line5: 30921,
        line6: 9789,
        line7: 12927,

        line9: 3506,
        line10: 2176,
        line11: 1698,
      },
      {
        name: 1994,
        line1: 38807,
        line2: 27506,
        line3: 24798,
        line4: 27278,
        line5: 31135,
        line6: 8744,
        line7: 13571,

        line9: 3730,
        line10: 2255,
        line11: 1730,
      },
      {
        name: 1995,
        line1: 39391,
        line2: 28519,
        line3: 25762,
        line4: 27861,
        line5: 31887,
        line6: 8586,
        line7: 13086,

        line9: 4000,
        line10: 2356,
        line11: 1790,
      },
      {
        name: 1996,
        line1: 40413,
        line2: 29012,
        line3: 26637,
        line4: 28472,
        line5: 32784,
        line6: 8479,
        line7: 13715,

        line9: 4220,
        line10: 2476,
        line11: 1851,
      },
      {
        name: 1997,
        line1: 41723,
        line2: 29863,
        line3: 28240,
        line4: 29260,
        line5: 33038,
        line6: 8813,
        line7: 14723,

        line9: 4311,
        line10: 2514,
        line11: 1846,
      },
      {
        name: 1998,
        line1: 43073,
        line2: 31187,
        line3: 29715,
        line4: 30077,
        line5: 32558,
        line6: 8557,
        line7: 15186,

        line9: 4310,
        line10: 2612,
        line11: 1890,
      },
      {
        name: 1999,
        line1: 44576,
        line2: 32705,
        line3: 30987,
        line4: 30933,
        line5: 32395,
        line6: 9340,
        line7: 14578,

        line9: 4467,
        line10: 2708,
        line11: 1921,
      },
      {
        name: 2000,
        line1: 45886,
        line2: 34203,
        line3: 32690,
        line4: 31946,
        line5: 33211,
        line6: 10553,
        line7: 14369,
        line8: 9915,
        line9: 4730,
        line10: 2753,
        line11: 1915,
      },
      {
        name: 2001,
        line1: 45878,
        line2: 34667,
        line3: 33482,
        line4: 32660,
        line5: 33246,
        line6: 11391,
        line7: 13652,

        line9: 4995,
        line10: 2838,
        line11: 1972,
      },
      {
        name: 2002,
        line1: 46266,
        line2: 35570,
        line3: 33987,
        line4: 33271,
        line5: 33195,
        line6: 12259,
        line7: 12095,

        line9: 5343,
        line10: 2871,
        line11: 1962,
      },
      {
        name: 2003,
        line1: 47158,
        line2: 36436,
        line3: 34607,
        line4: 34232,
        line5: 33620,
        line6: 13521,
        line7: 13089,

        line9: 5649,
        line10: 3036,
        line11: 1998,
      },
      {
        name: 2004,
        line1: 48493,
        line2: 38016,
        line3: 35889,
        line4: 34866,
        line5: 34314,
        line6: 14881,
        line7: 14183,

        line9: 6068,
        line10: 3213,
        line11: 2066,
      },
      {
        name: 2005,
        line1: 49655,
        line2: 39259,
        line3: 36787,
        line4: 35624,
        line5: 34858,
        line6: 16243,
        line7: 15344,

        line9: 6627,
        line10: 3397,
        line11: 2158,
      },
      {
        name: 2006,
        line1: 50490,
        line2: 40992,
        line3: 38161,
        line4: 36214,
        line5: 35333,
        line6: 18008,
        line7: 16490,

        line9: 7271,
        line10: 3629,
        line11: 2255,
      },
      {
        name: 2007,
        line1: 50902,
        line2: 42400,
        line3: 39999,
        line4: 36817,
        line5: 35893,
        line6: 20018,
        line7: 17892,

        line9: 7896,
        line10: 3871,
        line11: 2381,
      },
      {
        name: 2008,
        line1: 50276,
        line2: 42190,
        line3: 40130,
        line4: 36265,
        line5: 35496,
        line6: 21563,
        line7: 18520,

        line9: 8190,
        line10: 4031,
        line11: 2357,
      },
      {
        name: 2009,
        line1: 48453,
        line2: 40116,
        line3: 36663,
        line4: 34402,
        line5: 33590,
        line6: 20336,
        line7: 17329,

        line9: 8734,
        line10: 4271,
        line11: 2405,
      },
      {
        name: 2010,
        line1: 49267,
        line2: 42635,
        line3: 37615,
        line4: 34754,
        line5: 35011,
        line6: 21737,
        line7: 18980,
        line8: 13179,
        line9: 9658,
        line10: 4526,
        line11: 2580,
      },
      {
        name: 2011,
        line1: 49675,
        line2: 42079,
        line3: 38432,
        line4: 34971,
        line5: 34979,
        line6: 23130,
        line7: 20003,

        line9: 10221,
        line10: 4768,
        line11: 2712,
      },
      {
        name: 2012,
        line1: 50394,
        line2: 41650,
        line3: 37704,
        line4: 35185,
        line5: 35580,
        line6: 23931,
        line7: 19599,

        line9: 10680,
        line10: 4932,
        line11: 2765,
      },
      {
        name: 2013,
        line1: 50863,
        line2: 41811,
        line3: 37246,
        line4: 35618,
        line5: 36354,
        line6: 24224,
        line7: 19873,

        line9: 11328,
        line10: 5172,
        line11: 2854,
      },
      {
        name: 2014,
        line1: 51664,
        line2: 42476,
        line3: 36858,
        line4: 36436,
        line5: 36537,
        line6: 24387,
        line7: 19183,

        line9: 11944,
        line10: 5458,
        line11: 2942,
      },
      {
        name: 2015,
        line1: 52591,
        line2: 43746,
        line3: 36836,
        line4: 36941,
        line5: 37031,
        line6: 23691,
        line7: 19502,

        line9: 12244,
        line10: 5794,
        line11: 3046,
      },
      {
        name: 2016,
        line1: 53015,
        line2: 44659,
        line3: 37239,
        line4: 37334,
        line5: 37465,
        line6: 23635,
        line7: 18875,
        line8: 14700,
        line9: 12569,
        line10: 6125,
        line11: 3169,
      },
      {
        name: 2017,
        line1: 54008,
        line2: 45193,
        line3: 38367,
        line4: 37783,
        line5: 38415,
        line6: 24043,
        line7: 19201,
        line8: 14944,
        line9: 12734,
        line10: 6449,
        line11: 3250,
      },
      {
        name: 2018,
        line1: 55335,
        line2: 45542,
        line3: 38897,
        line4: 38058,
        line5: 38674,
        line6: 24669,
        line7: 18556,
        line8: 15212,
        line9: 13102,
        line10: 6806,
        line11: 3377,
      },
      {
        name: 2019,
      },
      {
        name: 2020,
      },
    ],
  },
  {
    name: "kuvio-2.1",
    source: "Kuvio 1. Jäätelön kysyntä",
    width: "100%",
    height: 350,
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 8,
    yAxisWidth: 50,
    xTickCount: 8,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    names: ["Hinta"],
    manualTicksX: [0, 100, 200, 300, 400, 500, 600, 700],
    data: [
      {
        name: 0,
        line1: 8,
      },
      {
        name: 50,
        line1: 7,
      },
      {
        name: 100,
        line1: 6,
      },
      {
        name: 150,
        line1: 5,
      },
      {
        name: 200,
        line1: 4,
      },
      {
        name: 250,
        line1: 3.5,
      },
      {
        name: 300,
        line1: 3,
      },
      {
        name: 350,
        line1: 2.5,
      },
      {
        name: 400,
        line1: 2,
      },
      {
        name: 450,
        line1: 1.7,
      },
      {
        name: 500,
        line1: 1.3,
      },
      {
        name: 550,
        line1: 1,
      },
      {
        name: 600,
        line1: 0.8,
      },
      {
        name: 650,
        line1: 0.6,
      },
      {
        name: 700,
        line1: 0.4,
      },
    ],
  },
  {
    name: "kuvio-2.2",
    source: "Kuvio 2: Vuokrayksiöiden kysyntä",
    width: "100%",
    height: 350,
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 8,
    yAxisWidth: 60,
    xTickCount: 8,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    names: ["Hinta"],
    data: [
      {
        name: 200,
        line1: 875,
      },
      {
        name: 250,
        line1: 824,
      },
      {
        name: 300,
        line1: 750,
      },
      {
        name: 350,
        line1: 700,
      },
      {
        name: 400,
        line1: 650,
      },
      {
        name: 450,
        line1: 575,
      },
      {
        name: 500,
        line1: 525,
      },
      {
        name: 550,
        line1: 450,
      },
    ],
  },
  {
    name: "kuvio-2.3a",
    source: "Kuvio 3a: Joustava kysyntä",
    width: "100%",
    height: 350,
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 8,
    yAxisWidth: 50,
    xTickCount: 8,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    xDomain: [0, 500],
    yDomain: [0, 30],
    dot: true,
    type: ["number", "number"],
    manualTicksX: [0, 100, 200, 300, 400, 500],
    names: ["Hinta"],
    data: [
      {
        name: 0,
        line1: 25,
      },
      {
        name: 50,
        line1: 24,
      },
      {
        name: 100,
        line1: 23,
      },
      {
        name: 150,
        line1: 22,
      },
      {
        name: 200,
        line1: 21,
      },
      {
        name: 250,
        line1: 20,
      },
      {
        name: 300,
        line1: 19,
      },
      {
        name: 350,
        line1: 18,
      },
      {
        name: 400,
        line1: 17,
      },
      {
        name: 450,
        line1: 16,
      },
    ],
  },
  {
    name: "kuvio-2.3b",
    source: "Kuvio 3b: Joustamaton kysyntä",
    width: "100%",
    height: 350,
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    dot: true,
    xDomain: [0, 350],
    yDomain: [0, 45],
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 8,
    yAxisWidth: 50,
    xTickCount: 8,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    names: ["Hinta"],
    type: ["number", "number"],
    manualTicksX: [0, 50, 100, 150, 200, 250, 300, 350],
    data: [
      {
        name: 230,
        line1: 40,
      },
      {
        name: 240,
        line1: 35,
      },
      {
        name: 250,
        line1: 30,
      },
      {
        name: 260,
        line1: 25,
      },
      {
        name: 270,
        line1: 20,
      },
      {
        name: 280,
        line1: 15,
      },
      {
        name: 290,
        line1: 10,
      },
      {
        name: 300,
        line1: 5,
      },
    ],
  },
  {
    name: "kuvio-2.4",
    source: "Kuvio 4: Jäätelön tarjonta",
    width: "100%",
    height: 350,
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 25,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 8,
    yAxisWidth: 45,
    xTickCount: 8,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    names: ["Hinta"],
    xDomain: ["dataMin", "dataMax" + 100],
    manualTicksX: [0, 100, 200, 300, 400, 500, 600, 700],
    manualTicksY: [0, 2, 4, 6, 8, 10, 12],
    type: ["number", "number"],
    data: [
      {
        name: 0,
        line1: 2,
      },
      {
        name: 80,
        line1: 3,
      },
      {
        name: 150,
        line1: 4,
      },
      {
        name: 200,
        line1: 5,
      },
      {
        name: 300,
        line1: 6,
      },
      {
        name: 400,
        line1: 7,
      },
      {
        name: 500,
        line1: 8,
      },
      {
        name: 600,
        line1: 10,
      },
    ],
  },
  {
    name: "kuvio-2.5",
    source: "Kuvio 5: Vuokrayksiöiden tarjonta",
    width: "100%",
    height: 350,
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 25,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 8,
    yAxisWidth: 60,
    xTickCount: 8,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    names: ["Hinta"],
    data: [
      {
        name: 200,
        line1: 500,
      },
      {
        name: 250,
        line1: 525,
      },
      {
        name: 300,
        line1: 550,
      },
      {
        name: 350,
        line1: 600,
      },
      {
        name: 400,
        line1: 650,
      },
      {
        name: 450,
        line1: 700,
      },
      {
        name: 500,
        line1: 725,
      },
      {
        name: 550,
        line1: 750,
      },
    ],
  },
  {
    name: "kuvio-2.6",
    source:
      "Kuvio 6: Vuokrayksiöiden kysyntä ja tarjonta: tasapainohinta 650€, määrä 400 asuntoa",
    width: "100%",
    yDomain: ["dataMin", "dataMax+25"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 25,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 60,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    xAxisPadding: "25px",
    type: ["number", "number"], // [xAxis, yAxis]
    referenceArea: [200, 400, 450, 650], // [x1,x2,y1,y2]
    names: ["Vuokra-asuntojen tarjonta", "Vuokra-asuntojen kysyntä"],
    manualTicksY: [450, 500, 550, 600, 650, 700, 750, 800, 850, 900],
    data: [
      {
        name: 200,
        line1: 500,
        line2: 875,
      },
      {
        name: 250,
        line1: 525,
        line2: 824,
      },
      {
        name: 300,
        line1: 550,
        line2: 750,
      },
      {
        name: 350,
        line1: 600,
        line2: 700,
      },
      {
        name: 400,
        line1: 650,
        line2: 650,
      },
      {
        name: 450,
        line1: 700,
        line2: 575,
      },
      {
        name: 500,
        line1: 725,
        line2: 525,
      },
      {
        name: 550,
        line1: 750,
        line2: 450,
      },
    ],
  },
  {
    name: "kuvio-2.7a",
    source:
      "Kuvio 7a: Vuokra-asuntojen kysyntä ja tarjonta: hinnalla €700, ylitarjontaa 100 asuntoa",
    width: "100%",
    yDomain: ["dataMin-50", "dataMax+25"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 25,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 60,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    xAxisPadding: "25px",
    type: ["number", "number"],
    referenceArea: [350, 450, 400, 700],
    names: ["Vuokra-asuntojen tarjonta", "Vuokra-asuntojen kysyntä"],
    data: [
      {
        name: 200,
        line1: 500,
        line2: 875,
      },
      {
        name: 250,
        line1: 525,
        line2: 824,
      },
      {
        name: 300,
        line1: 550,
        line2: 750,
      },
      {
        name: 350,
        line1: 600,
        line2: 700,
      },
      {
        name: 400,
        line1: 650,
        line2: 650,
      },
      {
        name: 450,
        line1: 700,
        line2: 575,
      },
      {
        name: 500,
        line1: 725,
        line2: 525,
      },
      {
        name: 550,
        line1: 750,
        line2: 450,
      },
    ],
  },
  {
    name: "kuvio-2.7b",
    source:
      "Kuvio 7b: Vuokra-asuntojen kysyntä ja tarjonta: hinnalla €550, ylikysyntää 175 asuntoa",
    width: "100%",
    yDomain: ["dataMin-50", "dataMax+25"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 25,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 60,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    xAxisPadding: "25px",
    type: ["number", "number"],
    referenceArea: [300, 470, 400, 550],
    names: ["Vuokra-asuntojen tarjonta", "Vuokra-asuntojen kysyntä"],
    data: [
      {
        name: 200,
        line1: 500,
        line2: 875,
      },
      {
        name: 250,
        line1: 525,
        line2: 824,
      },
      {
        name: 300,
        line1: 550,
        line2: 750,
      },
      {
        name: 350,
        line1: 600,
        line2: 700,
      },
      {
        name: 400,
        line1: 650,
        line2: 650,
      },
      {
        name: 450,
        line1: 700,
        line2: 575,
      },
      {
        name: 500,
        line1: 725,
        line2: 525,
      },
      {
        name: 550,
        line1: 750,
        line2: 450,
      },
    ],
  },
  {
    name: "kuvio-2.10",
    source: "Kuvio 10: Kysyntä eri päivinä ja eri säällä",
    width: "100%",
    height: 300,
    yDomain: ["dataMin", "dataMax"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 25,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 40,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    type: ["number", "number"],
    manualTicksY: [0, 2, 4, 6, 8, 10],
    names: ["Kysyntä helteellä", "Kysyntä pilvisellä säällä"],
    data: [
      {
        name: 0,
        line1: 7,
        line2: 6,
      },
      {
        name: 50,
        line1: 6,
        line2: 5,
      },
      {
        name: 100,
        line1: 5,
        line2: 4,
      },
      {
        name: 150,
        line1: 4.5,
        line2: 3.5,
      },
      {
        name: 200,
        line1: 4,
        line2: 3,
      },
      {
        name: 250,
        line1: 3.5,
        line2: 2.5,
      },
      {
        name: 300,
        line1: 3,
        line2: 2,
      },
      {
        name: 350,
        line1: 2.5,
        line2: 1.5,
      },
      {
        name: 400,
        line1: 2,
        line2: 1,
      },
      {
        name: 450,
        line1: 1.7,
        line2: 0.7,
      },
      {
        name: 500,
        line1: 1.3,
        line2: 0.3,
      },
    ],
  },
  {
    name: "kuvio-2.11",
    source: "Kuvio 11: Tarjonnan siirtymät",
    width: "100%",
    height: 400,
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    xDomain: ["dataMin", "dataMax" + 50],
    yAxisOffset: 25,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 55,
    margins: [0, 15, 10, 5],
    tickDx: 0,
    type: ["category", "number"],
    names: ["Kallis raakaöljy", "Halpa raakaöljy"],
    manualTicksY: [0, 5, 10, 15, 20, 25, 30],
    manualTicksX: [0, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500],
    data: [
      {
        name: 0,
        line1: 16,
        line2: 12,
      },
      {
        name: 50,
        line1: 17,
        line2: 13,
      },
      {
        name: 100,
        line1: 18,
        line2: 14,
      },
      {
        name: 150,
        line1: 19,
        line2: 15,
      },
      {
        name: 200,
        line1: 20,
        line2: 16,
      },
      {
        name: 250,
        line1: 21,
        line2: 17,
      },
      {
        name: 300,
        line1: 22,
        line2: 18,
      },
      {
        name: 350,
        line1: 23,
        line2: 19,
      },
      {
        name: 400,
        line1: 24,
        line2: 20,
      },
      {
        name: 450,
        line1: 25,
        line2: 21,
      },
    ],
  },
  {
    name: "kuvio-2.13",
    source: "Kuvio 13: Kulutusveron vaikutus kysyntään",
    width: "100%",
    yDomain: ["dataMin", "dataMax+1"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Myyntihinta P",
    yAxisOffset: 60,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 60,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    type: ["number", "number"],
    manualTicksY: [
      0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9,
      9.5,
    ],
    names: [
      "Kysyntä ilman veroa",
      "Kysyntä verojen kanssa",
      "Jäätelön tarjonta",
    ],
    chooseReference: 3, // number of line that is used to reference others
    data: [
      {
        name: 0,
        line1: 7,
        line2: 6,
        line3: 1,
      },
      {
        name: 50,
        line1: 6,
        line2: 5,
        line3: 2,
      },
      {
        name: 100,
        line1: 5,
        line2: 4,
        line3: 3,
      },
      {
        name: 150,
        line1: 4.5,
        line2: 3.5,
        line3: 3.5,
      },
      {
        name: 200,
        line1: 4,
        line2: 3,
        line3: 4,
      },
      {
        name: 250,
        line1: 3.5,
        line2: 2.5,
        line3: 5,
      },
      {
        name: 300,
        line1: 3,
        line2: 2,
        line3: 5.5,
      },
      {
        name: 350,
        line1: 2.5,
        line2: 1.5,
        line3: 6,
      },
      {
        name: 400,
        line1: 2,
        line2: 1,
        line3: 7,
      },
      {
        name: 450,
        line1: 1.7,
        line2: 0.7,
        line3: 8,
      },
      {
        name: 500,
        line1: 1.3,
        line2: 0.3,
        line3: 9,
      },
    ],
  },
  {
    name: "kuvio-2.14",
    source:
      "Kuvio 14: Myyntiveron vaikutus tarjontaan. Jäätelön kysyntä ja tarjonta: myyjä maksaa veron",
    width: "100%",
    yDomain: ["dataMin-1", "dataMax+1"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Myyntihinta P",
    yAxisOffset: 60,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 60,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    type: ["number", "number"],
    referenceArea: [0, 150, 0, 4.5],
    names: ["Kysyntä", "Tarjonta verojen kanssa", "Tarjonta ilman veroa"],
    chooseReference: 3, // number of line that is used to reference others
    data: [
      {
        name: 0,
        line1: 7,
        line2: 2,
        line3: 1,
      },
      {
        name: 50,
        line1: 6,
        line2: 3,
        line3: 2,
      },
      {
        name: 100,
        line1: 5,
        line2: 4,
        line3: 3,
      },
      {
        name: 150,
        line1: 4.5,
        line2: 4.5,
        line3: 3.5,
      },
      {
        name: 200,
        line1: 4,
        line2: 5,
        line3: 4,
      },
      {
        name: 250,
        line1: 3.5,
        line2: 6,
        line3: 5,
      },
      {
        name: 300,
        line1: 3,
        line2: 6.5,
        line3: 5.5,
      },
      {
        name: 350,
        line1: 2.5,
        line2: 7,
        line3: 6,
      },
      {
        name: 400,
        line1: 2,
        line2: 8,
        line3: 7,
      },
      {
        name: 450,
        line1: 1.7,
        line2: 9,
        line3: 8,
      },
      {
        name: 500,
        line1: 1.3,
        line2: 10,
        line3: 9,
      },
    ],
  },
  {
    name: "kuvio-2.15",
    source:
      "Kuvio 15: Vuokrakaton vaikutus. Vuokrakatto 525€ vain 250 asuntoa vuokrataan vaikka kysyntää 500:lle",
    width: "100%",
    yDomain: ["dataMin", "dataMax"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 40,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 60,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    type: ["number", "number"],
    referenceArea: [250, 500, 400, 525],
    manualTicksY: [400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900],
    names: ["Vuokra-asuntojen tarjonta", "Vuokra-asuntojen kysyntä"],
    chooseReference: 3, // number of line that is used to reference others
    data: [
      {
        name: 200,
        line1: 500,
        line2: 875,
      },
      {
        name: 250,
        line1: 525,
        line2: 824,
      },
      {
        name: 300,
        line1: 550,
        line2: 750,
      },
      {
        name: 350,
        line1: 600,
        line2: 700,
      },
      {
        name: 400,
        line1: 650,
        line2: 650,
      },
      {
        name: 450,
        line1: 700,
        line2: 575,
      },
      {
        name: 500,
        line1: 725,
        line2: 525,
      },
      {
        name: 550,
        line1: 750,
        line2: 450,
      },
    ],
  },
  {
    name: "kuvio-2.16",
    source:
      "Kuvio 16: Asumistuen vaikutus. Asumistuki €200: vuokralaisen maksettavaksi jää €525 ja 500 asuntoa vuokrataan",
    width: "100%",
    yDomain: ["dataMin-50", "dataMax"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Määrä Q",
    yAxisLabel: "Hinta P",
    yAxisOffset: 40,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 60,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    type: ["number", "number"],
    referenceArea: [200, 500, 400, 725],
    names: ["Tarjonta", "Kysyntä", "Tuettu kysyntä"],
    chooseReference: 3, // number of line that is used to reference others
    data: [
      {
        name: 200,
        line1: 500,
        line2: 875,
        line3: 1075,
      },
      {
        name: 250,
        line1: 525,
        line2: 824,
        line3: 1024,
      },
      {
        name: 300,
        line1: 550,
        line2: 750,
        line3: 950,
      },
      {
        name: 350,
        line1: 600,
        line2: 700,
        line3: 900,
      },
      {
        name: 400,
        line1: 650,
        line2: 650,
        line3: 850,
      },
      {
        name: 450,
        line1: 700,
        line2: 575,
        line3: 775,
      },
      {
        name: 500,
        line1: 725,
        line2: 525,
        line3: 725,
      },
      {
        name: 550,
        line1: 750,
        line2: 450,
        line3: 650,
      },
    ],
  },
  {
    name: "kuvio-5.2",
    width: "100%",
    height: 600,
    yDomain: [0, "dataMax"],
    xDomain: [0, "dataMax"],
    xAxisLabel: "Vuosi",
    yAxisLabel: "Dollaria",
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 10,
    yTickCount: 8,
    yAxisWidth: 70,
    xTickCount: 25,
    interval: 0,
    dot: true,
    connectNulls: true,
    margins: [20, 25, 10, 5],
    type: ["number", "number"],
    manualTicksY: [
      1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000,
      13000, 14000, 15000, 16000,
    ],
    manualTicksX: [0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000],
    names: [" "],
    data: [
      {
        name: 0,
        line1: 772,
      },
      {
        name: 10,
      },
      {
        name: 20,
      },
      {
        name: 30,
      },
      {
        name: 40,
      },
      {
        name: 50,
      },
      {
        name: 60,
      },
      {
        name: 70,
      },
      {
        name: 80,
      },
      {
        name: 90,
      },
      {
        name: 100,
      },
      {
        name: 110,
      },
      {
        name: 120,
      },
      {
        name: 130,
      },
      {
        name: 140,
      },
      {
        name: 150,
      },
      {
        name: 160,
      },
      {
        name: 170,
      },
      {
        name: 180,
      },
      {
        name: 190,
      },
      {
        name: 200,
      },
      {
        name: 210,
      },
      {
        name: 220,
      },
      {
        name: 230,
      },
      {
        name: 240,
      },
      {
        name: 250,
      },
      {
        name: 260,
      },
      {
        name: 270,
      },
      {
        name: 280,
      },
      {
        name: 290,
      },
      {
        name: 300,
      },
      {
        name: 310,
      },
      {
        name: 320,
      },
      {
        name: 330,
      },
      {
        name: 340,
      },
      {
        name: 350,
      },
      {
        name: 360,
      },
      {
        name: 370,
      },
      {
        name: 380,
      },
      {
        name: 390,
      },
      {
        name: 400,
      },
      {
        name: 410,
      },
      {
        name: 420,
      },
      {
        name: 430,
      },
      {
        name: 440,
      },
      {
        name: 450,
      },
      {
        name: 460,
      },
      {
        name: 470,
      },
      {
        name: 480,
      },
      {
        name: 490,
      },
      {
        name: 500,
      },
      {
        name: 510,
      },
      {
        name: 520,
      },
      {
        name: 530,
      },
      {
        name: 540,
      },
      {
        name: 550,
      },
      {
        name: 560,
      },
      {
        name: 570,
      },
      {
        name: 580,
      },
      {
        name: 590,
      },
      {
        name: 600,
      },
      {
        name: 610,
      },
      {
        name: 620,
      },
      {
        name: 630,
      },
      {
        name: 640,
      },
      {
        name: 650,
      },
      {
        name: 660,
      },
      {
        name: 670,
      },
      {
        name: 680,
      },
      {
        name: 690,
      },
      {
        name: 700,
      },
      {
        name: 710,
      },
      {
        name: 720,
      },
      {
        name: 730,
      },
      {
        name: 740,
      },
      {
        name: 750,
      },
      {
        name: 760,
      },
      {
        name: 770,
      },
      {
        name: 780,
      },
      {
        name: 790,
      },
      {
        name: 800,
      },
      {
        name: 810,
      },
      {
        name: 820,
      },
      {
        name: 830,
      },
      {
        name: 840,
      },
      {
        name: 850,
      },
      {
        name: 860,
      },
      {
        name: 870,
      },
      {
        name: 880,
      },
      {
        name: 890,
      },
      {
        name: 900,
      },
      {
        name: 910,
      },
      {
        name: 920,
      },
      {
        name: 930,
      },
      {
        name: 940,
      },
      {
        name: 950,
      },
      {
        name: 960,
      },
      {
        name: 970,
      },
      {
        name: 980,
      },
      {
        name: 990,
      },
      {
        name: 1000,
        line1: 750,
      },
      {
        name: 1010,
      },
      {
        name: 1020,
      },
      {
        name: 1030,
      },
      {
        name: 1040,
      },
      {
        name: 1050,
      },
      {
        name: 1060,
      },
      {
        name: 1070,
      },
      {
        name: 1080,
      },
      {
        name: 1090,
      },
      {
        name: 1100,
      },
      {
        name: 1110,
      },
      {
        name: 1120,
      },
      {
        name: 1130,
      },
      {
        name: 1140,
      },
      {
        name: 1150,
      },
      {
        name: 1160,
      },
      {
        name: 1170,
      },
      {
        name: 1180,
      },
      {
        name: 1190,
      },
      {
        name: 1200,
      },
      {
        name: 1210,
      },
      {
        name: 1220,
      },
      {
        name: 1230,
      },
      {
        name: 1240,
      },
      {
        name: 1250,
      },
      {
        name: 1260,
      },
      {
        name: 1270,
      },
      {
        name: 1280,
      },
      {
        name: 1290,
      },
      {
        name: 1300,
      },
      {
        name: 1310,
      },
      {
        name: 1320,
      },
      {
        name: 1330,
      },
      {
        name: 1340,
      },
      {
        name: 1350,
      },
      {
        name: 1360,
      },
      {
        name: 1370,
      },
      {
        name: 1380,
      },
      {
        name: 1390,
      },
      {
        name: 1400,
      },
      {
        name: 1410,
      },
      {
        name: 1420,
      },
      {
        name: 1430,
      },
      {
        name: 1440,
      },
      {
        name: 1450,
      },
      {
        name: 1460,
      },
      {
        name: 1470,
      },
      {
        name: 1480,
      },
      {
        name: 1490,
      },
      {
        name: 1500,
        line1: 937,
      },
      {
        name: 1510,
      },
      {
        name: 1520,
      },
      {
        name: 1530,
      },
      {
        name: 1540,
      },
      {
        name: 1550,
      },
      {
        name: 1560,
      },
      {
        name: 1570,
      },
      {
        name: 1580,
      },
      {
        name: 1590,
      },
      {
        name: 1600,
        line1: 986,
      },
      {
        name: 1610,
      },
      {
        name: 1620,
      },
      {
        name: 1630,
      },
      {
        name: 1640,
      },
      {
        name: 1650,
      },
      {
        name: 1660,
      },
      {
        name: 1670,
      },
      {
        name: 1680,
      },
      {
        name: 1690,
      },
      {
        name: 1700,
        line1: 1017,
      },
      {
        name: 1710,
      },
      {
        name: 1720,
      },
      {
        name: 1730,
      },
      {
        name: 1740,
      },
      {
        name: 1750,
      },
      {
        name: 1760,
      },
      {
        name: 1770,
      },
      {
        name: 1780,
      },
      {
        name: 1790,
      },
      {
        name: 1800,
      },
      {
        name: 1810,
      },
      {
        name: 1820,
        line1: 1102,
      },
      {
        name: 1830,
      },
      {
        name: 1840,
      },
      {
        name: 1850,
        line1: 1225,
      },
      {
        name: 1860,
      },
      {
        name: 1870,
        line1: 1498,
      },
      {
        name: 1880,
      },
      {
        name: 1890,
      },
      {
        name: 1900,
        line1: 2212,
      },
      {
        name: 1910,
      },
      {
        name: 1920,
        line1: 2241,
      },
      {
        name: 1930,
      },
      {
        name: 1940,
        line1: 3133,
      },
      {
        name: 1950,
        line1: 3351,
      },
      {
        name: 1960,
        line1: 4386,
      },
      {
        name: 1970,
        line1: 5952,
      },
      {
        name: 1980,
        line1: 7233,
      },
      {
        name: 1990,
        line1: 8222,
      },
      {
        name: 2000,
        line1: 9915,
      },
      {
        name: 2010,
        line1: 13179,
      },
      {
        name: 2020,
        line1: 14786,
      },
      {
        name: 2030,
      },
      {
        name: 2040,
      },
      {
        name: 2050,
      },
      {
        name: 2060,
      },
      {
        name: 2070,
      },
      {
        name: 2080,
      },
      {
        name: 2090,
      },
      {
        name: 2100,
      },
    ],
  },
  {
    name: "kuvio-5.15a",
    width: "100%",
    height: 450,
    yDomain: ["dataMin", "dataMax"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 15,
    yAxisWidth: 50,
    xTickCount: 8,
    margins: [0, 25, 0, 5],
    tickDx: 0,
    type: ["number", "number"],
    manualTicksY: [0, 5, 10, 15, 20, 25, 30, 35],
    manualTicksX: [
      1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020, 2030,
    ],
    names: ["Suomi", "Yhdysvallat", "Iso-Britannia"],
    data: [
      {
        name: 1920,
        line1: 12,

        line3: 8,
      },
      {
        name: 1921,
        line1: 11,

        line3: 9,
      },
      {
        name: 1922,
        line1: 12,

        line3: 9,
      },
      {
        name: 1923,
        line1: 14,

        line3: 8,
      },
      {
        name: 1924,
        line1: 14,

        line3: 9,
      },
      {
        name: 1925,
        line1: 13,

        line3: 9,
      },
      {
        name: 1926,
        line1: 15,

        line3: 9,
      },
      {
        name: 1927,
        line1: 16,

        line3: 9,
      },
      {
        name: 1928,
        line1: 20,

        line3: 9,
      },
      {
        name: 1929,
        line1: 17,
        line2: 19,
        line3: 10,
      },
      {
        name: 1930,
        line1: 14,
        line2: 16,
        line3: 10,
      },
      {
        name: 1931,
        line1: 11,
        line2: 12,
        line3: 10,
      },
      {
        name: 1932,
        line1: 12,
        line2: 7,
        line3: 8,
      },
      {
        name: 1933,
        line1: 11,
        line2: 8,
        line3: 8,
      },
      {
        name: 1934,
        line1: 12,
        line2: 10,
        line3: 10,
      },
      {
        name: 1935,
        line1: 14,
        line2: 14,
        line3: 10,
      },
      {
        name: 1936,
        line1: 16,
        line2: 16,
        line3: 10,
      },
      {
        name: 1937,
        line1: 18,
        line2: 18,
        line3: 11,
      },
      {
        name: 1938,
        line1: 19,
        line2: 14,
        line3: 11,
      },
      {
        name: 1939,
        line1: 20,
        line2: 16,
        line3: 9,
      },
      {
        name: 1940,
        line1: 23,
        line2: 18,
        line3: 7,
      },
      {
        name: 1941,
        line1: 10,
        line2: 23,
        line3: 6,
      },
      {
        name: 1942,
        line1: 11,
        line2: 25,
        line3: 5,
      },
      {
        name: 1943,
        line1: 11,
        line2: 23,
        line3: 4,
      },
      {
        name: 1944,
        line1: 9,
        line2: 21,
        line3: 3,
      },
      {
        name: 1945,
        line1: 13,
        line2: 17,
        line3: 4,
      },
      {
        name: 1946,
        line1: 17,
        line2: 17,
        line3: 9,
      },
      {
        name: 1947,
        line1: 18,
        line2: 17,
        line3: 11,
      },
      {
        name: 1948,
        line1: 22,
        line2: 21,
        line3: 9,
      },
      {
        name: 1949,
        line1: 23,
        line2: 18,
        line3: 10,
      },
      {
        name: 1950,
        line1: 23,
        line2: 23,
        line3: 11,
      },
      {
        name: 1951,
        line1: 23,
        line2: 24,
        line3: 12,
      },
      {
        name: 1952,
        line1: 27,
        line2: 22,
        line3: 12,
      },
      {
        name: 1953,
        line1: 28,
        line2: 22,
        line3: 13,
      },
      {
        name: 1954,
        line1: 27,
        line2: 21,
        line3: 13,
      },
      {
        name: 1955,
        line1: 26,
        line2: 23,
        line3: 14,
      },
      {
        name: 1956,
        line1: 26,
        line2: 23,
        line3: 15,
      },
      {
        name: 1957,
        line1: 25,
        line2: 23,
        line3: 16,
      },
      {
        name: 1958,
        line1: 25,
        line2: 22,
        line3: 16,
      },
      {
        name: 1959,
        line1: 26,
        line2: 23,
        line3: 16,
      },
      {
        name: 1960,
        line1: 29,
        line2: 23,
        line3: 17,
      },
      {
        name: 1961,
        line1: 29,
        line2: 23,
        line3: 18,
      },
      {
        name: 1962,
        line1: 28,
        line2: 23,
        line3: 18,
      },
      {
        name: 1963,
        line1: 26,
        line2: 23,
        line3: 18,
      },
      {
        name: 1964,
        line1: 26,
        line2: 23,
        line3: 19,
      },
      {
        name: 1965,
        line1: 27,
        line2: 24,
        line3: 20,
      },
      {
        name: 1966,
        line1: 27,
        line2: 24,
        line3: 20,
      },
      {
        name: 1967,
        line1: 26,
        line2: 23,
        line3: 20,
      },
      {
        name: 1968,
        line1: 24,
        line2: 23,
        line3: 21,
      },
      {
        name: 1969,
        line1: 24,
        line2: 23,
        line3: 21,
      },
      {
        name: 1970,
        line1: 27,
        line2: 21,
        line3: 23,
      },
      {
        name: 1971,
        line1: 28,
        line2: 22,
        line3: 24,
      },
      {
        name: 1972,
        line1: 29,
        line2: 22,
        line3: 23,
      },
      {
        name: 1973,
        line1: 30,
        line2: 22,
        line3: 25,
      },
      {
        name: 1974,
        line1: 31,
        line2: 22,
        line3: 26,
      },
      {
        name: 1975,
        line1: 33,
        line2: 21,
        line3: 25,
      },
      {
        name: 1976,
        line1: 30,
        line2: 21,
        line3: 25,
      },
      {
        name: 1977,
        line1: 29,
        line2: 22,
        line3: 24,
      },
      {
        name: 1978,
        line1: 26,
        line2: 24,
        line3: 23,
      },
      {
        name: 1979,
        line1: 25,
        line2: 24,
        line3: 24,
      },
      {
        name: 1980,
        line1: 27,
        line2: 24,
        line3: 23,
      },
      {
        name: 1981,
        line1: 27,
        line2: 23,
        line3: 21,
      },
      {
        name: 1982,
        line1: 27,
        line2: 23,
        line3: 21,
      },
      {
        name: 1983,
        line1: 28,
        line2: 22,
        line3: 20,
      },
      {
        name: 1984,
        line1: 26,
        line2: 23,
        line3: 22,
      },
      {
        name: 1985,
        line1: 27,
        line2: 24,
        line3: 22,
      },
      {
        name: 1986,
        line1: 26,
        line2: 24,
        line3: 22,
      },
      {
        name: 1987,
        line1: 27,
        line2: 23,
        line3: 22,
      },
      {
        name: 1988,
        line1: 28,
        line2: 22,
        line3: 24,
      },
      {
        name: 1989,
        line1: 31,
        line2: 22,
        line3: 25,
      },
      {
        name: 1990,
        line1: 30,
        line2: 21,
        line3: 24,
      },
      {
        name: 1991,
        line1: 26,
        line2: 20,
        line3: 21,
      },
      {
        name: 1992,
        line1: 22,
        line2: 20,
        line3: 19,
      },
      {
        name: 1993,
        line1: 19,
        line2: 20,
        line3: 18,
      },
      {
        name: 1994,
        line1: 19,
        line2: 20,
        line3: 19,
      },
      {
        name: 1995,
        line1: 19,
        line2: 21,
        line3: 18,
      },
      {
        name: 1996,
        line1: 21,
        line2: 21,
        line3: 18,
      },
      {
        name: 1997,
        line1: 21,
        line2: 22,
        line3: 17,
      },
      {
        name: 1998,
        line1: 22,
        line2: 22,
        line3: 17,
      },
      {
        name: 1999,
        line1: 22,
        line2: 23,
        line3: 17,
      },
      {
        name: 2000,
        line1: 23,
        line2: 23,
        line3: 18,
      },
      {
        name: 2001,
        line1: 23,
        line2: 23,
        line3: 18,
      },
      {
        name: 2002,
        line1: 22,
        line2: 22,
        line3: 18,
      },
      {
        name: 2003,
        line1: 22,
        line2: 22,
        line3: 17,
      },
      {
        name: 2004,
        line1: 22,
        line2: 22,
        line3: 17,
      },
      {
        name: 2005,
        line1: 23,
        line2: 23,
        line3: 17,
      },
      {
        name: 2006,
        line1: 23,
        line2: 23,
        line3: 18,
      },
      {
        name: 2007,
        line1: 24,
        line2: 22,
        line3: 18,
      },
      {
        name: 2008,
        line1: 24,
        line2: 21,
        line3: 17,
      },
      {
        name: 2009,
        line1: 23,
        line2: 19,
        line3: 16,
      },
      {
        name: 2010,
        line1: 22,
        line2: 18,
        line3: 16,
      },
      {
        name: 2011,
        line1: 23,
        line2: 19,
        line3: 16,
      },
      {
        name: 2012,
        line1: 23,
        line2: 20,
        line3: 16,
      },
      {
        name: 2013,
        line1: 22,
        line2: 20,
        line3: 16,
      },
      {
        name: 2014,
        line1: 21,
        line2: 20,
        line3: 16,
      },
      {
        name: 2015,
        line1: 21,
        line2: 20,
        line3: 17,
      },
      {
        name: 2016,
        line1: 23,
        line2: 20,
        line3: 18,
      },
      {
        name: 2017,
        line1: 23,
        line2: 21,
        line3: 18,
      },
      {
        name: 2018,
        line1: 24,
        line2: 21,
        line3: 18,
      },
      {
        name: 2019,
        line1: 24,
        line2: 21,
        line3: 18,
      },
      {
        name: 2020,
        line1: 24,
        line2: 21,
        line3: 17,
      },
      {
        name: 2021,
        line1: 24,

        line3: 17,
      },
      {
        name: 2022,
      },
      {
        name: 2023,
      },
      {
        name: 2024,
      },
      {
        name: 2025,
      },
      {
        name: 2026,
      },
      {
        name: 2027,
      },
      {
        name: 2028,
      },
      {
        name: 2029,
      },
      {
        name: 2030,
      },
    ],
  },
  {
    name: "kuvio-2.9",
    width: "100%",
    yDomain: ["dataMin", "dataMax+1"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Vuosi",
    yAxisLabel: "Liikevaihto milj. euroa",
    type: ["number", "number"],
    yAxisOffset: 100,
    xAxisOffset: -15,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    yAxisWidth: 60,
    margins: [0, 30, 15, 5],
    tickDx: 0,
    dot: true,
    fill: "#8884d8",
    manualTicksX: [2014, 2015, 2016, 2017, 2018],
    manualTicksY: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000],
    names: ["Mehiläinen", "Terveystalo", "Pihlajalinna", "Attendo", "Esperi"],
    data: [
      {
        name: 2014,
        line1: 269,
        line2: 474,
        line3: 149,
        line4: 158,
        line5: 121,
        "": "",
      },
      {
        name: 2015,
        line1: 445,
        line2: 506,
        line3: 213,
        line4: 193,
        line5: 126,
        "": "",
      },
      {
        name: 2016,
        line1: 590,
        line2: 547,
        line3: 399,
        line4: 213,
        line5: 171,
        "": "",
      },
      {
        name: 2017,
        line1: 756,
        line2: 690,
        line3: 424,
        line4: 352,
        line5: 213,
        "": "",
      },
      {
        name: 2018,
        line1: 916,
        line2: 745,
        line3: 488,
        line4: 450,
        line5: 265,
        "": "",
      },
    ],
  },
  {
    name: "kuvio-6.7",
    width: "100%",
    yDomain: ["dataMin", "dataMax+1"],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    type: ["category", "number"],
    yAxisOffset: 0,
    xAxisOffset: -10,
    minTickGapX: 15,
    xTickCount: 15,
    yTickCount: 15,
    yAxisWidth: 60,
    margins: [15, 25, 0, 5],
    yInterval: 0,
    tickDx: 0,
    fontSize: "0.8rem",
    xTickFontSize: "1rem",
    manualTicksY: [-1.5, 0, 1.5, 3, 4.5, 6, 7.5, 9, 10.5],
    manualTicksX: [
      2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018, 2020, 2022,
    ],
    chooseReference: 3,
    chooseReference2: 4,
    chooseDashReference: 4,
    names: [
      "EKP:n ohjauskorko",
      "Inflaatio euroalueella",
      "EKP:n inflaatiotavoite",
      "Keskiarvoinflaatio 2013-2020",
    ],
    data: [
      {
        name: 2002,
        line1: 3.25,
        line2: 2.6,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2.5,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2.5,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2002,
        line1: 3.25,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2002,
        line1: 2.75,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2.75,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2.75,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2.5,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2.5,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2.5,
        line2: 1.8,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2,
        line2: 2,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2,
        line2: 2,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2,
        line2: 2,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2003,
        line1: 2,
        line2: 2,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 1.8,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 1.7,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2004,
        line1: 2,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2.6,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2.5,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2005,
        line1: 2.25,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2006,
        line1: 2.25,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2006,
        line1: 2.25,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2006,
        line1: 2.5,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2006,
        line1: 2.5,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2006,
        line1: 2.5,
        line2: 2.5,
        line3: 2,
      },
      {
        name: 2006,
        line1: 2.75,
        line2: 2.5,
        line3: 2,
      },
      {
        name: 2006,
        line1: 2.75,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2006,
        line1: 3,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2006,
        line1: 3,
        line2: 1.8,
        line3: 2,
      },
      {
        name: 2006,
        line1: 3.25,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2006,
        line1: 3.25,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2006,
        line1: 3.5,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2007,
        line1: 3.5,
        line2: 1.8,
        line3: 2,
      },
      {
        name: 2007,
        line1: 3.5,
        line2: 1.8,
        line3: 2,
      },
      {
        name: 2007,
        line1: 3.75,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2007,
        line1: 3.75,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2007,
        line1: 3.75,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2007,
        line1: 4,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2007,
        line1: 4,
        line2: 1.8,
        line3: 2,
      },
      {
        name: 2007,
        line1: 4,
        line2: 1.7,
        line3: 2,
      },
      {
        name: 2007,
        line1: 4,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2007,
        line1: 4,
        line2: 2.5,
        line3: 2,
      },
      {
        name: 2007,
        line1: 4,
        line2: 3.1,
        line3: 2,
      },
      {
        name: 2007,
        line1: 4,
        line2: 3.1,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4,
        line2: 3.2,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4,
        line2: 3.3,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4,
        line2: 3.6,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4,
        line2: 3.3,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4,
        line2: 3.7,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4,
        line2: 4,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4.25,
        line2: 4.1,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4.25,
        line2: 3.8,
        line3: 2,
      },
      {
        name: 2008,
        line1: 4.25,
        line2: 3.6,
        line3: 2,
      },
      {
        name: 2008,
        line1: 3.75,
        line2: 3.2,
        line3: 2,
      },
      {
        name: 2008,
        line1: 3.25,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2008,
        line1: 2.5,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2009,
        line1: 2,
        line2: 1.1,
        line3: 2,
      },
      {
        name: 2009,
        line1: 2,
        line2: 1.2,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1.5,
        line2: 0.6,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1.25,
        line2: 0.6,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1,
        line2: 0,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1,
        line2: -0.1,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1,
        line2: -0.6,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1,
        line2: -0.2,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1,
        line2: -0.3,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1,
        line2: -0.1,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1,
        line2: 0.5,
        line3: 2,
      },
      {
        name: 2009,
        line1: 1,
        line2: 0.9,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 0.9,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 0.8,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.7,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.5,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.7,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2010,
        line1: 1,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1,
        line2: 2.7,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1.25,
        line2: 2.8,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1.25,
        line2: 2.7,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1.25,
        line2: 2.7,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1.5,
        line2: 2.6,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1.5,
        line2: 2.5,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1.5,
        line2: 3,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1.5,
        line2: 3,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1.25,
        line2: 3,
        line3: 2,
      },
      {
        name: 2011,
        line1: 1,
        line2: 2.8,
        line3: 2,
      },
      {
        name: 2012,
        line1: 1,
        line2: 2.7,
        line3: 2,
      },
      {
        name: 2012,
        line1: 1,
        line2: 2.7,
        line3: 2,
      },
      {
        name: 2012,
        line1: 1,
        line2: 2.7,
        line3: 2,
      },
      {
        name: 2012,
        line1: 1,
        line2: 2.6,
        line3: 2,
      },
      {
        name: 2012,
        line1: 1,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2012,
        line1: 1,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2012,
        line1: 0.75,
        line2: 2.4,
        line3: 2,
      },
      {
        name: 2012,
        line1: 0.75,
        line2: 2.6,
        line3: 2,
      },
      {
        name: 2012,
        line1: 0.75,
        line2: 2.6,
        line3: 2,
      },
      {
        name: 2012,
        line1: 0.75,
        line2: 2.5,
        line3: 2,
      },
      {
        name: 2012,
        line1: 0.75,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2012,
        line1: 0.75,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.75,
        line2: 2,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.75,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.75,
        line2: 1.7,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.75,
        line2: 1.2,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.5,
        line2: 1.4,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.5,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.5,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.5,
        line2: 1.3,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.5,
        line2: 1.1,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.5,
        line2: 0.7,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.25,
        line2: 0.9,
        line3: 2,
      },
      {
        name: 2013,
        line1: 0.25,
        line2: 0.8,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.25,
        line2: 0.8,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.25,
        line2: 0.7,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.25,
        line2: 0.5,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.25,
        line2: 0.7,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.25,
        line2: 0.5,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.15,
        line2: 0.5,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.15,
        line2: 0.4,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.15,
        line2: 0.4,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.05,
        line2: 0.3,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.05,
        line2: 0.4,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.05,
        line2: 0.3,
        line3: 2,
      },
      {
        name: 2014,
        line1: 0.05,
        line2: -0.2,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: -0.6,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: -0.3,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: -0.1,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.2,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.6,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.5,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.5,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.4,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.2,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.4,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.1,
        line3: 2,
      },
      {
        name: 2015,
        line1: 0.05,
        line2: 0.2,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0.05,
        line2: 0.3,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0.05,
        line2: -0.1,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: 0,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: -0.3,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: -0.1,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: 0,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: 0.2,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: 0.2,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: 0.4,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: 0.5,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: 0.6,
        line3: 2,
      },
      {
        name: 2016,
        line1: 0,
        line2: 1.1,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.7,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 2,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.5,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.4,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.3,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.3,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.5,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.4,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.5,
        line3: 2,
      },
      {
        name: 2017,
        line1: 0,
        line2: 1.3,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 1.3,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 1.1,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 1.4,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 1.2,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 2,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 2,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 2.1,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 2.3,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2018,
        line1: 0,
        line2: 1.5,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1.4,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1.5,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1.4,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1.7,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1.2,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1.3,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 0.8,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 0.7,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1,
        line3: 2,
      },
      {
        name: 2019,
        line1: 0,
        line2: 1.3,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: 1.4,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: 1.2,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: 0.7,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: 0.3,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: 0.1,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: 0.3,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: 0.4,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: -0.2,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: -0.3,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: -0.3,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: -0.3,
        line3: 2,
      },
      {
        name: 2020,
        line1: 0,
        line2: -0.3,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 0.9,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 0.9,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 1.3,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 1.6,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 2,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 1.9,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 2.2,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 3,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 3.4,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 4.1,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 4.9,
        line3: 2,
      },
      {
        name: 2021,
        line1: 0,
        line2: 5,
        line3: 2,
      },
      {
        name: 2022,
        line1: 0,
        line2: 5.1,
        line3: 2,
      },
      {
        name: 2022,
        line1: 0,
        line2: 5.9,
        line3: 2,
      },
      {
        name: 2022,
        line1: 0,
        line2: 7.4,
        line3: 2,
      },
      {
        name: 2022,
        line1: 0,
        line2: 7.4,
        line3: 2,
      },
      {
        name: 2022,
        line1: 0,
        line2: 8.1,
        line3: 2,
      },
      {
        name: 2022,
        line1: 0,
        line2: 8.6,
        line3: 2,
      },
      {
        name: 2022,
        line1: 0.5,
        line2: 8.9,
        line3: 2,
      },
      {
        name: 2022,
        line1: 0.5,
        line2: 9.1,
        line3: 2,
      },
    ],
  },
  {
    name: "kuvio-7.3",
    height: 450,
    width: "100%",
    connectNulls: true,
    xAxisLabel: "Vuosi",
    yAxisLabel: "",
    yAxisLabel2: "",
    yAxisId2: [2, 3],
    whichYId: [0, 1, 1],
    yDomain: [0, 300],
    yDomain2: [0, 100],
    manualTicksY2: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    manualTicksX: [1860, 1880, 1900, 1920, 1940, 1960, 1980, 2000, 2020],
    rightYAxis: true,
    minTickGapX: 9,
    xTickCount: 15,
    yTickCount: 15,
    yAxisWidth: 70,
    xAxisHeight: 50,
    margins: [15, 20, 0, 5],
    tickDx: 0,
    fontSize: "0.8rem",
    xTickFontSize: "1rem",
    yAxisOffset: 50,
    yAxisOffset2: -50,
    xAxisOffset: 0,
    names: [
      "Bkt (vasen akseli, miljardia euroa vuoden 2021 hinnoin)",
      "Hiilidioksidipäästöt (oikea akseli, miljoonaa tonnia)",
      "Rikkidioksidipäästöt (oikea akseli, 10 000 tonnia)",
    ],
    data: [
      {
        name: 1860,
        line1: 3,
        line2: 0.03,
        line3: 0.31,
      },
      {
        name: 1861,
        line1: 3,
        line2: 0.06,
      },
      {
        name: 1862,
        line1: 3,
        line2: 0.06,
      },
      {
        name: 1863,
        line1: 3,
        line2: 0.07,
      },
      {
        name: 1864,
        line1: 3,
        line2: 0.04,
      },
      {
        name: 1865,
        line1: 3,
        line2: 0.05,
      },
      {
        name: 1866,
        line1: 3,
        line2: 0.06,
      },
      {
        name: 1867,
        line1: 3,
        line2: 0.06,
      },
      {
        name: 1868,
        line1: 3,
        line2: 0.08,
      },
      {
        name: 1869,
        line1: 3,
        line2: 0.06,
      },
      {
        name: 1870,
        line1: 4,
        line2: 0.08,
        line3: 0.4,
      },
      {
        name: 1871,
        line1: 4,
        line2: 0.1,
      },
      {
        name: 1872,
        line1: 4,
        line2: 0.08,
      },
      {
        name: 1873,
        line1: 4,
        line2: 0.08,
      },
      {
        name: 1874,
        line1: 4,
        line2: 0.12,
      },
      {
        name: 1875,
        line1: 4,
        line2: 0.08,
      },
      {
        name: 1876,
        line1: 4,
        line2: 0.09,
      },
      {
        name: 1877,
        line1: 4,
        line2: 0.11,
      },
      {
        name: 1878,
        line1: 4,
        line2: 0.12,
      },
      {
        name: 1879,
        line1: 4,
        line2: 0.07,
      },
      {
        name: 1880,
        line1: 4,
        line2: 0.09,
        line3: 0.44,
      },
      {
        name: 1881,
        line1: 4,
        line2: 0.09,
      },
      {
        name: 1882,
        line1: 4,
        line2: 0.11,
      },
      {
        name: 1883,
        line1: 5,
        line2: 0.11,
      },
      {
        name: 1884,
        line1: 5,
        line2: 0.13,
      },
      {
        name: 1885,
        line1: 5,
        line2: 0.2,
      },
      {
        name: 1886,
        line1: 5,
        line2: 0.12,
      },
      {
        name: 1887,
        line1: 5,
        line2: 0.12,
      },
      {
        name: 1888,
        line1: 5,
        line2: 0.11,
      },
      {
        name: 1889,
        line1: 5,
        line2: 0.15,
      },
      {
        name: 1890,
        line1: 6,
        line2: 0.2,
        line3: 1.29,
      },
      {
        name: 1891,
        line1: 6,
        line2: 0.2,
      },
      {
        name: 1892,
        line1: 6,
        line2: 0.2,
      },
      {
        name: 1893,
        line1: 6,
        line2: 0.2,
      },
      {
        name: 1894,
        line1: 6,
        line2: 0.2,
      },
      {
        name: 1895,
        line1: 7,
        line2: 0.2,
      },
      {
        name: 1896,
        line1: 7,
        line2: 0.3,
      },
      {
        name: 1897,
        line1: 7,
        line2: 0.5,
      },
      {
        name: 1898,
        line1: 8,
        line2: 0.5,
      },
      {
        name: 1899,
        line1: 7,
        line2: 0.7,
      },
      {
        name: 1900,
        line1: 8,
        line2: 0.6,
        line3: 2.62,
      },
      {
        name: 1901,
        line1: 8,
        line2: 0.4,
      },
      {
        name: 1902,
        line1: 8,
        line2: 0.4,
      },
      {
        name: 1903,
        line1: 8,
        line2: 0.5,
      },
      {
        name: 1904,
        line1: 8,
        line2: 0.5,
      },
      {
        name: 1905,
        line1: 9,
        line2: 0.6,
      },
      {
        name: 1906,
        line1: 9,
        line2: 0.6,
      },
      {
        name: 1907,
        line1: 9,
        line2: 0.8,
      },
      {
        name: 1908,
        line1: 9,
        line2: 1.3,
      },
      {
        name: 1909,
        line1: 10,
        line2: 1.2,
      },
      {
        name: 1910,
        line1: 10,
        line2: 1,
        line3: 5.42,
      },
      {
        name: 1911,
        line1: 10,
        line2: 1.2,
      },
      {
        name: 1912,
        line1: 11,
        line2: 1.4,
      },
      {
        name: 1913,
        line1: 11,
        line2: 1.6,
      },
      {
        name: 1914,
        line1: 11,
        line2: 0.6,
      },
      {
        name: 1915,
        line1: 10,
        line2: 0.1,
      },
      {
        name: 1916,
        line1: 10,
        line2: 0.2,
      },
      {
        name: 1917,
        line1: 9,
        line2: 0.1,
      },
      {
        name: 1918,
        line1: 8,
        line2: 0.2,
      },
      {
        name: 1919,
        line1: 9,
        line2: 0.2,
      },
      {
        name: 1920,
        line1: 10,
        line2: 0.4,
        line3: 2.71,
      },
      {
        name: 1921,
        line1: 11,
        line2: 0.5,
      },
      {
        name: 1922,
        line1: 12,
        line2: 0.7,
      },
      {
        name: 1923,
        line1: 13,
        line2: 0.8,
      },
      {
        name: 1924,
        line1: 13,
        line2: 1.1,
      },
      {
        name: 1925,
        line1: 14,
        line2: 1.3,
      },
      {
        name: 1926,
        line1: 14,
        line2: 1.4,
      },
      {
        name: 1927,
        line1: 15,
        line2: 1.7,
      },
      {
        name: 1928,
        line1: 16,
        line2: 2,
      },
      {
        name: 1929,
        line1: 16,
        line2: 2.3,
      },
      {
        name: 1930,
        line1: 16,
        line2: 2.9,
        line3: 10.66,
      },
      {
        name: 1931,
        line1: 16,
        line2: 3,
      },
      {
        name: 1932,
        line1: 16,
        line2: 3.2,
      },
      {
        name: 1933,
        line1: 17,
        line2: 3.5,
      },
      {
        name: 1934,
        line1: 19,
        line2: 4,
      },
      {
        name: 1935,
        line1: 20,
        line2: 4.1,
      },
      {
        name: 1936,
        line1: 21,
        line2: 4.5,
      },
      {
        name: 1937,
        line1: 22,
        line2: 5,
      },
      {
        name: 1938,
        line1: 23,
        line2: 5.4,
      },
      {
        name: 1939,
        line1: 22,
        line2: 5.2,
      },
      {
        name: 1940,
        line1: 21,
        line2: 3.3,
        line3: 10.8,
      },
      {
        name: 1941,
        line1: 22,
        line2: 1.4,
      },
      {
        name: 1942,
        line1: 22,
        line2: 1.2,
      },
      {
        name: 1943,
        line1: 24,
        line2: 1.3,
      },
      {
        name: 1944,
        line1: 24,
        line2: 2.5,
      },
      {
        name: 1945,
        line1: 23,
        line2: 1.2,
      },
      {
        name: 1946,
        line1: 25,
        line2: 3.7,
      },
      {
        name: 1947,
        line1: 25,
        line2: 5.3,
      },
      {
        name: 1948,
        line1: 27,
        line2: 6.3,
      },
      {
        name: 1949,
        line1: 29,
        line2: 6.6,
      },
      {
        name: 1950,
        line1: 30,
        line2: 7.1,
        line3: 31.35,
      },
      {
        name: 1951,
        line1: 33,
        line2: 7.6,
      },
      {
        name: 1952,
        line1: 34,
        line2: 7.6,
      },
      {
        name: 1953,
        line1: 34,
        line2: 8.1,
      },
      {
        name: 1954,
        line1: 37,
        line2: 8.5,
      },
      {
        name: 1955,
        line1: 39,
        line2: 10.3,
      },
      {
        name: 1956,
        line1: 40,
        line2: 11.8,
      },
      {
        name: 1957,
        line1: 42,
        line2: 12.1,
      },
      {
        name: 1958,
        line1: 42,
        line2: 11.8,
      },
      {
        name: 1959,
        line1: 45,
        line2: 12.9,
      },
      {
        name: 1960,
        line1: 49,
        line2: 15.5,
        line3: 39.07,
      },
      {
        name: 1961,
        line1: 53,
        line2: 15.4,
      },
      {
        name: 1962,
        line1: 54,
        line2: 16.9,
      },
      {
        name: 1963,
        line1: 56,
        line2: 19.4,
      },
      {
        name: 1964,
        line1: 59,
        line2: 22.6,
      },
      {
        name: 1965,
        line1: 62,
        line2: 25.4,
      },
      {
        name: 1966,
        line1: 63,
        line2: 28.5,
      },
      {
        name: 1967,
        line1: 65,
        line2: 28.9,
      },
      {
        name: 1968,
        line1: 66,
        line2: 32.4,
      },
      {
        name: 1969,
        line1: 73,
        line2: 38.4,
      },
      {
        name: 1970,
        line1: 78,
        line2: 39.9,
        line3: 59.16,
      },
      {
        name: 1971,
        line1: 80,
        line2: 39.8,
      },
      {
        name: 1972,
        line1: 86,
        line2: 43.6,
      },
      {
        name: 1973,
        line1: 91,
        line2: 48.3,
      },
      {
        name: 1974,
        line1: 94,
        line2: 44.1,
      },
      {
        name: 1975,
        line1: 95,
        line2: 44.1,
      },
      {
        name: 1976,
        line1: 96,
        line2: 50.4,
      },
      {
        name: 1977,
        line1: 96,
        line2: 49.8,
      },
      {
        name: 1978,
        line1: 99,
        line2: 54,
      },
      {
        name: 1979,
        line1: 106,
        line2: 53.5,
      },
      {
        name: 1980,
        line1: 112,
        line2: 54.1,
        line3: 58.3,
      },
      {
        name: 1981,
        line1: 114,
        line2: 44.9,
        line3: 53.37,
      },
      {
        name: 1982,
        line1: 117,
        line2: 43.4,
        line3: 48.25,
      },
      {
        name: 1983,
        line1: 121,
        line2: 43.1,
        line3: 37.11,
      },
      {
        name: 1984,
        line1: 125,
        line2: 44.4,
        line3: 36.7,
      },
      {
        name: 1985,
        line1: 129,
        line2: 50.3,
        line3: 38.19,
      },
      {
        name: 1986,
        line1: 133,
        line2: 48.9,
        line3: 33.05,
      },
      {
        name: 1987,
        line1: 137,
        line2: 52.4,
        line3: 32.74,
      },
      {
        name: 1988,
        line1: 144,
        line2: 52.2,
        line3: 30.17,
      },
      {
        name: 1989,
        line1: 152,
        line2: 52,
        line3: 24.4,
      },
      {
        name: 1990,
        line1: 153,
        line2: 52.5,
        line3: 25.03,
      },
      {
        name: 1991,
        line1: 144,
        line2: 51.2,
        line3: 20.22,
      },
      {
        name: 1992,
        line1: 139,
        line2: 50.5,
        line3: 15.78,
      },
      {
        name: 1993,
        line1: 138,
        line2: 52.4,
        line3: 13.81,
      },
      {
        name: 1994,
        line1: 144,
        line2: 57.7,
        line3: 12.37,
      },
      {
        name: 1995,
        line1: 150,
        line2: 54.3,
        line3: 10.5,
      },
      {
        name: 1996,
        line1: 155,
        line2: 60,
        line3: 11.04,
      },
      {
        name: 1997,
        line1: 165,
        line2: 58.4,
        line3: 10.16,
      },
      {
        name: 1998,
        line1: 174,
        line2: 55.1,
        line3: 9.37,
      },
      {
        name: 1999,
        line1: 182,
        line2: 54.5,
        line3: 9.14,
      },
      {
        name: 2000,
        line1: 192,
        line2: 52.8,
        line3: 8.1,
      },
      {
        name: 2001,
        line1: 197,
        line2: 58.1,
        line3: 9.04,
      },
      {
        name: 2002,
        line1: 201,
        line2: 60.7,
        line3: 9.12,
      },
      {
        name: 2003,
        line1: 205,
        line2: 68.3,
        line3: 10.25,
      },
      {
        name: 2004,
        line1: 213,
        line2: 64.4,
        line3: 8.46,
      },
      {
        name: 2005,
        line1: 219,
        line2: 52.7,
        line3: 6.92,
      },
      {
        name: 2006,
        line1: 227,
        line2: 63.8,
        line3: 8.41,
      },
      {
        name: 2007,
        line1: 239,
        line2: 61.8,
        line3: 8.29,
      },
      {
        name: 2008,
        line1: 241,
        line2: 53.5,
        line3: 6.77,
      },
      {
        name: 2009,
        line1: 222,
        line2: 51.7,
        line3: 5.96,
      },
      {
        name: 2010,
        line1: 229,
        line2: 59.1,
        line3: 6.71,
      },
      {
        name: 2011,
        line1: 235,
        line2: 51.7,
        line3: 6.04,
      },
      {
        name: 2012,
        line1: 232,
        line2: 46.6,
        line3: 5.11,
      },
      {
        name: 2013,
        line1: 229,
        line2: 47.2,
        line3: 4.85,
      },
      {
        name: 2014,
        line1: 229,
        line2: 43.4,
        line3: 4.35,
      },
      {
        name: 2015,
        line1: 230,
        line2: 39.7,
        line3: 4.22,
      },
      {
        name: 2016,
        line1: 236,
        line2: 42.4,
        line3: 4,
      },
      {
        name: 2017,
        line1: 244,
        line2: 40,
        line3: 3.56,
      },
      {
        name: 2018,
        line1: 247,
        line2: 41.1,
        line3: 3.31,
      },
      {
        name: 2019,
        line1: 250,
        line2: 38.1,
        line3: 2.97,
      },
      {
        name: 2020,
        line1: 244,
      },
    ],
  },
  {
    name: "kuvio-3.17",
    height: 450,
    width: "100%",
    connectNulls: true,
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    yDomain: [0, 40],
    manualTicksX: [
      2000, 2002, 2004, 2006, 2008, 2010, 2012, 2014, 2016, 2018, 2020,
    ],
    yAxisOffset: 0,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 45,
    yAxisWidth: 55,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    fontSize: "0.8rem",
    names: [
      "Kotitalouksien tulovero",
      "Yhteisöjen tulovero",
      "Pakolliset sosiaaliturvamaksut",
      "Omaisuusverot",
      "Tavaroista ja palveluista maksetut verot",
      "Muut verot",
    ],
    data: [
      {
        name: 2000,
        line1: 30.5,
        line2: 12.3,
        line3: 25.3,
        line4: 2.4,
        line5: 29,
        line6: 0,
      },
      {
        name: 2001,
        line1: 31.2,
        line2: 9.2,
        line3: 27,
        line4: 2.3,
        line5: 29.7,
        line6: 0.1,
      },
      {
        name: 2002,
        line1: 30.8,
        line2: 9.2,
        line3: 26.6,
        line4: 2.4,
        line5: 30.3,
        line6: 0.1,
      },
      {
        name: 2003,
        line1: 30.7,
        line2: 7.6,
        line3: 26.7,
        line4: 2.4,
        line5: 32.1,
        line6: 0.1,
      },
      {
        name: 2004,
        line1: 30.2,
        line2: 8,
        line3: 26.9,
        line4: 2.6,
        line5: 31.9,
        line6: 0.1,
      },
      {
        name: 2005,
        line1: 30.5,
        line2: 7.5,
        line3: 27.3,
        line4: 2.7,
        line5: 31.5,
        line6: 0.1,
      },
      {
        name: 2006,
        line1: 30.1,
        line2: 7.6,
        line3: 27.9,
        line4: 2.5,
        line5: 31.4,
        line6: 0.1,
      },
      {
        name: 2007,
        line1: 30,
        line2: 8.9,
        line3: 27.7,
        line4: 2.6,
        line5: 30.3,
        line6: 0.1,
      },
      {
        name: 2008,
        line1: 30.3,
        line2: 8,
        line3: 28.1,
        line4: 2.6,
        line5: 30.3,
        line6: 0.1,
      },
      {
        name: 2009,
        line1: 30.5,
        line2: 4.6,
        line3: 29.8,
        line4: 2.6,
        line5: 31.7,
        line6: 0.1,
      },
      {
        name: 2010,
        line1: 29.3,
        line2: 5.8,
        line3: 29.7,
        line4: 2.7,
        line5: 31.8,
        line6: 0.1,
      },
      {
        name: 2011,
        line1: 29,
        line2: 6.1,
        line3: 28.8,
        line4: 2.6,
        line5: 33,
        line6: 0.1,
      },
      {
        name: 2012,
        line1: 29.1,
        line2: 4.8,
        line3: 29.7,
        line4: 2.8,
        line5: 33.1,
        line6: 0.1,
      },
      {
        name: 2013,
        line1: 29.1,
        line2: 5.3,
        line3: 29,
        line4: 2.9,
        line5: 33.2,
        line6: 0.1,
      },
      {
        name: 2014,
        line1: 30.5,
        line2: 4.3,
        line3: 28.9,
        line4: 3,
        line5: 32.9,
        line6: 0.1,
      },
      {
        name: 2015,
        line1: 30.2,
        line2: 4.8,
        line3: 29,
        line4: 3.3,
        line5: 32.3,
        line6: 0.1,
      },
      {
        name: 2016,
        line1: 29.6,
        line2: 5.1,
        line3: 29.1,
        line4: 3.2,
        line5: 32.8,
        line6: 0.1,
      },
      {
        name: 2017,
        line1: 29.2,
        line2: 6.3,
        line3: 28,
        line4: 3.6,
        line5: 32.8,
        line6: 0.1,
      },
      {
        name: 2018,
        line1: 28.9,
        line2: 6,
        line3: 28,
        line4: 3.4,
        line5: 33.6,
        line6: 0,
      },
      {
        name: 2019,
        line1: 29.1,
        line2: 6,
        line3: 28,
        line4: 3.4,
        line5: 33.4,
        line6: 0.1,
      },
      {
        name: 2020,
        line1: 30.1,
        line2: 5.1,
        line3: 27.5,
        line4: 3.6,
        line5: 33.6,
        line6: 0.1,
      },
      {
        name: 2021,
        line1: 29.9,
        line2: 6.2,
        line3: 28.1,
        line4: 3.5,
        line5: 32.2,
        line6: 0.1,
      },
    ],
  },
  {
    name: "kuvio-6.1",
    height: 450,
    width: "100%",
    xAxisLabel: "Vuosineljännes",
    yAxisLabel: "%",
    yDomain: [-10, 14],
    xDomain: ["dataMin", "dataMax+2"],
    type: ["category", "number"],
    manualTicksX: [
      "1990Q1",
      "1991Q4",
      "1992Q4",
      "1993Q4",
      "1994Q4",
      "1995Q4",
      "1996Q4",
      "1997Q4",
      "1998Q4",
      "1999Q4",
      "2000Q4",
      "2001Q4",
      "2002Q4",
      "2003Q4",
      "2004Q4",
      "2005Q4",
      "2006Q4",
      "2007Q4",
      "2008Q4",
      "2009Q4",
      "2010Q4",
      "2011Q4",
      "2012Q4",
      "2013Q4",
      "2014Q4",
      "2015Q4",
      "2016Q4",
      "2017Q4",
      "2018Q4",
      "2019Q4",
      "2020Q4",
      "2021Q4",
    ],
    yAxisOffset: 0,
    xAxisOffset: -65,
    xAxisPadding: 70,
    minTickGapX: 5,
    xTickCount: 30,
    yTickCount: 30,
    interval: 0,
    yAxisWidth: 80,
    margins: [25, 35, 0, 5],
    tickDx: 0,
    tickDy: 35,
    xLabelPosition: "insideBottom",
    xAxisLabelSideways: 90,
    fontSize: "0.8rem",
    xTickFontSize: "1rem",
    names: ["Työpäiväkorjatun sarjan volyymin muutos vuodentakaisesta, %"],
    data: [
      {
        name: "1991Q1",
        line1: -5.9,
      },
      {
        name: "1991Q2",
        line1: -5.8,
      },
      {
        name: "1991Q3",
        line1: -5.5,
      },
      {
        name: "1991Q4",
        line1: -6.3,
      },
      {
        name: "1992Q1",
        line1: -4,
      },
      {
        name: "1992Q2",
        line1: -3.9,
      },
      {
        name: "1992Q3",
        line1: -2.8,
      },
      {
        name: "1992Q4",
        line1: -2.6,
      },
      {
        name: "1993Q1",
        line1: -2.4,
      },
      {
        name: "1993Q2",
        line1: -1.6,
      },
      {
        name: "1993Q3",
        line1: -0.3,
      },
      {
        name: "1993Q4",
        line1: 1.5,
      },
      {
        name: "1994Q1",
        line1: 1.7,
      },
      {
        name: "1994Q2",
        line1: 3,
      },
      {
        name: "1994Q3",
        line1: 4.4,
      },
      {
        name: "1994Q4",
        line1: 6.5,
      },
      {
        name: "1995Q1",
        line1: 4.9,
      },
      {
        name: "1995Q2",
        line1: 6.9,
      },
      {
        name: "1995Q3",
        line1: 3.9,
      },
      {
        name: "1995Q4",
        line1: 1.5,
      },
      {
        name: "1996Q1",
        line1: 4.3,
      },
      {
        name: "1996Q2",
        line1: 2,
      },
      {
        name: "1996Q3",
        line1: 2.6,
      },
      {
        name: "1996Q4",
        line1: 5.8,
      },
      {
        name: "1997Q1",
        line1: 4.1,
      },
      {
        name: "1997Q2",
        line1: 4.9,
      },
      {
        name: "1997Q3",
        line1: 7,
      },
      {
        name: "1997Q4",
        line1: 8.8,
      },
      {
        name: "1998Q1",
        line1: 6.4,
      },
      {
        name: "1998Q2",
        line1: 6.9,
      },
      {
        name: "1998Q3",
        line1: 5.5,
      },
      {
        name: "1998Q4",
        line1: 3.4,
      },
      {
        name: "1999Q1",
        line1: 6.6,
      },
      {
        name: "1999Q2",
        line1: 4.6,
      },
      {
        name: "1999Q3",
        line1: 3.6,
      },
      {
        name: "1999Q4",
        line1: 3,
      },
      {
        name: "2000Q1",
        line1: 5,
      },
      {
        name: "2000Q2",
        line1: 5.3,
      },
      {
        name: "2000Q3",
        line1: 6.2,
      },
      {
        name: "2000Q4",
        line1: 6.3,
      },
      {
        name: "2001Q1",
        line1: 4,
      },
      {
        name: "2001Q2",
        line1: 3.4,
      },
      {
        name: "2001Q3",
        line1: 2.4,
      },
      {
        name: "2001Q4",
        line1: 0.8,
      },
      {
        name: "2002Q1",
        line1: 1.4,
      },
      {
        name: "2002Q2",
        line1: 2.3,
      },
      {
        name: "2002Q3",
        line1: 0.9,
      },
      {
        name: "2002Q4",
        line1: 2.2,
      },
      {
        name: "2003Q1",
        line1: 0,
      },
      {
        name: "2003Q2",
        line1: 0.8,
      },
      {
        name: "2003Q3",
        line1: 3,
      },
      {
        name: "2003Q4",
        line1: 4,
      },
      {
        name: "2004Q1",
        line1: 4.3,
      },
      {
        name: "2004Q2",
        line1: 4.3,
      },
      {
        name: "2004Q3",
        line1: 3.7,
      },
      {
        name: "2004Q4",
        line1: 3.7,
      },
      {
        name: "2005Q1",
        line1: 6.4,
      },
      {
        name: "2005Q2",
        line1: 2.5,
      },
      {
        name: "2005Q3",
        line1: 2,
      },
      {
        name: "2005Q4",
        line1: 0.6,
      },
      {
        name: "2006Q1",
        line1: 2.7,
      },
      {
        name: "2006Q2",
        line1: 4.6,
      },
      {
        name: "2006Q3",
        line1: 4.1,
      },
      {
        name: "2006Q4",
        line1: 4.6,
      },
      {
        name: "2007Q1",
        line1: 4.2,
      },
      {
        name: "2007Q2",
        line1: 5.6,
      },
      {
        name: "2007Q3",
        line1: 5.3,
      },
      {
        name: "2007Q4",
        line1: 6,
      },
      {
        name: "2008Q1",
        line1: 4.2,
      },
      {
        name: "2008Q2",
        line1: 1.4,
      },
      {
        name: "2008Q3",
        line1: 0.9,
      },
      {
        name: "2008Q4",
        line1: -2.9,
      },
      {
        name: "2009Q1",
        line1: -9,
      },
      {
        name: "2009Q2",
        line1: -8.5,
      },
      {
        name: "2009Q3",
        line1: -7.9,
      },
      {
        name: "2009Q4",
        line1: -6.9,
      },
      {
        name: "2010Q1",
        line1: 0.6,
      },
      {
        name: "2010Q2",
        line1: 3.7,
      },
      {
        name: "2010Q3",
        line1: 2.5,
      },
      {
        name: "2010Q4",
        line1: 5.7,
      },
      {
        name: "2011Q1",
        line1: 5.2,
      },
      {
        name: "2011Q2",
        line1: 2.1,
      },
      {
        name: "2011Q3",
        line1: 2.8,
      },
      {
        name: "2011Q4",
        line1: 0.5,
      },
      {
        name: "2012Q1",
        line1: -0.2,
      },
      {
        name: "2012Q2",
        line1: -1.4,
      },
      {
        name: "2012Q3",
        line1: -1.8,
      },
      {
        name: "2012Q4",
        line1: -2.2,
      },
      {
        name: "2013Q1",
        line1: -2.6,
      },
      {
        name: "2013Q2",
        line1: -0.7,
      },
      {
        name: "2013Q3",
        line1: -0.1,
      },
      {
        name: "2013Q4",
        line1: -0.3,
      },
      {
        name: "2014Q1",
        line1: -0.3,
      },
      {
        name: "2014Q2",
        line1: -0.3,
      },
      {
        name: "2014Q3",
        line1: -0.4,
      },
      {
        name: "2014Q4",
        line1: -0.5,
      },
      {
        name: "2015Q1",
        line1: -0.4,
      },
      {
        name: "2015Q2",
        line1: 1,
      },
      {
        name: "2015Q3",
        line1: 0.4,
      },
      {
        name: "2015Q4",
        line1: 1.1,
      },
      {
        name: "2016Q1",
        line1: 3.3,
      },
      {
        name: "2016Q2",
        line1: 1.6,
      },
      {
        name: "2016Q3",
        line1: 3.1,
      },
      {
        name: "2016Q4",
        line1: 3.2,
      },
      {
        name: "2017Q1",
        line1: 2.8,
      },
      {
        name: "2017Q2",
        line1: 3.9,
      },
      {
        name: "2017Q3",
        line1: 2.8,
      },
      {
        name: "2017Q4",
        line1: 3.2,
      },
      {
        name: "2018Q1",
        line1: 2.6,
      },
      {
        name: "2018Q2",
        line1: 1.4,
      },
      {
        name: "2018Q3",
        line1: 1,
      },
      {
        name: "2018Q4",
        line1: 0.4,
      },
      {
        name: "2019Q1",
        line1: 0.7,
      },
      {
        name: "2019Q2",
        line1: 1.5,
      },
      {
        name: "2019Q3",
        line1: 1.8,
      },
      {
        name: "2019Q4",
        line1: 1.1,
      },
      {
        name: "2020Q1",
        line1: -0.9,
      },
      {
        name: "2020Q2",
        line1: -6.3,
      },
      {
        name: "2020Q3",
        line1: -2.6,
      },
      {
        name: "2020Q4",
        line1: -1.3,
      },
      {
        name: "2021Q1",
        line1: -1.1,
      },
      {
        name: "2021Q2",
        line1: 7.1,
      },
      {
        name: "2021Q3",
        line1: 3.1,
      },
      {
        name: "2021Q4",
        line1: 3,
      },
      {
        name: "2022Q1",
        line1: 3.8,
      },
      {
        name: "2022Q2",
        line1: 3.2,
      },
    ],
  },
  {
    name: "kuvio-6.3",
    height: 450,
    width: "100%",
    xAxisLabel: "Kuukausi",
    yAxisLabel: "%",
    yDomain: [4, 12],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: 0,
    xAxisOffset: -65,
    xAxisPadding: 70,
    minTickGapX: 5,
    xTickCount: 30,
    yTickCount: 30,
    interval: 0,
    yAxisWidth: 50,
    margins: [25, 35, 0, 5],
    tickDx: 0,
    tickDy: 35,
    xLabelPosition: "insideBottom",
    xAxisLabelSideways: 90,
    fontSize: "0.8rem",
    xTickFontSize: "1rem",
    type: ["category", "number"],
    manualTicksX: [
      "2010M08",
      "2011M08",
      "2012M08",
      "2013M08",
      "2014M08",
      "2015M08",
      "2016M08",
      "2017M08",
      "2018M08",
      "2019M08",
      "2020M08",
      "2021M08",
      "2022M08",
    ],
    manualTicksY: [4, 5, 6, 7, 8, 9, 10, 11, 12],
    names: ["Työttömyysaste, %", "Työttömyysaste, %, trendi"],
    data: [
      {
        name: "2010M01",
        line1: 9.5,
        line2: 8.8,
      },
      {
        name: "2010M02",
        line1: 9.2,
        line2: 8.7,
      },
      {
        name: "2010M03",
        line1: 9.1,
        line2: 8.6,
      },
      {
        name: "2010M04",
        line1: 9.3,
        line2: 8.5,
      },
      {
        name: "2010M05",
        line1: 10.5,
        line2: 8.5,
      },
      {
        name: "2010M06",
        line1: 8.8,
        line2: 8.4,
      },
      {
        name: "2010M07",
        line1: 7.5,
        line2: 8.4,
      },
      {
        name: "2010M08",
        line1: 7.3,
        line2: 8.3,
      },
      {
        name: "2010M09",
        line1: 7,
        line2: 8.2,
      },
      {
        name: "2010M10",
        line1: 7.4,
        line2: 8.1,
      },
      {
        name: "2010M11",
        line1: 7.1,
        line2: 8.1,
      },
      {
        name: "2010M12",
        line1: 7.9,
        line2: 8.1,
      },
      {
        name: "2011M01",
        line1: 8.2,
        line2: 8,
      },
      {
        name: "2011M02",
        line1: 8.4,
        line2: 8,
      },
      {
        name: "2011M03",
        line1: 9.3,
        line2: 7.9,
      },
      {
        name: "2011M04",
        line1: 8.2,
        line2: 7.9,
      },
      {
        name: "2011M05",
        line1: 9.8,
        line2: 7.8,
      },
      {
        name: "2011M06",
        line1: 8.4,
        line2: 7.8,
      },
      {
        name: "2011M07",
        line1: 6.9,
        line2: 7.7,
      },
      {
        name: "2011M08",
        line1: 6.6,
        line2: 7.7,
      },
      {
        name: "2011M09",
        line1: 6.9,
        line2: 7.6,
      },
      {
        name: "2011M10",
        line1: 7,
        line2: 7.6,
      },
      {
        name: "2011M11",
        line1: 6.2,
        line2: 7.6,
      },
      {
        name: "2011M12",
        line1: 7.4,
        line2: 7.6,
      },
      {
        name: "2012M01",
        line1: 7.8,
        line2: 7.5,
      },
      {
        name: "2012M02",
        line1: 7.7,
        line2: 7.5,
      },
      {
        name: "2012M03",
        line1: 8.5,
        line2: 7.6,
      },
      {
        name: "2012M04",
        line1: 8.4,
        line2: 7.6,
      },
      {
        name: "2012M05",
        line1: 9.5,
        line2: 7.6,
      },
      {
        name: "2012M06",
        line1: 7.9,
        line2: 7.7,
      },
      {
        name: "2012M07",
        line1: 6.9,
        line2: 7.8,
      },
      {
        name: "2012M08",
        line1: 7.3,
        line2: 7.8,
      },
      {
        name: "2012M09",
        line1: 7.1,
        line2: 7.9,
      },
      {
        name: "2012M10",
        line1: 6.9,
        line2: 7.9,
      },
      {
        name: "2012M11",
        line1: 7.3,
        line2: 7.9,
      },
      {
        name: "2012M12",
        line1: 6.9,
        line2: 7.9,
      },
      {
        name: "2013M01",
        line1: 8.7,
        line2: 8,
      },
      {
        name: "2013M02",
        line1: 8.7,
        line2: 8.1,
      },
      {
        name: "2013M03",
        line1: 9,
        line2: 8.1,
      },
      {
        name: "2013M04",
        line1: 8.8,
        line2: 8.1,
      },
      {
        name: "2013M05",
        line1: 10.8,
        line2: 8.1,
      },
      {
        name: "2013M06",
        line1: 7.8,
        line2: 8.1,
      },
      {
        name: "2013M07",
        line1: 6.6,
        line2: 8.1,
      },
      {
        name: "2013M08",
        line1: 7.1,
        line2: 8.1,
      },
      {
        name: "2013M09",
        line1: 7.6,
        line2: 8.2,
      },
      {
        name: "2013M10",
        line1: 7.4,
        line2: 8.3,
      },
      {
        name: "2013M11",
        line1: 7.9,
        line2: 8.3,
      },
      {
        name: "2013M12",
        line1: 7.9,
        line2: 8.4,
      },
      {
        name: "2014M01",
        line1: 8.5,
        line2: 8.4,
      },
      {
        name: "2014M02",
        line1: 9.1,
        line2: 8.4,
      },
      {
        name: "2014M03",
        line1: 9.5,
        line2: 8.4,
      },
      {
        name: "2014M04",
        line1: 9,
        line2: 8.5,
      },
      {
        name: "2014M05",
        line1: 10.7,
        line2: 8.5,
      },
      {
        name: "2014M06",
        line1: 9.2,
        line2: 8.6,
      },
      {
        name: "2014M07",
        line1: 7,
        line2: 8.6,
      },
      {
        name: "2014M08",
        line1: 7.4,
        line2: 8.7,
      },
      {
        name: "2014M09",
        line1: 8.2,
        line2: 8.8,
      },
      {
        name: "2014M10",
        line1: 8.3,
        line2: 8.9,
      },
      {
        name: "2014M11",
        line1: 8.2,
        line2: 9,
      },
      {
        name: "2014M12",
        line1: 8.8,
        line2: 9.1,
      },
      {
        name: "2015M01",
        line1: 8.8,
        line2: 9.2,
      },
      {
        name: "2015M02",
        line1: 10.1,
        line2: 9.2,
      },
      {
        name: "2015M03",
        line1: 10.3,
        line2: 9.3,
      },
      {
        name: "2015M04",
        line1: 10.3,
        line2: 9.4,
      },
      {
        name: "2015M05",
        line1: 11.8,
        line2: 9.4,
      },
      {
        name: "2015M06",
        line1: 10,
        line2: 9.5,
      },
      {
        name: "2015M07",
        line1: 8.4,
        line2: 9.4,
      },
      {
        name: "2015M08",
        line1: 8.3,
        line2: 9.4,
      },
      {
        name: "2015M09",
        line1: 8.4,
        line2: 9.4,
      },
      {
        name: "2015M10",
        line1: 8.7,
        line2: 9.3,
      },
      {
        name: "2015M11",
        line1: 8.2,
        line2: 9.3,
      },
      {
        name: "2015M12",
        line1: 9.2,
        line2: 9.2,
      },
      {
        name: "2016M01",
        line1: 9.3,
        line2: 9.2,
      },
      {
        name: "2016M02",
        line1: 9.4,
        line2: 9.1,
      },
      {
        name: "2016M03",
        line1: 10.1,
        line2: 9,
      },
      {
        name: "2016M04",
        line1: 9.8,
        line2: 8.9,
      },
      {
        name: "2016M05",
        line1: 10.8,
        line2: 8.9,
      },
      {
        name: "2016M06",
        line1: 9.3,
        line2: 8.8,
      },
      {
        name: "2016M07",
        line1: 7.8,
        line2: 8.8,
      },
      {
        name: "2016M08",
        line1: 7.2,
        line2: 8.7,
      },
      {
        name: "2016M09",
        line1: 7.7,
        line2: 8.7,
      },
      {
        name: "2016M10",
        line1: 8.1,
        line2: 8.7,
      },
      {
        name: "2016M11",
        line1: 8.1,
        line2: 8.7,
      },
      {
        name: "2016M12",
        line1: 7.9,
        line2: 8.7,
      },
      {
        name: "2017M01",
        line1: 9.2,
        line2: 8.7,
      },
      {
        name: "2017M02",
        line1: 9.2,
        line2: 8.7,
      },
      {
        name: "2017M03",
        line1: 9.6,
        line2: 8.8,
      },
      {
        name: "2017M04",
        line1: 10.2,
        line2: 8.8,
      },
      {
        name: "2017M05",
        line1: 10.7,
        line2: 8.7,
      },
      {
        name: "2017M06",
        line1: 8.9,
        line2: 8.7,
      },
      {
        name: "2017M07",
        line1: 7.5,
        line2: 8.6,
      },
      {
        name: "2017M08",
        line1: 7.5,
        line2: 8.5,
      },
      {
        name: "2017M09",
        line1: 8,
        line2: 8.5,
      },
      {
        name: "2017M10",
        line1: 7.3,
        line2: 8.4,
      },
      {
        name: "2017M11",
        line1: 7.1,
        line2: 8.3,
      },
      {
        name: "2017M12",
        line1: 8.4,
        line2: 8.3,
      },
      {
        name: "2018M01",
        line1: 8.8,
        line2: 8.2,
      },
      {
        name: "2018M02",
        line1: 8.6,
        line2: 8,
      },
      {
        name: "2018M03",
        line1: 8.8,
        line2: 7.8,
      },
      {
        name: "2018M04",
        line1: 8.6,
        line2: 7.6,
      },
      {
        name: "2018M05",
        line1: 9.3,
        line2: 7.5,
      },
      {
        name: "2018M06",
        line1: 6.7,
        line2: 7.3,
      },
      {
        name: "2018M07",
        line1: 6.5,
        line2: 7.3,
      },
      {
        name: "2018M08",
        line1: 6.8,
        line2: 7.2,
      },
      {
        name: "2018M09",
        line1: 6.3,
        line2: 7.1,
      },
      {
        name: "2018M10",
        line1: 6.3,
        line2: 7,
      },
      {
        name: "2018M11",
        line1: 6.2,
        line2: 6.8,
      },
      {
        name: "2018M12",
        line1: 5.4,
        line2: 6.7,
      },
      {
        name: "2019M01",
        line1: 6.8,
        line2: 6.6,
      },
      {
        name: "2019M02",
        line1: 7.4,
        line2: 6.6,
      },
      {
        name: "2019M03",
        line1: 7,
        line2: 6.6,
      },
      {
        name: "2019M04",
        line1: 8,
        line2: 6.7,
      },
      {
        name: "2019M05",
        line1: 8.8,
        line2: 6.7,
      },
      {
        name: "2019M06",
        line1: 6.2,
        line2: 6.7,
      },
      {
        name: "2019M07",
        line1: 6,
        line2: 6.7,
      },
      {
        name: "2019M08",
        line1: 6.1,
        line2: 6.7,
      },
      {
        name: "2019M09",
        line1: 5.9,
        line2: 6.7,
      },
      {
        name: "2019M10",
        line1: 6.2,
        line2: 6.7,
      },
      {
        name: "2019M11",
        line1: 5.9,
        line2: 6.7,
      },
      {
        name: "2019M12",
        line1: 6,
        line2: 6.7,
      },
      {
        name: "2020M01",
        line1: 7.2,
        line2: 6.7,
      },
      {
        name: "2020M02",
        line1: 6.9,
        line2: 6.7,
      },
      {
        name: "2020M03",
        line1: 7.3,
        line2: 6.7,
      },
      {
        name: "2020M04",
        line1: 8.1,
        line2: 6.7,
      },
      {
        name: "2020M05",
        line1: 10.6,
        line2: 8.4,
      },
      {
        name: "2020M06",
        line1: 7.9,
        line2: 8.3,
      },
      {
        name: "2020M07",
        line1: 7.7,
        line2: 8.3,
      },
      {
        name: "2020M08",
        line1: 7.7,
        line2: 8.3,
      },
      {
        name: "2020M09",
        line1: 7.6,
        line2: 8.3,
      },
      {
        name: "2020M10",
        line1: 7.4,
        line2: 8.2,
      },
      {
        name: "2020M11",
        line1: 6.9,
        line2: 8.2,
      },
      {
        name: "2020M12",
        line1: 7.6,
        line2: 8.1,
      },
      {
        name: "2021M01",
        line1: 8.8,
        line2: 8.1,
      },
      {
        name: "2021M02",
        line1: 8.3,
        line2: 8.1,
      },
      {
        name: "2021M03",
        line1: 8.2,
        line2: 8,
      },
      {
        name: "2021M04",
        line1: 9.6,
        line2: 8.1,
      },
      {
        name: "2021M05",
        line1: 10.3,
        line2: 8,
      },
      {
        name: "2021M06",
        line1: 7.6,
        line2: 7.8,
      },
      {
        name: "2021M07",
        line1: 7.1,
        line2: 7.6,
      },
      {
        name: "2021M08",
        line1: 6.5,
        line2: 7.4,
      },
      {
        name: "2021M09",
        line1: 7,
        line2: 7.2,
      },
      {
        name: "2021M10",
        line1: 6,
        line2: 7.1,
      },
      {
        name: "2021M11",
        line1: 6,
        line2: 7,
      },
      {
        name: "2021M12",
        line1: 6.7,
        line2: 6.9,
      },
      {
        name: "2022M01",
        line1: 7.5,
        line2: 6.8,
      },
      {
        name: "2022M02",
        line1: 6.7,
        line2: 6.7,
      },
      {
        name: "2022M03",
        line1: 7,
        line2: 6.5,
      },
      {
        name: "2022M04",
        line1: 6.9,
        line2: 6.4,
      },
      {
        name: "2022M05",
        line1: 7.9,
        line2: 6.5,
      },
      {
        name: "2022M06",
        line1: 6.8,
        line2: 6.8,
      },
      {
        name: "2022M07",
        line1: 6.6,
        line2: 7,
      },
      {
        name: "2022M08",
        line1: 6.7,
        line2: 7.1,
      },
    ],
  },
  {
    name: "kuvio-6.4",
    height: 450,
    width: "100%",
    xAxisLabel: "Kuukausi",
    yAxisLabel: "%",
    yDomain: [64, 76],
    type: ["category", "number"],
    yAxisOffset: 0,
    xAxisOffset: -65,
    xAxisPadding: 70,
    minTickGapX: 5,
    xTickCount: 30,
    yTickCount: 30,
    interval: 0,
    yAxisWidth: 60,
    margins: [25, 35, 0, 5],
    tickDx: 0,
    tickDy: 35,
    xLabelPosition: "insideBottom",
    xAxisLabelSideways: 90,
    fontSize: "0.8rem",
    xTickFontSize: "1rem",
    manualTicksX: [
      "2010M08",
      "2011M08",
      "2012M08",
      "2013M08",
      "2014M08",
      "2015M08",
      "2016M08",
      "2017M08",
      "2018M08",
      "2019M08",
      "2020M08",
      "2021M08",
      "2022M08",
    ],
    manualTicksY: [64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76],
    names: [
      "Työllisyysaste, 15-64-vuotiaat, %",
      "Työllisyysaste, 15-64-vuotiaat, %, trendi",
    ],
    data: [
      {
        name: "2010M01",
        line1: 65.5,
        line2: 67.7,
      },
      {
        name: "2010M02",
        line1: 66.4,
        line2: 67.7,
      },
      {
        name: "2010M03",
        line1: 66.5,
        line2: 67.7,
      },
      {
        name: "2010M04",
        line1: 66.9,
        line2: 67.7,
      },
      {
        name: "2010M05",
        line1: 68.6,
        line2: 67.7,
      },
      {
        name: "2010M06",
        line1: 70.9,
        line2: 67.7,
      },
      {
        name: "2010M07",
        line1: 70.6,
        line2: 67.8,
      },
      {
        name: "2010M08",
        line1: 68.8,
        line2: 67.8,
      },
      {
        name: "2010M09",
        line1: 67.3,
        line2: 67.9,
      },
      {
        name: "2010M10",
        line1: 67.9,
        line2: 68,
      },
      {
        name: "2010M11",
        line1: 67.3,
        line2: 68,
      },
      {
        name: "2010M12",
        line1: 66.6,
        line2: 68.1,
      },
      {
        name: "2011M01",
        line1: 66.8,
        line2: 68.2,
      },
      {
        name: "2011M02",
        line1: 67.1,
        line2: 68.3,
      },
      {
        name: "2011M03",
        line1: 66.4,
        line2: 68.3,
      },
      {
        name: "2011M04",
        line1: 68.2,
        line2: 68.4,
      },
      {
        name: "2011M05",
        line1: 69.2,
        line2: 68.5,
      },
      {
        name: "2011M06",
        line1: 71.7,
        line2: 68.6,
      },
      {
        name: "2011M07",
        line1: 71.8,
        line2: 68.7,
      },
      {
        name: "2011M08",
        line1: 69.8,
        line2: 68.7,
      },
      {
        name: "2011M09",
        line1: 67.8,
        line2: 68.7,
      },
      {
        name: "2011M10",
        line1: 68.3,
        line2: 68.8,
      },
      {
        name: "2011M11",
        line1: 69.2,
        line2: 68.9,
      },
      {
        name: "2011M12",
        line1: 67.1,
        line2: 68.9,
      },
      {
        name: "2012M01",
        line1: 66.9,
        line2: 68.9,
      },
      {
        name: "2012M02",
        line1: 68,
        line2: 69,
      },
      {
        name: "2012M03",
        line1: 67.6,
        line2: 69.1,
      },
      {
        name: "2012M04",
        line1: 68.5,
        line2: 69.1,
      },
      {
        name: "2012M05",
        line1: 69.9,
        line2: 69.1,
      },
      {
        name: "2012M06",
        line1: 71.7,
        line2: 69.1,
      },
      {
        name: "2012M07",
        line1: 72.7,
        line2: 69.1,
      },
      {
        name: "2012M08",
        line1: 70,
        line2: 69,
      },
      {
        name: "2012M09",
        line1: 68.1,
        line2: 69,
      },
      {
        name: "2012M10",
        line1: 68.7,
        line2: 68.9,
      },
      {
        name: "2012M11",
        line1: 68.4,
        line2: 68.8,
      },
      {
        name: "2012M12",
        line1: 67.3,
        line2: 68.7,
      },
      {
        name: "2013M01",
        line1: 66.9,
        line2: 68.7,
      },
      {
        name: "2013M02",
        line1: 67.6,
        line2: 68.6,
      },
      {
        name: "2013M03",
        line1: 66.6,
        line2: 68.6,
      },
      {
        name: "2013M04",
        line1: 68.7,
        line2: 68.6,
      },
      {
        name: "2013M05",
        line1: 68.9,
        line2: 68.7,
      },
      {
        name: "2013M06",
        line1: 72.1,
        line2: 68.7,
      },
      {
        name: "2013M07",
        line1: 71.7,
        line2: 68.6,
      },
      {
        name: "2013M08",
        line1: 69.4,
        line2: 68.4,
      },
      {
        name: "2013M09",
        line1: 67.3,
        line2: 68.3,
      },
      {
        name: "2013M10",
        line1: 68.1,
        line2: 68.3,
      },
      {
        name: "2013M11",
        line1: 67.8,
        line2: 68.3,
      },
      {
        name: "2013M12",
        line1: 66.9,
        line2: 68.3,
      },
      {
        name: "2014M01",
        line1: 67,
        line2: 68.3,
      },
      {
        name: "2014M02",
        line1: 66.9,
        line2: 68.3,
      },
      {
        name: "2014M03",
        line1: 66.7,
        line2: 68.4,
      },
      {
        name: "2014M04",
        line1: 68.3,
        line2: 68.4,
      },
      {
        name: "2014M05",
        line1: 68.9,
        line2: 68.5,
      },
      {
        name: "2014M06",
        line1: 71.7,
        line2: 68.4,
      },
      {
        name: "2014M07",
        line1: 71.2,
        line2: 68.4,
      },
      {
        name: "2014M08",
        line1: 68.9,
        line2: 68.3,
      },
      {
        name: "2014M09",
        line1: 68.2,
        line2: 68.2,
      },
      {
        name: "2014M10",
        line1: 67.6,
        line2: 68.2,
      },
      {
        name: "2014M11",
        line1: 67.3,
        line2: 68.2,
      },
      {
        name: "2014M12",
        line1: 67.4,
        line2: 68.2,
      },
      {
        name: "2015M01",
        line1: 66.7,
        line2: 68.2,
      },
      {
        name: "2015M02",
        line1: 66.6,
        line2: 68.1,
      },
      {
        name: "2015M03",
        line1: 66.8,
        line2: 68.1,
      },
      {
        name: "2015M04",
        line1: 67.7,
        line2: 68,
      },
      {
        name: "2015M05",
        line1: 68,
        line2: 67.9,
      },
      {
        name: "2015M06",
        line1: 71,
        line2: 68,
      },
      {
        name: "2015M07",
        line1: 70.7,
        line2: 68.1,
      },
      {
        name: "2015M08",
        line1: 68.8,
        line2: 68.3,
      },
      {
        name: "2015M09",
        line1: 68.8,
        line2: 68.4,
      },
      {
        name: "2015M10",
        line1: 68.4,
        line2: 68.3,
      },
      {
        name: "2015M11",
        line1: 66.9,
        line2: 68.2,
      },
      {
        name: "2015M12",
        line1: 66.9,
        line2: 68.1,
      },
      {
        name: "2016M01",
        line1: 66.8,
        line2: 68.2,
      },
      {
        name: "2016M02",
        line1: 66.7,
        line2: 68.4,
      },
      {
        name: "2016M03",
        line1: 68,
        line2: 68.5,
      },
      {
        name: "2016M04",
        line1: 67.9,
        line2: 68.6,
      },
      {
        name: "2016M05",
        line1: 68.6,
        line2: 68.6,
      },
      {
        name: "2016M06",
        line1: 72,
        line2: 68.7,
      },
      {
        name: "2016M07",
        line1: 71.1,
        line2: 68.8,
      },
      {
        name: "2016M08",
        line1: 69.9,
        line2: 68.9,
      },
      {
        name: "2016M09",
        line1: 69.2,
        line2: 68.9,
      },
      {
        name: "2016M10",
        line1: 68.4,
        line2: 68.9,
      },
      {
        name: "2016M11",
        line1: 67.7,
        line2: 68.8,
      },
      {
        name: "2016M12",
        line1: 68.3,
        line2: 68.8,
      },
      {
        name: "2017M01",
        line1: 67,
        line2: 68.9,
      },
      {
        name: "2017M02",
        line1: 67.3,
        line2: 69.1,
      },
      {
        name: "2017M03",
        line1: 68.8,
        line2: 69.2,
      },
      {
        name: "2017M04",
        line1: 68.6,
        line2: 69.3,
      },
      {
        name: "2017M05",
        line1: 69.4,
        line2: 69.3,
      },
      {
        name: "2017M06",
        line1: 72.5,
        line2: 69.4,
      },
      {
        name: "2017M07",
        line1: 72,
        line2: 69.5,
      },
      {
        name: "2017M08",
        line1: 70.5,
        line2: 69.7,
      },
      {
        name: "2017M09",
        line1: 69.3,
        line2: 69.9,
      },
      {
        name: "2017M10",
        line1: 70,
        line2: 70.2,
      },
      {
        name: "2017M11",
        line1: 70.4,
        line2: 70.5,
      },
      {
        name: "2017M12",
        line1: 69.6,
        line2: 70.7,
      },
      {
        name: "2018M01",
        line1: 69.3,
        line2: 70.9,
      },
      {
        name: "2018M02",
        line1: 69.8,
        line2: 71,
      },
      {
        name: "2018M03",
        line1: 70,
        line2: 71.2,
      },
      {
        name: "2018M04",
        line1: 70.1,
        line2: 71.4,
      },
      {
        name: "2018M05",
        line1: 72.3,
        line2: 71.6,
      },
      {
        name: "2018M06",
        line1: 75.5,
        line2: 71.8,
      },
      {
        name: "2018M07",
        line1: 74.1,
        line2: 71.8,
      },
      {
        name: "2018M08",
        line1: 72.6,
        line2: 71.8,
      },
      {
        name: "2018M09",
        line1: 71.4,
        line2: 71.8,
      },
      {
        name: "2018M10",
        line1: 71.5,
        line2: 71.9,
      },
      {
        name: "2018M11",
        line1: 71.5,
        line2: 72.1,
      },
      {
        name: "2018M12",
        line1: 72.1,
        line2: 72.3,
      },
      {
        name: "2019M01",
        line1: 70.6,
        line2: 72.3,
      },
      {
        name: "2019M02",
        line1: 70.7,
        line2: 72.4,
      },
      {
        name: "2019M03",
        line1: 71.8,
        line2: 72.5,
      },
      {
        name: "2019M04",
        line1: 71.2,
        line2: 72.5,
      },
      {
        name: "2019M05",
        line1: 73.1,
        line2: 72.5,
      },
      {
        name: "2019M06",
        line1: 75.7,
        line2: 72.5,
      },
      {
        name: "2019M07",
        line1: 74.5,
        line2: 72.5,
      },
      {
        name: "2019M08",
        line1: 73.5,
        line2: 72.5,
      },
      {
        name: "2019M09",
        line1: 72.7,
        line2: 72.6,
      },
      {
        name: "2019M10",
        line1: 72,
        line2: 72.6,
      },
      {
        name: "2019M11",
        line1: 71.7,
        line2: 72.8,
      },
      {
        name: "2019M12",
        line1: 73,
        line2: 73,
      },
      {
        name: "2020M01",
        line1: 72.1,
        line2: 73.1,
      },
      {
        name: "2020M02",
        line1: 71.3,
        line2: 73,
      },
      {
        name: "2020M03",
        line1: 71.3,
        line2: 73,
      },
      {
        name: "2020M04",
        line1: 70.1,
        line2: 70.5,
      },
      {
        name: "2020M05",
        line1: 70.4,
        line2: 70.6,
      },
      {
        name: "2020M06",
        line1: 73.7,
        line2: 70.7,
      },
      {
        name: "2020M07",
        line1: 73.2,
        line2: 70.9,
      },
      {
        name: "2020M08",
        line1: 71.5,
        line2: 71.2,
      },
      {
        name: "2020M09",
        line1: 72,
        line2: 71.5,
      },
      {
        name: "2020M10",
        line1: 71.5,
        line2: 71.8,
      },
      {
        name: "2020M11",
        line1: 71.8,
        line2: 71.8,
      },
      {
        name: "2020M12",
        line1: 70,
        line2: 70.3,
      },
      {
        name: "2021M01",
        line1: 69.1,
        line2: 70.5,
      },
      {
        name: "2021M02",
        line1: 70,
        line2: 70.8,
      },
      {
        name: "2021M03",
        line1: 70.7,
        line2: 71.1,
      },
      {
        name: "2021M04",
        line1: 70.1,
        line2: 71.4,
      },
      {
        name: "2021M05",
        line1: 73,
        line2: 71.8,
      },
      {
        name: "2021M06",
        line1: 75.8,
        line2: 72.2,
      },
      {
        name: "2021M07",
        line1: 74.9,
        line2: 72.4,
      },
      {
        name: "2021M08",
        line1: 73.4,
        line2: 72.5,
      },
      {
        name: "2021M09",
        line1: 71.7,
        line2: 72.6,
      },
      {
        name: "2021M10",
        line1: 72.3,
        line2: 72.8,
      },
      {
        name: "2021M11",
        line1: 72.2,
        line2: 73.1,
      },
      {
        name: "2021M12",
        line1: 73.3,
        line2: 73.3,
      },
      {
        name: "2022M01",
        line1: 71.5,
        line2: 73.5,
      },
      {
        name: "2022M02",
        line1: 72.7,
        line2: 73.6,
      },
      {
        name: "2022M03",
        line1: 72.5,
        line2: 73.6,
      },
      {
        name: "2022M04",
        line1: 72.7,
        line2: 73.6,
      },
      {
        name: "2022M05",
        line1: 74.7,
        line2: 73.6,
      },
      {
        name: "2022M06",
        line1: 76.9,
        line2: 73.6,
      },
      {
        name: "2022M07",
        line1: 75.7,
        line2: 73.5,
      },
      {
        name: "2022M08",
        line1: 74.2,
        line2: 73.6,
      },
    ],
  },
  {
    name: "kuvio-5.15b",
    height: 450,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    yDomain: [0, 50],
    tickDy: 0,
    type: ["category", "number"],
    manualTicksX: [1960, 1970, 1980, 1990, 2000, 2010, 2020, 2030],
    yAxisOffset: 0,
    xAxisOffset: -15,
    minTickGapX: 15,
    yTickCount: 10,
    yAxisWidth: 60,
    xTickCount: 8,
    margins: [15, 25, 0, 5],
    tickDx: 0,
    fontSize: "1rem",
    names: ["Kiina", "Intia", "Maailma", "Kenia", "Argentiina"],
    data: [
      {
        name: 1960,
        line1: 33,
        line2: 14,

        line5: 23,
      },
      {
        name: 1961,
        line1: 19,
        line2: 16,

        line5: 28,
      },
      {
        name: 1962,
        line1: 15,
        line2: 16,

        line5: 22,
      },
      {
        name: 1963,
        line1: 18,
        line2: 16,

        line5: 18,
      },
      {
        name: 1964,
        line1: 20,
        line2: 16,

        line4: 12,
        line5: 17,
      },
      {
        name: 1965,
        line1: 21,
        line2: 17,

        line4: 13,
        line5: 19,
      },
      {
        name: 1966,
        line1: 22,
        line2: 17,

        line4: 15,
        line5: 21,
      },
      {
        name: 1967,
        line1: 18,
        line2: 16,

        line4: 19,
        line5: 21,
      },
      {
        name: 1968,
        line1: 17,
        line2: 16,

        line4: 19,
        line5: 22,
      },
      {
        name: 1969,
        line1: 21,
        line2: 16,

        line4: 18,
        line5: 24,
      },
      {
        name: 1970,
        line1: 24,
        line2: 16,
        line3: 25,
        line4: 20,
        line5: 24,
      },
      {
        name: 1971,
        line1: 25,
        line2: 17,
        line3: 25,
        line4: 23,
        line5: 24,
      },
      {
        name: 1972,
        line1: 25,
        line2: 17,
        line3: 26,
        line4: 22,
        line5: 24,
      },
      {
        name: 1973,
        line1: 24,
        line2: 16,
        line3: 27,
        line4: 20,
        line5: 21,
      },
      {
        name: 1974,
        line1: 27,
        line2: 17,
        line3: 26,
        line4: 19,
        line5: 22,
      },
      {
        name: 1975,
        line1: 29,
        line2: 18,
        line3: 26,
        line4: 20,
        line5: 29,
      },
      {
        name: 1976,
        line1: 29,
        line2: 18,
        line3: 26,
        line4: 20,
        line5: 31,
      },
      {
        name: 1977,
        line1: 28,
        line2: 19,
        line3: 26,
        line4: 21,
        line5: 31,
      },
      {
        name: 1978,
        line1: 29,
        line2: 19,
        line3: 27,
        line4: 25,
        line5: 28,
      },
      {
        name: 1979,
        line1: 28,
        line2: 20,
        line3: 27,
        line4: 19,
        line5: 24,
      },
      {
        name: 1980,
        line1: 29,
        line2: 20,
        line3: 27,
        line4: 18,
        line5: 25,
      },
      {
        name: 1981,
        line1: 27,
        line2: 20,
        line3: 26,
        line4: 19,
        line5: 23,
      },
      {
        name: 1982,
        line1: 28,
        line2: 21,
        line3: 25,
        line4: 19,
        line5: 22,
      },
      {
        name: 1983,
        line1: 28,
        line2: 21,
        line3: 25,
        line4: 18,
        line5: 21,
      },
      {
        name: 1984,
        line1: 29,
        line2: 21,
        line3: 24,
        line4: 17,
        line5: 20,
      },
      {
        name: 1985,
        line1: 30,
        line2: 22,
        line3: 24,
        line4: 17,
        line5: 18,
      },
      {
        name: 1986,
        line1: 31,
        line2: 23,
        line3: 25,
        line4: 20,
        line5: 17,
      },
      {
        name: 1987,
        line1: 31,
        line2: 24,
        line3: 25,
        line4: 20,
        line5: 20,
      },
      {
        name: 1988,
        line1: 31,
        line2: 24,
        line3: 25,
        line4: 20,
        line5: 19,
      },
      {
        name: 1989,
        line1: 26,
        line2: 25,
        line3: 26,
        line4: 19,
        line5: 16,
      },
      {
        name: 1990,
        line1: 24,
        line2: 26,
        line3: 25,
        line4: 21,
        line5: 14,
      },
      {
        name: 1991,
        line1: 26,
        line2: 25,
        line3: 25,
        line4: 19,
        line5: 15,
      },
      {
        name: 1992,
        line1: 30,
        line2: 25,
        line3: 24,
        line4: 17,
        line5: 17,
      },
      {
        name: 1993,
        line1: 37,
        line2: 24,
        line3: 24,
        line4: 17,
        line5: 19,
      },
      {
        name: 1994,
        line1: 34,
        line2: 23,
        line3: 24,
        line4: 19,
        line5: 20,
      },
      {
        name: 1995,
        line1: 32,
        line2: 25,
        line3: 24,
        line4: 21,
        line5: 18,
      },
      {
        name: 1996,
        line1: 32,
        line2: 25,
        line3: 24,
        line4: 16,
        line5: 18,
      },
      {
        name: 1997,
        line1: 31,
        line2: 25,
        line3: 24,
        line4: 15,
        line5: 19,
      },
      {
        name: 1998,
        line1: 33,
        line2: 25,
        line3: 23,
        line4: 16,
        line5: 20,
      },
      {
        name: 1999,
        line1: 33,
        line2: 28,
        line3: 24,
        line4: 16,
        line5: 18,
      },
      {
        name: 2000,
        line1: 33,
        line2: 26,
        line3: 24,
        line4: 17,
        line5: 16,
      },
      {
        name: 2001,
        line1: 33,
        line2: 30,
        line3: 23,
        line4: 18,
        line5: 14,
      },
      {
        name: 2002,
        line1: 35,
        line2: 28,
        line3: 23,
        line4: 17,
        line5: 12,
      },
      {
        name: 2003,
        line1: 38,
        line2: 28,
        line3: 23,
        line4: 16,
        line5: 15,
      },
      {
        name: 2004,
        line1: 40,
        line2: 31,
        line3: 23,
        line4: 16,
        line5: 16,
      },
      {
        name: 2005,
        line1: 39,
        line2: 33,
        line3: 24,
        line4: 19,
        line5: 17,
      },
      {
        name: 2006,
        line1: 39,
        line2: 34,
        line3: 24,
        line4: 19,
        line5: 18,
      },
      {
        name: 2007,
        line1: 38,
        line2: 36,
        line3: 24,
        line4: 20,
        line5: 20,
      },
      {
        name: 2008,
        line1: 39,
        line2: 35,
        line3: 24,
        line4: 19,
        line5: 19,
      },
      {
        name: 2009,
        line1: 44,
        line2: 34,
        line3: 23,
        line4: 18,
        line5: 16,
      },
      {
        name: 2010,
        line1: 44,
        line2: 33,
        line3: 23,
        line4: 21,
        line5: 17,
      },
      {
        name: 2011,
        line1: 44,
        line2: 34,
        line3: 24,
        line4: 21,
        line5: 17,
      },
      {
        name: 2012,
        line1: 44,
        line2: 33,
        line3: 24,
        line4: 22,
        line5: 16,
      },
      {
        name: 2013,
        line1: 45,
        line2: 31,
        line3: 25,
        line4: 21,
        line5: 16,
      },
      {
        name: 2014,
        line1: 44,
        line2: 30,
        line3: 25,
        line4: 24,
        line5: 16,
      },
      {
        name: 2015,
        line1: 42,
        line2: 29,
        line3: 25,
        line4: 22,
        line5: 16,
      },
      {
        name: 2016,
        line1: 42,
        line2: 28,
        line3: 25,
        line4: 19,
        line5: 14,
      },
      {
        name: 2017,
        line1: 42,
        line2: 28,
        line3: 25,
        line4: 20,
        line5: 15,
      },
      {
        name: 2018,
        line1: 43,
        line2: 29,
        line3: 25,
        line4: 19,
        line5: 15,
      },
      {
        name: 2019,
        line1: 43,
        line2: 29,
        line3: 26,
        line4: 19,
        line5: 14,
      },
      {
        name: 2020,
        line1: 42,
        line2: 27,
        line3: 26,
        line4: 19,
        line5: 14,
      },
      {
        name: 2021,

        line2: 28,

        line4: 20,
        line5: 17,
      },
      {
        name: 2022,
      },
      {
        name: 2023,
      },
      {
        name: 2024,
      },
      {
        name: 2025,
      },
      {
        name: 2026,
      },
      {
        name: 2027,
      },
      {
        name: 2028,
      },
      {
        name: 2029,
      },
      {
        name: 2030,
      },
    ],
  },
  {
    name: "kuvio-4.8",
    height: 450,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    yDomain: [0, 60],
    yAxisOffset: 0,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 45,
    yAxisWidth: 55,
    margins: [0, 25, 15, 5],
    tickDx: 0,
    type: ["category", "number"],
    manualTicksX: [
      1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020,
    ],
    manualTicksY: [0, 10, 20, 30, 40, 50, 60],
    fontSize: "1rem",
    names: ["Suomi", "Ruotsi", "Saksa", "Japani", "USA"],
    data: [
      {
        name: 1920,
        line1: 9.87,
        line2: 13.49,
      },
      {
        name: 1921,
        line1: 9.78,
        line2: 13.44,
      },
      {
        name: 1922,
        line1: 9.64,
        line2: 13.36,
      },
      {
        name: 1923,
        line1: 9.64,
        line2: 13.54,
      },
      {
        name: 1924,
        line1: 9.56,
        line2: 13.63,
      },
      {
        name: 1925,
        line1: 9.59,
        line2: 13.75,
      },
      {
        name: 1926,
        line1: 9.64,
        line2: 13.75,
      },
      {
        name: 1927,
        line1: 9.61,
        line2: 13.73,
      },
      {
        name: 1928,
        line1: 9.65,
        line2: 13.8,
      },
      {
        name: 1929,
        line1: 9.71,
        line2: 13.87,
      },
      {
        name: 1930,
        line1: 9.75,
        line2: 13.97,
      },
      {
        name: 1931,
        line1: 9.51,
        line2: 13.88,
      },
      {
        name: 1932,
        line1: 9.32,
        line2: 13.8,
      },
      {
        name: 1933,
        line1: 9,
        line2: 13.65,
      },
      {
        name: 1934,
        line1: 9.08,
        line2: 13.58,
      },
      {
        name: 1935,
        line1: 9.19,
        line2: 13.42,
      },
      {
        name: 1936,
        line1: 9.22,
        line2: 13.35,
      },
      {
        name: 1937,
        line1: 9.28,
        line2: 13.29,
      },
      {
        name: 1938,
        line1: 9.34,
        line2: 13.31,
      },
      {
        name: 1939,
        line1: 9.47,
        line2: 13.32,
      },
      {
        name: 1940,
        line1: 9.57,
        line2: 13.4,
      },
      {
        name: 1941,
        line1: 9.74,
        line2: 13.5,
      },
      {
        name: 1942,
        line1: 9.86,
        line2: 13.81,
      },
      {
        name: 1943,
        line1: 9.97,
        line2: 14.05,
      },
      {
        name: 1944,
        line1: 10.12,
        line2: 14.28,
      },
      {
        name: 1945,
        line1: 10.21,
        line2: 14.45,
      },
      {
        name: 1946,
        line1: 10.29,
        line2: 14.59,
      },
      {
        name: 1947,
        line1: 10.37,
        line2: 14.69,
      },
      {
        name: 1948,
        line1: 10.48,
        line2: 14.91,
      },
      {
        name: 1949,
        line1: 10.47,
        line2: 15.19,
      },
      {
        name: 1950,
        line1: 10.44,
        line2: 15.44,
        line3: 14.16,
        line4: 8.19,
        line5: 12.62,
      },
      {
        name: 1951,
        line1: 10.62,
        line2: 15.7,
        line3: 14.53,
        line4: 8.21,
        line5: 12.96,
      },
      {
        name: 1952,
        line1: 10.79,
        line2: 16.03,
        line3: 14.87,
        line4: 8.27,
        line5: 13.27,
      },
      {
        name: 1953,
        line1: 10.91,
        line2: 16.34,
        line3: 15.18,
        line4: 8.37,
        line5: 13.62,
      },
      {
        name: 1954,
        line1: 11,
        line2: 16.58,
        line3: 15.45,
        line4: 8.51,
        line5: 13.96,
      },
      {
        name: 1955,
        line1: 11.11,
        line2: 16.85,
        line3: 15.66,
        line4: 8.65,
        line5: 14.27,
      },
      {
        name: 1956,
        line1: 11.23,
        line2: 17.14,
        line3: 15.88,
        line4: 8.7,
        line5: 14.58,
      },
      {
        name: 1957,
        line1: 11.38,
        line2: 17.3,
        line3: 16.12,
        line4: 8.71,
        line5: 14.81,
      },
      {
        name: 1958,
        line1: 11.49,
        line2: 17.53,
        line3: 16.42,
        line4: 8.75,
        line5: 14.94,
      },
      {
        name: 1959,
        line1: 11.65,
        line2: 17.75,
        line3: 16.75,
        line4: 8.84,
        line5: 15.12,
      },
      {
        name: 1960,
        line1: 11.8,
        line2: 17.95,
        line3: 17.1,
        line4: 8.96,
        line5: 15.4,
      },
      {
        name: 1961,
        line1: 11.87,
        line2: 18.18,
        line3: 17.54,
        line4: 9.09,
        line5: 15.58,
      },
      {
        name: 1962,
        line1: 11.87,
        line2: 18.4,
        line3: 17.98,
        line4: 9.17,
        line5: 15.66,
      },
      {
        name: 1963,
        line1: 12.05,
        line2: 18.68,
        line3: 18.4,
        line4: 9.22,
        line5: 15.76,
      },
      {
        name: 1964,
        line1: 12.29,
        line2: 18.94,
        line3: 18.87,
        line4: 9.29,
        line5: 15.88,
      },
      {
        name: 1965,
        line1: 12.49,
        line2: 19.26,
        line3: 19.35,
        line4: 9.39,
        line5: 15.96,
      },
      {
        name: 1966,
        line1: 12.72,
        line2: 19.64,
        line3: 19.85,
        line4: 9.56,
        line5: 15.92,
      },
      {
        name: 1967,
        line1: 12.92,
        line2: 20.04,
        line3: 20.39,
        line4: 9.79,
        line5: 15.92,
      },
      {
        name: 1968,
        line1: 13.15,
        line2: 20.38,
        line3: 20.84,
        line4: 10.01,
        line5: 15.92,
      },
      {
        name: 1969,
        line1: 13.63,
        line2: 20.69,
        line3: 21.24,
        line4: 10.21,
        line5: 15.84,
      },
      {
        name: 1970,
        line1: 14.01,
        line2: 21.06,
        line3: 21.65,
        line4: 10.39,
        line5: 15.83,
      },
      {
        name: 1971,
        line1: 14.3,
        line2: 21.58,
        line3: 22.04,
        line4: 10.58,
        line5: 15.84,
      },
      {
        name: 1972,
        line1: 14.69,
        line2: 22.13,
        line3: 22.43,
        line4: 10.86,
        line5: 15.86,
      },
      {
        name: 1973,
        line1: 15.08,
        line2: 22.73,
        line3: 22.79,
        line4: 11.18,
        line5: 15.94,
      },
      {
        name: 1974,
        line1: 15.53,
        line2: 23.3,
        line3: 23.13,
        line4: 11.51,
        line5: 16.06,
      },
      {
        name: 1975,
        line1: 15.99,
        line2: 23.78,
        line3: 23.39,
        line4: 11.86,
        line5: 16.21,
      },
      {
        name: 1976,
        line1: 16.44,
        line2: 24.18,
        line3: 23.54,
        line4: 12.22,
        line5: 16.42,
      },
      {
        name: 1977,
        line1: 16.91,
        line2: 24.64,
        line3: 23.72,
        line4: 12.6,
        line5: 16.64,
      },
      {
        name: 1978,
        line1: 17.25,
        line2: 25.01,
        line3: 23.9,
        line4: 13,
        line5: 16.81,
      },
      {
        name: 1979,
        line1: 17.58,
        line2: 25.31,
        line3: 23.98,
        line4: 13.39,
        line5: 16.93,
      },
      {
        name: 1980,
        line1: 17.79,
        line2: 25.51,
        line3: 23.79,
        line4: 13.75,
        line5: 17.02,
      },
      {
        name: 1981,
        line1: 17.91,
        line2: 25.71,
        line3: 23.17,
        line4: 14.13,
        line5: 17.14,
      },
      {
        name: 1982,
        line1: 18.08,
        line2: 25.95,
        line3: 22.34,
        line4: 14.5,
        line5: 17.27,
      },
      {
        name: 1983,
        line1: 18.22,
        line2: 26.18,
        line3: 21.51,
        line4: 14.79,
        line5: 17.35,
      },
      {
        name: 1984,
        line1: 18.17,
        line2: 26.4,
        line3: 20.99,
        line4: 15.07,
        line5: 17.5,
      },
      {
        name: 1985,
        line1: 18.49,
        line2: 26.97,
        line3: 20.98,
        line4: 15.43,
        line5: 17.72,
      },
      {
        name: 1986,
        line1: 18.82,
        line2: 27.35,
        line3: 21.18,
        line4: 15.83,
        line5: 17.87,
      },
      {
        name: 1987,
        line1: 19.1,
        line2: 27.54,
        line3: 21.35,
        line4: 16.24,
        line5: 18,
      },
      {
        name: 1988,
        line1: 19.47,
        line2: 27.62,
        line3: 21.45,
        line4: 16.67,
        line5: 18.27,
      },
      {
        name: 1989,
        line1: 19.75,
        line2: 27.66,
        line3: 21.51,
        line4: 17.18,
        line5: 18.52,
      },
      {
        name: 1990,
        line1: 20.02,
        line2: 27.67,
        line3: 21.61,
        line4: 17.78,
        line5: 18.57,
      },
      {
        name: 1991,
        line1: 20.27,
        line2: 27.67,
        line3: 21.74,
        line4: 18.48,
        line5: 18.68,
      },
      {
        name: 1992,
        line1: 20.5,
        line2: 27.64,
        line3: 21.9,
        line4: 19.22,
        line5: 18.84,
      },
      {
        name: 1993,
        line1: 20.76,
        line2: 27.56,
        line3: 22.12,
        line4: 19.96,
        line5: 18.91,
      },
      {
        name: 1994,
        line1: 21.13,
        line2: 27.44,
        line3: 22.39,
        line4: 20.74,
        line5: 19.01,
      },
      {
        name: 1995,
        line1: 21.46,
        line2: 27.42,
        line3: 22.66,
        line4: 21.55,
        line5: 19.11,
      },
      {
        name: 1996,
        line1: 21.73,
        line2: 27.36,
        line3: 22.89,
        line4: 22.41,
        line5: 19.09,
      },
      {
        name: 1997,
        line1: 21.92,
        line2: 27.29,
        line3: 23.05,
        line4: 23.35,
        line5: 19.01,
      },
      {
        name: 1998,
        line1: 22,
        line2: 27.13,
        line3: 23.2,
        line4: 24.31,
        line5: 18.9,
      },
      {
        name: 1999,
        line1: 22.17,
        line2: 26.94,
        line3: 23.55,
        line4: 25.26,
        line5: 18.76,
      },
      {
        name: 2000,
        line1: 22.41,
        line2: 26.76,
        line3: 24.18,
        line4: 26.25,
        line5: 18.59,
      },
      {
        name: 2001,
        line1: 22.65,
        line2: 26.61,
        line3: 24.9,
        line4: 27.34,
        line5: 18.51,
      },
      {
        name: 2002,
        line1: 22.94,
        line2: 26.47,
        line3: 25.66,
        line4: 28.42,
        line5: 18.46,
      },
      {
        name: 2003,
        line1: 23.32,
        line2: 26.41,
        line3: 26.47,
        line4: 29.41,
        line5: 18.4,
      },
      {
        name: 2004,
        line1: 23.8,
        line2: 26.46,
        line3: 27.44,
        line4: 30.31,
        line5: 18.4,
      },
      {
        name: 2005,
        line1: 23.98,
        line2: 26.44,
        line3: 28.52,
        line4: 31.34,
        line5: 18.44,
      },
      {
        name: 2006,
        line1: 24.77,
        line2: 26.44,
        line3: 29.51,
        line4: 32.61,
        line5: 18.5,
      },
      {
        name: 2007,
        line1: 24.79,
        line2: 26.66,
        line3: 30.18,
        line4: 33.97,
        line5: 18.61,
      },
      {
        name: 2008,
        line1: 25.18,
        line2: 27.11,
        line3: 30.6,
        line4: 35.27,
        line5: 18.86,
      },
      {
        name: 2009,
        line1: 25.63,
        line2: 27.72,
        line3: 30.97,
        line4: 36.46,
        line5: 19.15,
      },
      {
        name: 2010,
        line1: 26.53,
        line2: 28.42,
        line3: 31.02,
        line4: 37.34,
        line5: 19.41,
      },
      {
        name: 2011,
        line1: 27.73,
        line2: 29.19,
        line3: 30.87,
        line4: 38.11,
        line5: 19.81,
      },
      {
        name: 2012,
        line1: 28.95,
        line2: 29.89,
        line3: 30.91,
        line4: 39.53,
        line5: 20.34,
      },
      {
        name: 2013,
        line1: 30.19,
        line2: 30.56,
        line3: 31.09,
        line4: 41.58,
        line5: 20.87,
      },
      {
        name: 2014,
        line1: 31.33,
        line2: 31.09,
        line3: 31.41,
        line4: 43.72,
        line5: 21.28,
      },
      {
        name: 2015,
        line1: 32.38,
        line2: 31.47,
        line3: 31.82,
        line4: 45.55,
        line5: 21.59,
      },
      {
        name: 2016,
        line1: 33.24,
        line2: 31.59,
        line3: 32.22,
        line4: 46.96,
        line5: 22.18,
      },
      {
        name: 2017,
        line1: 34.25,
        line2: 31.75,
        line3: 32.64,
        line4: 48.13,
        line5: 22.87,
      },
      {
        name: 2018,
        line1: 35.12,
        line2: 31.93,
        line3: 33.09,
        line4: 49.1,
        line5: 23.44,
      },
      {
        name: 2019,
        line1: 35.97,
        line2: 32.13,
        line3: 33.62,
        line4: 49.92,
        line5: 24.12,
      },
      {
        name: 2020,
        line1: 36.76,
        line2: 32.36,
        line3: 34.17,
        line4: 50.57,
        line5: 24.86,
      },
      {
        name: 2021,
        line1: 37.43,
        line2: 32.62,
        line3: 34.66,
        line4: 50.97,
        line5: 25.63,
      },
    ],
  },
  {
    name: "kuvio-4.7",
    height: 450,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    yDomain: [-5, 20],
    yAxisOffset: 0,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 45,
    yAxisWidth: 55,
    margins: [0, 25, 15, 5],
    tickDx: 0,
    type: ["category", "number"],
    manualTicksX: [
      "1920",
      "1930",
      "1940",
      "1950",
      "1960",
      "1970",
      "1980",
      "1990",
      "2000",
      "2010",
      "2020",
    ],
    manualTicksY: [-5, 0, 5, 10, 15, 20],
    fontSize: "1rem",
    names: [
      "Suomen Pankin peruskorko",
      "Suomen valtion 10v obligaatiokorko",
      "Asuntolainakannan keskikorko",
    ],
    data: [
      {
        name: "1920",
        line1: 7,
      },
      {
        name: "1920M2",
        line1: 7,
      },
      {
        name: "1920M3",
        line1: 8,
      },
      {
        name: "1920M4",
        line1: 8,
      },
      {
        name: "1920M5",
        line1: 8,
      },
      {
        name: "1920M6",
        line1: 8,
      },
      {
        name: "1920M7",
        line1: 8,
      },
      {
        name: "1920M8",
        line1: 8,
      },
      {
        name: "1920M9",
        line1: 8,
      },
      {
        name: "1920M10",
        line1: 8,
      },
      {
        name: "1920M11",
        line1: 9,
      },
      {
        name: "1920M12",
        line1: 9,
      },
      {
        name: "1921M1",
        line1: 9,
      },
      {
        name: "1921M2",
        line1: 9,
      },
      {
        name: "1921M3",
        line1: 9,
      },
      {
        name: "1921M4",
        line1: 9,
      },
      {
        name: "1921M5",
        line1: 9,
      },
      {
        name: "1921M6",
        line1: 9,
      },
      {
        name: "1921M7",
        line1: 9,
      },
      {
        name: "1921M8",
        line1: 9,
      },
      {
        name: "1921M9",
        line1: 9,
      },
      {
        name: "1921M10",
        line1: 9,
      },
      {
        name: "1921M11",
        line1: 9,
      },
      {
        name: "1921M12",
        line1: 9,
      },
      {
        name: "1922M1",
        line1: 9,
      },
      {
        name: "1922M2",
        line1: 9,
      },
      {
        name: "1922M3",
        line1: 9,
      },
      {
        name: "1922M4",
        line1: 9,
      },
      {
        name: "1922M5",
        line1: 9,
      },
      {
        name: "1922M6",
        line1: 9,
      },
      {
        name: "1922M7",
        line1: 9,
      },
      {
        name: "1922M8",
        line1: 9,
      },
      {
        name: "1922M9",
        line1: 9,
      },
      {
        name: "1922M10",
        line1: 8,
      },
      {
        name: "1922M11",
        line1: 8,
      },
      {
        name: "1922M12",
        line1: 8,
      },
      {
        name: "1923M1",
        line1: 8,
      },
      {
        name: "1923M2",
        line1: 8,
      },
      {
        name: "1923M3",
        line1: 8,
      },
      {
        name: "1923M4",
        line1: 8,
      },
      {
        name: "1923M5",
        line1: 8,
      },
      {
        name: "1923M6",
        line1: 8,
      },
      {
        name: "1923M7",
        line1: 8,
      },
      {
        name: "1923M8",
        line1: 8,
      },
      {
        name: "1923M9",
        line1: 9,
      },
      {
        name: "1923M10",
        line1: 8,
      },
      {
        name: "1923M11",
        line1: 10,
      },
      {
        name: "1923M12",
        line1: 10,
      },
      {
        name: "1924M1",
        line1: 10,
      },
      {
        name: "1924M2",
        line1: 10,
      },
      {
        name: "1924M3",
        line1: 9,
      },
      {
        name: "1924M4",
        line1: 9,
      },
      {
        name: "1924M5",
        line1: 9,
      },
      {
        name: "1924M6",
        line1: 9,
      },
      {
        name: "1924M7",
        line1: 9,
      },
      {
        name: "1924M8",
        line1: 9,
      },
      {
        name: "1924M9",
        line1: 9,
      },
      {
        name: "1924M10",
        line1: 9,
      },
      {
        name: "1924M11",
        line1: 9,
      },
      {
        name: "1924M12",
        line1: 9,
      },
      {
        name: "1925M1",
        line1: 9,
      },
      {
        name: "1925M2",
        line1: 9,
      },
      {
        name: "1925M3",
        line1: 9,
      },
      {
        name: "1925M4",
        line1: 9,
      },
      {
        name: "1925M5",
        line1: 9,
      },
      {
        name: "1925M6",
        line1: 9,
      },
      {
        name: "1925M7",
        line1: 9,
      },
      {
        name: "1925M8",
        line1: 8,
      },
      {
        name: "1925M9",
        line1: 8,
      },
      {
        name: "1925M10",
        line1: 7.5,
      },
      {
        name: "1925M11",
        line1: 7.5,
      },
      {
        name: "1925M12",
        line1: 7.5,
      },
      {
        name: "1926M1",
        line1: 7.5,
      },
      {
        name: "1926M2",
        line1: 7.5,
      },
      {
        name: "1926M3",
        line1: 7.5,
      },
      {
        name: "1926M4",
        line1: 7.5,
      },
      {
        name: "1926M5",
        line1: 7.5,
      },
      {
        name: "1926M6",
        line1: 7.5,
      },
      {
        name: "1926M7",
        line1: 7.5,
      },
      {
        name: "1926M8",
        line1: 7.5,
      },
      {
        name: "1926M9",
        line1: 7.5,
      },
      {
        name: "1926M10",
        line1: 7.5,
      },
      {
        name: "1926M11",
        line1: 7.5,
      },
      {
        name: "1926M12",
        line1: 7.5,
      },
      {
        name: "1927M1",
        line1: 7.5,
      },
      {
        name: "1927M2",
        line1: 7.5,
      },
      {
        name: "1927M3",
        line1: 7,
      },
      {
        name: "1927M4",
        line1: 7,
      },
      {
        name: "1927M5",
        line1: 7,
      },
      {
        name: "1927M6",
        line1: 7,
      },
      {
        name: "1927M7",
        line1: 7,
      },
      {
        name: "1927M8",
        line1: 6.5,
      },
      {
        name: "1927M9",
        line1: 6.5,
      },
      {
        name: "1927M10",
        line1: 6.5,
      },
      {
        name: "1927M11",
        line1: 6,
      },
      {
        name: "1927M12",
        line1: 6,
      },
      {
        name: "1928M1",
        line1: 6,
      },
      {
        name: "1928M2",
        line1: 6,
      },
      {
        name: "1928M3",
        line1: 6,
      },
      {
        name: "1928M4",
        line1: 6,
      },
      {
        name: "1928M5",
        line1: 6,
      },
      {
        name: "1928M6",
        line1: 6,
      },
      {
        name: "1928M7",
        line1: 6,
      },
      {
        name: "1928M8",
        line1: 6.5,
      },
      {
        name: "1928M9",
        line1: 6.5,
      },
      {
        name: "1928M10",
        line1: 6.5,
      },
      {
        name: "1928M11",
        line1: 7,
      },
      {
        name: "1928M12",
        line1: 7,
      },
      {
        name: "1929M1",
        line1: 7,
      },
      {
        name: "1929M2",
        line1: 7,
      },
      {
        name: "1929M3",
        line1: 7,
      },
      {
        name: "1929M4",
        line1: 7,
      },
      {
        name: "1929M5",
        line1: 7,
      },
      {
        name: "1929M6",
        line1: 7,
      },
      {
        name: "1929M7",
        line1: 7,
      },
      {
        name: "1929M8",
        line1: 7,
      },
      {
        name: "1929M9",
        line1: 7,
      },
      {
        name: "1929M10",
        line1: 7,
      },
      {
        name: "1929M11",
        line1: 7,
      },
      {
        name: "1929M12",
        line1: 7,
      },
      {
        name: "1930",
        line1: 7,
      },
      {
        name: "1930M2",
        line1: 7,
      },
      {
        name: "1930M3",
        line1: 7,
      },
      {
        name: "1930M4",
        line1: 6.5,
      },
      {
        name: "1930M5",
        line1: 6.5,
      },
      {
        name: "1930M6",
        line1: 6.5,
      },
      {
        name: "1930M7",
        line1: 6.5,
      },
      {
        name: "1930M8",
        line1: 6,
      },
      {
        name: "1930M9",
        line1: 6,
      },
      {
        name: "1930M10",
        line1: 6,
      },
      {
        name: "1930M11",
        line1: 6,
      },
      {
        name: "1930M12",
        line1: 6,
      },
      {
        name: "1931M1",
        line1: 6,
      },
      {
        name: "1931M2",
        line1: 6,
      },
      {
        name: "1931M3",
        line1: 6,
      },
      {
        name: "1931M4",
        line1: 6,
      },
      {
        name: "1931M5",
        line1: 6,
      },
      {
        name: "1931M6",
        line1: 6,
      },
      {
        name: "1931M7",
        line1: 6,
      },
      {
        name: "1931M8",
        line1: 6,
      },
      {
        name: "1931M9",
        line1: 6,
      },
      {
        name: "1931M10",
        line1: 8,
      },
      {
        name: "1931M11",
        line1: 8,
      },
      {
        name: "1931M12",
        line1: 8,
      },
      {
        name: "1932M1",
        line1: 8,
      },
      {
        name: "1932M2",
        line1: 7,
      },
      {
        name: "1932M3",
        line1: 7,
      },
      {
        name: "1932M4",
        line1: 6.5,
      },
      {
        name: "1932M5",
        line1: 6.5,
      },
      {
        name: "1932M6",
        line1: 6.5,
      },
      {
        name: "1932M7",
        line1: 6.5,
      },
      {
        name: "1932M8",
        line1: 6.5,
      },
      {
        name: "1932M9",
        line1: 6.5,
      },
      {
        name: "1932M10",
        line1: 6.5,
      },
      {
        name: "1932M11",
        line1: 6.5,
      },
      {
        name: "1932M12",
        line1: 6.5,
      },
      {
        name: "1933M1",
        line1: 6.5,
      },
      {
        name: "1933M2",
        line1: 6,
      },
      {
        name: "1933M3",
        line1: 6,
      },
      {
        name: "1933M4",
        line1: 6,
      },
      {
        name: "1933M5",
        line1: 6,
      },
      {
        name: "1933M6",
        line1: 5.5,
      },
      {
        name: "1933M7",
        line1: 5.5,
      },
      {
        name: "1933M8",
        line1: 5.5,
      },
      {
        name: "1933M9",
        line1: 5,
      },
      {
        name: "1933M10",
        line1: 5,
      },
      {
        name: "1933M11",
        line1: 5,
      },
      {
        name: "1933M12",
        line1: 4.5,
      },
      {
        name: "1934M1",
        line1: 4.5,
      },
      {
        name: "1934M2",
        line1: 4.5,
      },
      {
        name: "1934M3",
        line1: 4.5,
      },
      {
        name: "1934M4",
        line1: 4.5,
      },
      {
        name: "1934M5",
        line1: 4.5,
      },
      {
        name: "1934M6",
        line1: 4.5,
      },
      {
        name: "1934M7",
        line1: 4.5,
      },
      {
        name: "1934M8",
        line1: 4.5,
      },
      {
        name: "1934M9",
        line1: 4.5,
      },
      {
        name: "1934M10",
        line1: 4.5,
      },
      {
        name: "1934M11",
        line1: 4.5,
      },
      {
        name: "1934M12",
        line1: 4,
      },
      {
        name: "1935M1",
        line1: 4,
      },
      {
        name: "1935M2",
        line1: 4,
      },
      {
        name: "1935M3",
        line1: 4,
      },
      {
        name: "1935M4",
        line1: 4,
      },
      {
        name: "1935M5",
        line1: 4,
      },
      {
        name: "1935M6",
        line1: 4,
      },
      {
        name: "1935M7",
        line1: 4,
      },
      {
        name: "1935M8",
        line1: 4,
      },
      {
        name: "1935M9",
        line1: 4,
      },
      {
        name: "1935M10",
        line1: 4,
      },
      {
        name: "1935M11",
        line1: 4,
      },
      {
        name: "1935M12",
        line1: 4,
      },
      {
        name: "1936M1",
        line1: 4,
      },
      {
        name: "1936M2",
        line1: 4,
      },
      {
        name: "1936M3",
        line1: 4,
      },
      {
        name: "1936M4",
        line1: 4,
      },
      {
        name: "1936M5",
        line1: 4,
      },
      {
        name: "1936M6",
        line1: 4,
      },
      {
        name: "1936M7",
        line1: 4,
      },
      {
        name: "1936M8",
        line1: 4,
      },
      {
        name: "1936M9",
        line1: 4,
      },
      {
        name: "1936M10",
        line1: 4,
      },
      {
        name: "1936M11",
        line1: 4,
      },
      {
        name: "1936M12",
        line1: 4,
      },
      {
        name: "1937M1",
        line1: 4,
      },
      {
        name: "1937M2",
        line1: 4,
      },
      {
        name: "1937M3",
        line1: 4,
      },
      {
        name: "1937M4",
        line1: 4,
      },
      {
        name: "1937M5",
        line1: 4,
      },
      {
        name: "1937M6",
        line1: 4,
      },
      {
        name: "1937M7",
        line1: 4,
      },
      {
        name: "1937M8",
        line1: 4,
      },
      {
        name: "1937M9",
        line1: 4,
      },
      {
        name: "1937M10",
        line1: 4,
      },
      {
        name: "1937M11",
        line1: 4,
      },
      {
        name: "1937M12",
        line1: 4,
      },
      {
        name: "1938M1",
        line1: 4,
      },
      {
        name: "1938M2",
        line1: 4,
      },
      {
        name: "1938M3",
        line1: 4,
      },
      {
        name: "1938M4",
        line1: 4,
      },
      {
        name: "1938M5",
        line1: 4,
      },
      {
        name: "1938M6",
        line1: 4,
      },
      {
        name: "1938M7",
        line1: 4,
      },
      {
        name: "1938M8",
        line1: 4,
      },
      {
        name: "1938M9",
        line1: 4,
      },
      {
        name: "1938M10",
        line1: 4,
      },
      {
        name: "1938M11",
        line1: 4,
      },
      {
        name: "1938M12",
        line1: 4,
      },
      {
        name: "1939M1",
        line1: 4,
      },
      {
        name: "1939M2",
        line1: 4,
      },
      {
        name: "1939M3",
        line1: 4,
      },
      {
        name: "1939M4",
        line1: 4,
      },
      {
        name: "1939M5",
        line1: 4,
      },
      {
        name: "1939M6",
        line1: 4,
      },
      {
        name: "1939M7",
        line1: 4,
      },
      {
        name: "1939M8",
        line1: 4,
      },
      {
        name: "1939M9",
        line1: 4,
      },
      {
        name: "1939M10",
        line1: 4,
      },
      {
        name: "1939M11",
        line1: 4,
      },
      {
        name: "1939M12",
        line1: 4,
      },
      {
        name: "1940",
        line1: 4,
      },
      {
        name: "1940M2",
        line1: 4,
      },
      {
        name: "1940M3",
        line1: 4,
      },
      {
        name: "1940M4",
        line1: 4,
      },
      {
        name: "1940M5",
        line1: 4,
      },
      {
        name: "1940M6",
        line1: 4,
      },
      {
        name: "1940M7",
        line1: 4,
      },
      {
        name: "1940M8",
        line1: 4,
      },
      {
        name: "1940M9",
        line1: 4,
      },
      {
        name: "1940M10",
        line1: 4,
      },
      {
        name: "1940M11",
        line1: 4,
      },
      {
        name: "1940M12",
        line1: 4,
      },
      {
        name: "1941M1",
        line1: 4,
      },
      {
        name: "1941M2",
        line1: 4,
      },
      {
        name: "1941M3",
        line1: 4,
      },
      {
        name: "1941M4",
        line1: 4,
      },
      {
        name: "1941M5",
        line1: 4,
      },
      {
        name: "1941M6",
        line1: 4,
      },
      {
        name: "1941M7",
        line1: 4,
      },
      {
        name: "1941M8",
        line1: 4,
      },
      {
        name: "1941M9",
        line1: 4,
      },
      {
        name: "1941M10",
        line1: 4,
      },
      {
        name: "1941M11",
        line1: 4,
      },
      {
        name: "1941M12",
        line1: 4,
      },
      {
        name: "1942M1",
        line1: 4,
      },
      {
        name: "1942M2",
        line1: 4,
      },
      {
        name: "1942M3",
        line1: 4,
      },
      {
        name: "1942M4",
        line1: 4,
      },
      {
        name: "1942M5",
        line1: 4,
      },
      {
        name: "1942M6",
        line1: 4,
      },
      {
        name: "1942M7",
        line1: 4,
      },
      {
        name: "1942M8",
        line1: 4,
      },
      {
        name: "1942M9",
        line1: 4,
      },
      {
        name: "1942M10",
        line1: 4,
      },
      {
        name: "1942M11",
        line1: 4,
      },
      {
        name: "1942M12",
        line1: 4,
      },
      {
        name: "1943M1",
        line1: 4,
      },
      {
        name: "1943M2",
        line1: 4,
      },
      {
        name: "1943M3",
        line1: 4,
      },
      {
        name: "1943M4",
        line1: 4,
      },
      {
        name: "1943M5",
        line1: 4,
      },
      {
        name: "1943M6",
        line1: 4,
      },
      {
        name: "1943M7",
        line1: 4,
      },
      {
        name: "1943M8",
        line1: 4,
      },
      {
        name: "1943M9",
        line1: 4,
      },
      {
        name: "1943M10",
        line1: 4,
      },
      {
        name: "1943M11",
        line1: 4,
      },
      {
        name: "1943M12",
        line1: 4,
      },
      {
        name: "1944M1",
        line1: 4,
      },
      {
        name: "1944M2",
        line1: 4,
      },
      {
        name: "1944M3",
        line1: 4,
      },
      {
        name: "1944M4",
        line1: 4,
      },
      {
        name: "1944M5",
        line1: 4,
      },
      {
        name: "1944M6",
        line1: 4,
      },
      {
        name: "1944M7",
        line1: 4,
      },
      {
        name: "1944M8",
        line1: 4,
      },
      {
        name: "1944M9",
        line1: 4,
      },
      {
        name: "1944M10",
        line1: 4,
      },
      {
        name: "1944M11",
        line1: 4,
      },
      {
        name: "1944M12",
        line1: 4,
      },
      {
        name: "1945M1",
        line1: 4,
      },
      {
        name: "1945M2",
        line1: 4,
      },
      {
        name: "1945M3",
        line1: 4,
      },
      {
        name: "1945M4",
        line1: 4,
      },
      {
        name: "1945M5",
        line1: 4,
      },
      {
        name: "1945M6",
        line1: 4,
      },
      {
        name: "1945M7",
        line1: 4,
      },
      {
        name: "1945M8",
        line1: 4,
      },
      {
        name: "1945M9",
        line1: 4,
      },
      {
        name: "1945M10",
        line1: 4,
      },
      {
        name: "1945M11",
        line1: 4,
      },
      {
        name: "1945M12",
        line1: 4,
      },
      {
        name: "1946M1",
        line1: 4,
      },
      {
        name: "1946M2",
        line1: 4,
      },
      {
        name: "1946M3",
        line1: 4,
      },
      {
        name: "1946M4",
        line1: 4,
      },
      {
        name: "1946M5",
        line1: 4,
      },
      {
        name: "1946M6",
        line1: 4,
      },
      {
        name: "1946M7",
        line1: 4,
      },
      {
        name: "1946M8",
        line1: 4,
      },
      {
        name: "1946M9",
        line1: 4,
      },
      {
        name: "1946M10",
        line1: 4,
      },
      {
        name: "1946M11",
        line1: 4,
      },
      {
        name: "1946M12",
        line1: 4,
      },
      {
        name: "1947M1",
        line1: 4,
      },
      {
        name: "1947M2",
        line1: 4,
      },
      {
        name: "1947M3",
        line1: 4,
      },
      {
        name: "1947M4",
        line1: 4,
      },
      {
        name: "1947M5",
        line1: 4,
      },
      {
        name: "1947M6",
        line1: 4.5,
      },
      {
        name: "1947M7",
        line1: 4.5,
      },
      {
        name: "1947M8",
        line1: 4.5,
      },
      {
        name: "1947M9",
        line1: 4.5,
      },
      {
        name: "1947M10",
        line1: 4.5,
      },
      {
        name: "1947M11",
        line1: 4.5,
      },
      {
        name: "1947M12",
        line1: 5.25,
      },
      {
        name: "1948M1",
        line1: 5.25,
      },
      {
        name: "1948M2",
        line1: 7.25,
      },
      {
        name: "1948M3",
        line1: 7.25,
      },
      {
        name: "1948M4",
        line1: 7.25,
      },
      {
        name: "1948M5",
        line1: 7.25,
      },
      {
        name: "1948M6",
        line1: 7.25,
      },
      {
        name: "1948M7",
        line1: 7.25,
      },
      {
        name: "1948M8",
        line1: 7.25,
      },
      {
        name: "1948M9",
        line1: 7.25,
      },
      {
        name: "1948M10",
        line1: 7.25,
      },
      {
        name: "1948M11",
        line1: 7.25,
      },
      {
        name: "1948M12",
        line1: 7.25,
      },
      {
        name: "1949M1",
        line1: 7.25,
      },
      {
        name: "1949M2",
        line1: 6.75,
      },
      {
        name: "1949M3",
        line1: 6.75,
      },
      {
        name: "1949M4",
        line1: 6.75,
      },
      {
        name: "1949M5",
        line1: 6.75,
      },
      {
        name: "1949M6",
        line1: 6.75,
      },
      {
        name: "1949M7",
        line1: 5.75,
      },
      {
        name: "1949M8",
        line1: 5.75,
      },
      {
        name: "1949M9",
        line1: 5.75,
      },
      {
        name: "1949M10",
        line1: 5.75,
      },
      {
        name: "1949M11",
        line1: 5.75,
      },
      {
        name: "1949M12",
        line1: 5.75,
      },
      {
        name: "1950",
        line1: 5.75,
      },
      {
        name: "1950M2",
        line1: 5.75,
      },
      {
        name: "1950M3",
        line1: 5.75,
      },
      {
        name: "1950M4",
        line1: 5.75,
      },
      {
        name: "1950M5",
        line1: 5.75,
      },
      {
        name: "1950M6",
        line1: 5.75,
      },
      {
        name: "1950M7",
        line1: 5.75,
      },
      {
        name: "1950M8",
        line1: 5.75,
      },
      {
        name: "1950M9",
        line1: 5.75,
      },
      {
        name: "1950M10",
        line1: 5.75,
      },
      {
        name: "1950M11",
        line1: 7.75,
      },
      {
        name: "1950M12",
        line1: 7.75,
      },
      {
        name: "1951M1",
        line1: 7.75,
      },
      {
        name: "1951M2",
        line1: 7.75,
      },
      {
        name: "1951M3",
        line1: 7.75,
      },
      {
        name: "1951M4",
        line1: 7.75,
      },
      {
        name: "1951M5",
        line1: 7.75,
      },
      {
        name: "1951M6",
        line1: 7.75,
      },
      {
        name: "1951M7",
        line1: 7.75,
      },
      {
        name: "1951M8",
        line1: 7.75,
      },
      {
        name: "1951M9",
        line1: 7.75,
      },
      {
        name: "1951M10",
        line1: 7.75,
      },
      {
        name: "1951M11",
        line1: 7.75,
      },
      {
        name: "1951M12",
        line1: 5.75,
      },
      {
        name: "1952M1",
        line1: 5.75,
      },
      {
        name: "1952M2",
        line1: 5.75,
      },
      {
        name: "1952M3",
        line1: 5.75,
      },
      {
        name: "1952M4",
        line1: 5.75,
      },
      {
        name: "1952M5",
        line1: 5.75,
      },
      {
        name: "1952M6",
        line1: 5.75,
      },
      {
        name: "1952M7",
        line1: 5.75,
      },
      {
        name: "1952M8",
        line1: 5.75,
      },
      {
        name: "1952M9",
        line1: 5.75,
      },
      {
        name: "1952M10",
        line1: 5.75,
      },
      {
        name: "1952M11",
        line1: 5.75,
      },
      {
        name: "1952M12",
        line1: 5.75,
      },
      {
        name: "1953M1",
        line1: 5.75,
      },
      {
        name: "1953M2",
        line1: 5.75,
      },
      {
        name: "1953M3",
        line1: 5.75,
      },
      {
        name: "1953M4",
        line1: 5.75,
      },
      {
        name: "1953M5",
        line1: 5.75,
      },
      {
        name: "1953M6",
        line1: 5.75,
      },
      {
        name: "1953M7",
        line1: 5.75,
      },
      {
        name: "1953M8",
        line1: 5.75,
      },
      {
        name: "1953M9",
        line1: 5.75,
      },
      {
        name: "1953M10",
        line1: 5.75,
      },
      {
        name: "1953M11",
        line1: 5.75,
      },
      {
        name: "1953M12",
        line1: 5.75,
      },
      {
        name: "1954M1",
        line1: 5.75,
      },
      {
        name: "1954M2",
        line1: 5.75,
      },
      {
        name: "1954M3",
        line1: 5.75,
      },
      {
        name: "1954M4",
        line1: 5.75,
      },
      {
        name: "1954M5",
        line1: 5.75,
      },
      {
        name: "1954M6",
        line1: 5.75,
      },
      {
        name: "1954M7",
        line1: 5.75,
      },
      {
        name: "1954M8",
        line1: 5.75,
      },
      {
        name: "1954M9",
        line1: 5.75,
      },
      {
        name: "1954M10",
        line1: 5.75,
      },
      {
        name: "1954M11",
        line1: 5.75,
      },
      {
        name: "1954M12",
        line1: 5,
      },
      {
        name: "1955M1",
        line1: 5,
      },
      {
        name: "1955M2",
        line1: 5,
      },
      {
        name: "1955M3",
        line1: 5,
      },
      {
        name: "1955M4",
        line1: 5,
      },
      {
        name: "1955M5",
        line1: 5,
      },
      {
        name: "1955M6",
        line1: 5,
      },
      {
        name: "1955M7",
        line1: 5,
      },
      {
        name: "1955M8",
        line1: 5,
      },
      {
        name: "1955M9",
        line1: 5,
      },
      {
        name: "1955M10",
        line1: 5,
      },
      {
        name: "1955M11",
        line1: 5,
      },
      {
        name: "1955M12",
        line1: 5,
      },
      {
        name: "1956M1",
        line1: 5,
      },
      {
        name: "1956M2",
        line1: 5,
      },
      {
        name: "1956M3",
        line1: 5,
      },
      {
        name: "1956M4",
        line1: 6.5,
      },
      {
        name: "1956M5",
        line1: 6.5,
      },
      {
        name: "1956M6",
        line1: 6.5,
      },
      {
        name: "1956M7",
        line1: 6.5,
      },
      {
        name: "1956M8",
        line1: 6.5,
      },
      {
        name: "1956M9",
        line1: 6.5,
      },
      {
        name: "1956M10",
        line1: 6.5,
      },
      {
        name: "1956M11",
        line1: 6.5,
      },
      {
        name: "1956M12",
        line1: 6.5,
      },
      {
        name: "1957M1",
        line1: 6.5,
      },
      {
        name: "1957M2",
        line1: 6.5,
      },
      {
        name: "1957M3",
        line1: 6.5,
      },
      {
        name: "1957M4",
        line1: 6.5,
      },
      {
        name: "1957M5",
        line1: 6.5,
      },
      {
        name: "1957M6",
        line1: 6.5,
      },
      {
        name: "1957M7",
        line1: 6.5,
      },
      {
        name: "1957M8",
        line1: 6.5,
      },
      {
        name: "1957M9",
        line1: 6.5,
      },
      {
        name: "1957M10",
        line1: 6.5,
      },
      {
        name: "1957M11",
        line1: 6.5,
      },
      {
        name: "1957M12",
        line1: 6.5,
      },
      {
        name: "1958M1",
        line1: 6.5,
      },
      {
        name: "1958M2",
        line1: 6.5,
      },
      {
        name: "1958M3",
        line1: 6.5,
      },
      {
        name: "1958M4",
        line1: 6.5,
      },
      {
        name: "1958M5",
        line1: 6.5,
      },
      {
        name: "1958M6",
        line1: 6.5,
      },
      {
        name: "1958M7",
        line1: 6.5,
      },
      {
        name: "1958M8",
        line1: 6.5,
      },
      {
        name: "1958M9",
        line1: 6.5,
      },
      {
        name: "1958M10",
        line1: 7.25,
      },
      {
        name: "1958M11",
        line1: 7.25,
      },
      {
        name: "1958M12",
        line1: 7.25,
      },
      {
        name: "1959M1",
        line1: 7.25,
      },
      {
        name: "1959M2",
        line1: 7.25,
      },
      {
        name: "1959M3",
        line1: 7.25,
      },
      {
        name: "1959M4",
        line1: 6.75,
      },
      {
        name: "1959M5",
        line1: 6.75,
      },
      {
        name: "1959M6",
        line1: 6.75,
      },
      {
        name: "1959M7",
        line1: 6.75,
      },
      {
        name: "1959M8",
        line1: 6.75,
      },
      {
        name: "1959M9",
        line1: 6.75,
      },
      {
        name: "1959M10",
        line1: 6.75,
      },
      {
        name: "1959M11",
        line1: 6.75,
      },
      {
        name: "1959M12",
        line1: 6.75,
      },
      {
        name: "1960",
        line1: 6.75,
      },
      {
        name: "1960M2",
        line1: 6.75,
      },
      {
        name: "1960M3",
        line1: 6.75,
      },
      {
        name: "1960M4",
        line1: 6.75,
      },
      {
        name: "1960M5",
        line1: 6.75,
      },
      {
        name: "1960M6",
        line1: 6.75,
      },
      {
        name: "1960M7",
        line1: 6.75,
      },
      {
        name: "1960M8",
        line1: 6.75,
      },
      {
        name: "1960M9",
        line1: 6.75,
      },
      {
        name: "1960M10",
        line1: 6.75,
      },
      {
        name: "1960M11",
        line1: 6.75,
      },
      {
        name: "1960M12",
        line1: 6.75,
      },
      {
        name: "1961M1",
        line1: 6.75,
      },
      {
        name: "1961M2",
        line1: 6.75,
      },
      {
        name: "1961M3",
        line1: 6.75,
      },
      {
        name: "1961M4",
        line1: 6.75,
      },
      {
        name: "1961M5",
        line1: 6.75,
      },
      {
        name: "1961M6",
        line1: 6.75,
      },
      {
        name: "1961M7",
        line1: 6.75,
      },
      {
        name: "1961M8",
        line1: 6.75,
      },
      {
        name: "1961M9",
        line1: 6.75,
      },
      {
        name: "1961M10",
        line1: 6.75,
      },
      {
        name: "1961M11",
        line1: 6.75,
      },
      {
        name: "1961M12",
        line1: 6.75,
      },
      {
        name: "1962M1",
        line1: 6.75,
      },
      {
        name: "1962M2",
        line1: 6.75,
      },
      {
        name: "1962M3",
        line1: 8,
      },
      {
        name: "1962M4",
        line1: 7,
      },
      {
        name: "1962M5",
        line1: 7,
      },
      {
        name: "1962M6",
        line1: 7,
      },
      {
        name: "1962M7",
        line1: 7,
      },
      {
        name: "1962M8",
        line1: 7,
      },
      {
        name: "1962M9",
        line1: 7,
      },
      {
        name: "1962M10",
        line1: 7,
      },
      {
        name: "1962M11",
        line1: 7,
      },
      {
        name: "1962M12",
        line1: 7,
      },
      {
        name: "1963M1",
        line1: 7,
      },
      {
        name: "1963M2",
        line1: 7,
      },
      {
        name: "1963M3",
        line1: 7,
      },
      {
        name: "1963M4",
        line1: 7,
      },
      {
        name: "1963M5",
        line1: 7,
      },
      {
        name: "1963M6",
        line1: 7,
      },
      {
        name: "1963M7",
        line1: 7,
      },
      {
        name: "1963M8",
        line1: 7,
      },
      {
        name: "1963M9",
        line1: 7,
      },
      {
        name: "1963M10",
        line1: 7,
      },
      {
        name: "1963M11",
        line1: 7,
      },
      {
        name: "1963M12",
        line1: 7,
      },
      {
        name: "1964M1",
        line1: 7,
      },
      {
        name: "1964M2",
        line1: 7,
      },
      {
        name: "1964M3",
        line1: 7,
      },
      {
        name: "1964M4",
        line1: 7,
      },
      {
        name: "1964M5",
        line1: 7,
      },
      {
        name: "1964M6",
        line1: 7,
      },
      {
        name: "1964M7",
        line1: 7,
      },
      {
        name: "1964M8",
        line1: 7,
      },
      {
        name: "1964M9",
        line1: 7,
      },
      {
        name: "1964M10",
        line1: 7,
      },
      {
        name: "1964M11",
        line1: 7,
      },
      {
        name: "1964M12",
        line1: 7,
      },
      {
        name: "1965M1",
        line1: 7,
      },
      {
        name: "1965M2",
        line1: 7,
      },
      {
        name: "1965M3",
        line1: 7,
      },
      {
        name: "1965M4",
        line1: 7,
      },
      {
        name: "1965M5",
        line1: 7,
      },
      {
        name: "1965M6",
        line1: 7,
      },
      {
        name: "1965M7",
        line1: 7,
      },
      {
        name: "1965M8",
        line1: 7,
      },
      {
        name: "1965M9",
        line1: 7,
      },
      {
        name: "1965M10",
        line1: 7,
      },
      {
        name: "1965M11",
        line1: 7,
      },
      {
        name: "1965M12",
        line1: 7,
      },
      {
        name: "1966M1",
        line1: 7,
      },
      {
        name: "1966M2",
        line1: 7,
      },
      {
        name: "1966M3",
        line1: 7,
      },
      {
        name: "1966M4",
        line1: 7,
      },
      {
        name: "1966M5",
        line1: 7,
      },
      {
        name: "1966M6",
        line1: 7,
      },
      {
        name: "1966M7",
        line1: 7,
      },
      {
        name: "1966M8",
        line1: 7,
      },
      {
        name: "1966M9",
        line1: 7,
      },
      {
        name: "1966M10",
        line1: 7,
      },
      {
        name: "1966M11",
        line1: 7,
      },
      {
        name: "1966M12",
        line1: 7,
      },
      {
        name: "1967M1",
        line1: 7,
      },
      {
        name: "1967M2",
        line1: 7,
      },
      {
        name: "1967M3",
        line1: 7,
      },
      {
        name: "1967M4",
        line1: 7,
      },
      {
        name: "1967M5",
        line1: 7,
      },
      {
        name: "1967M6",
        line1: 7,
      },
      {
        name: "1967M7",
        line1: 7,
      },
      {
        name: "1967M8",
        line1: 7,
      },
      {
        name: "1967M9",
        line1: 7,
      },
      {
        name: "1967M10",
        line1: 7,
      },
      {
        name: "1967M11",
        line1: 7,
      },
      {
        name: "1967M12",
        line1: 7,
      },
      {
        name: "1968M1",
        line1: 7,
      },
      {
        name: "1968M2",
        line1: 7,
      },
      {
        name: "1968M3",
        line1: 7,
      },
      {
        name: "1968M4",
        line1: 7,
      },
      {
        name: "1968M5",
        line1: 7,
      },
      {
        name: "1968M6",
        line1: 7,
      },
      {
        name: "1968M7",
        line1: 7,
      },
      {
        name: "1968M8",
        line1: 7,
      },
      {
        name: "1968M9",
        line1: 7,
      },
      {
        name: "1968M10",
        line1: 7,
      },
      {
        name: "1968M11",
        line1: 7,
      },
      {
        name: "1968M12",
        line1: 7,
      },
      {
        name: "1969M1",
        line1: 7,
      },
      {
        name: "1969M2",
        line1: 7,
      },
      {
        name: "1969M3",
        line1: 7,
      },
      {
        name: "1969M4",
        line1: 7,
      },
      {
        name: "1969M5",
        line1: 7,
      },
      {
        name: "1969M6",
        line1: 7,
      },
      {
        name: "1969M7",
        line1: 7,
      },
      {
        name: "1969M8",
        line1: 7,
      },
      {
        name: "1969M9",
        line1: 7,
      },
      {
        name: "1969M10",
        line1: 7,
      },
      {
        name: "1969M11",
        line1: 7,
      },
      {
        name: "1969M12",
        line1: 7,
      },
      {
        name: "1970",
        line1: 7,
      },
      {
        name: "1970M2",
        line1: 7,
      },
      {
        name: "1970M3",
        line1: 7,
      },
      {
        name: "1970M4",
        line1: 7,
      },
      {
        name: "1970M5",
        line1: 7,
      },
      {
        name: "1970M6",
        line1: 7,
      },
      {
        name: "1970M7",
        line1: 7,
      },
      {
        name: "1970M8",
        line1: 7,
      },
      {
        name: "1970M9",
        line1: 7,
      },
      {
        name: "1970M10",
        line1: 7,
      },
      {
        name: "1970M11",
        line1: 7,
      },
      {
        name: "1970M12",
        line1: 7,
      },
      {
        name: "1971M1",
        line1: 7,
      },
      {
        name: "1971M2",
        line1: 7,
      },
      {
        name: "1971M3",
        line1: 7,
      },
      {
        name: "1971M4",
        line1: 7,
      },
      {
        name: "1971M5",
        line1: 7,
        line2: 9.9,
      },
      {
        name: "1971M6",
        line1: 8.5,
        line2: 10.9,
      },
      {
        name: "1971M7",
        line1: 8.5,
        line2: 10.4,
      },
      {
        name: "1971M8",
        line1: 8.5,
        line2: 10.4,
      },
      {
        name: "1971M9",
        line1: 8.5,
        line2: 11.8,
      },
      {
        name: "1971M10",
        line1: 8.5,
        line2: 12.1,
      },
      {
        name: "1971M11",
        line1: 8.5,
        line2: 11.5,
      },
      {
        name: "1971M12",
        line1: 8.5,
        line2: 10.8,
      },
      {
        name: "1972M1",
        line1: 7.75,
        line2: 10.6,
      },
      {
        name: "1972M2",
        line1: 7.75,
        line2: 11.4,
      },
      {
        name: "1972M3",
        line1: 7.75,
        line2: 10.9,
      },
      {
        name: "1972M4",
        line1: 7.75,
        line2: 10.2,
      },
      {
        name: "1972M5",
        line1: 7.75,
        line2: 10.6,
      },
      {
        name: "1972M6",
        line1: 7.75,
        line2: 10.9,
      },
      {
        name: "1972M7",
        line1: 7.75,
        line2: 11.6,
      },
      {
        name: "1972M8",
        line1: 7.75,
        line2: 11.1,
      },
      {
        name: "1972M9",
        line1: 7.75,
        line2: 10.9,
      },
      {
        name: "1972M10",
        line1: 7.75,
        line2: 11.3,
      },
      {
        name: "1972M11",
        line1: 7.75,
        line2: 11.2,
      },
      {
        name: "1972M12",
        line1: 7.75,
        line2: 10.8,
      },
      {
        name: "1973M1",
        line1: 7.75,
        line2: 11.7,
      },
      {
        name: "1973M2",
        line1: 7.75,
        line2: 11.5,
      },
      {
        name: "1973M3",
        line1: 7.75,
        line2: 11.8,
      },
      {
        name: "1973M4",
        line1: 7.75,
        line2: 11.4,
      },
      {
        name: "1973M5",
        line1: 7.75,
        line2: 11.3,
      },
      {
        name: "1973M6",
        line1: 7.75,
        line2: 11.4,
      },
      {
        name: "1973M7",
        line1: 9.25,
        line2: 11.4,
      },
      {
        name: "1973M8",
        line1: 9.25,
        line2: 12.4,
      },
      {
        name: "1973M9",
        line1: 9.25,
        line2: 12.6,
      },
      {
        name: "1973M10",
        line1: 9.25,
        line2: 13.4,
      },
      {
        name: "1973M11",
        line1: 9.25,
        line2: 13.5,
      },
      {
        name: "1973M12",
        line1: 9.25,
        line2: 13.9,
      },
      {
        name: "1974M1",
        line1: 9.25,
        line2: 13.7,
      },
      {
        name: "1974M2",
        line1: 9.25,
        line2: 14.3,
      },
      {
        name: "1974M3",
        line1: 9.25,
        line2: 14.6,
      },
      {
        name: "1974M4",
        line1: 9.25,
        line2: 14.5,
      },
      {
        name: "1974M5",
        line1: 9.25,
        line2: 15.5,
      },
      {
        name: "1974M6",
        line1: 9.25,
        line2: 16.6,
      },
      {
        name: "1974M7",
        line1: 9.25,
        line2: 16.8,
      },
      {
        name: "1974M8",
        line1: 9.25,
        line2: 16.8,
      },
      {
        name: "1974M9",
        line1: 9.25,
        line2: 17.1,
      },
      {
        name: "1974M10",
        line1: 9.25,
        line2: 17,
      },
      {
        name: "1974M11",
        line1: 9.25,
        line2: 17.1,
      },
      {
        name: "1974M12",
        line1: 9.25,
        line2: 14.8,
      },
      {
        name: "1975M1",
        line1: 9.25,
        line2: 14.8,
      },
      {
        name: "1975M2",
        line1: 9.25,
        line2: 14.7,
      },
      {
        name: "1975M3",
        line1: 9.25,
        line2: 14.9,
      },
      {
        name: "1975M4",
        line1: 9.25,
        line2: 16.3,
      },
      {
        name: "1975M5",
        line1: 9.25,
        line2: 17.3,
      },
      {
        name: "1975M6",
        line1: 9.25,
        line2: 16.5,
      },
      {
        name: "1975M7",
        line1: 9.25,
        line2: 15.5,
      },
      {
        name: "1975M8",
        line1: 9.25,
        line2: 17,
      },
      {
        name: "1975M9",
        line1: 9.25,
        line2: 16.8,
      },
      {
        name: "1975M10",
        line1: 9.25,
        line2: 18.6,
      },
      {
        name: "1975M11",
        line1: 9.25,
        line2: 16.9,
      },
      {
        name: "1975M12",
        line1: 9.25,
        line2: 16.5,
      },
      {
        name: "1976M1",
        line1: 9.25,
        line2: 15.8,
      },
      {
        name: "1976M2",
        line1: 9.25,
        line2: 14.1,
      },
      {
        name: "1976M3",
        line1: 9.25,
        line2: 16.6,
      },
      {
        name: "1976M4",
        line1: 9.25,
        line2: 15.4,
      },
      {
        name: "1976M5",
        line1: 9.25,
        line2: 15.4,
      },
      {
        name: "1976M6",
        line1: 9.25,
        line2: 15.5,
      },
      {
        name: "1976M7",
        line1: 9.25,
        line2: 15,
      },
      {
        name: "1976M8",
        line1: 9.25,
        line2: 14.3,
      },
      {
        name: "1976M9",
        line1: 9.25,
        line2: 14.7,
      },
      {
        name: "1976M10",
        line1: 9.25,
        line2: 14.5,
      },
      {
        name: "1976M11",
        line1: 9.25,
        line2: 14.7,
      },
      {
        name: "1976M12",
        line1: 9.25,
        line2: 15.4,
      },
      {
        name: "1977M1",
        line1: 9.25,
        line2: 14.4,
      },
      {
        name: "1977M2",
        line1: 9.25,
        line2: 13.7,
      },
      {
        name: "1977M3",
        line1: 9.25,
        line2: 13.2,
      },
      {
        name: "1977M4",
        line1: 9.25,
        line2: 12.9,
      },
      {
        name: "1977M5",
        line1: 9.25,
        line2: 13.7,
      },
      {
        name: "1977M6",
        line1: 9.25,
        line2: 13.4,
      },
      {
        name: "1977M7",
        line1: 9.25,
        line2: 12.4,
      },
      {
        name: "1977M8",
        line1: 9.25,
        line2: 12.5,
      },
      {
        name: "1977M9",
        line1: 9.25,
        line2: 12.5,
      },
      {
        name: "1977M10",
        line1: 8.25,
        line2: 12.8,
      },
      {
        name: "1977M11",
        line1: 8.25,
        line2: 12.4,
      },
      {
        name: "1977M12",
        line1: 8.25,
        line2: 12.5,
      },
      {
        name: "1978M1",
        line1: 8.25,
        line2: 13.4,
      },
      {
        name: "1978M2",
        line1: 8.25,
        line2: 13.4,
      },
      {
        name: "1978M3",
        line1: 8.25,
        line2: 13.2,
      },
      {
        name: "1978M4",
        line1: 8.25,
        line2: 12.9,
      },
      {
        name: "1978M5",
        line1: 7.25,
        line2: 12.6,
      },
      {
        name: "1978M6",
        line1: 7.25,
        line2: 12,
      },
      {
        name: "1978M7",
        line1: 7.25,
        line2: 11.9,
      },
      {
        name: "1978M8",
        line1: 7.25,
        line2: 12.1,
      },
      {
        name: "1978M9",
        line1: 7.25,
        line2: 11.8,
      },
      {
        name: "1978M10",
        line1: 7.25,
        line2: 11.8,
      },
      {
        name: "1978M11",
        line1: 7.25,
        line2: 11.6,
      },
      {
        name: "1978M12",
        line1: 7.25,
        line2: 12.1,
      },
      {
        name: "1979M1",
        line1: 7.25,
        line2: 12.3,
      },
      {
        name: "1979M2",
        line1: 7.25,
        line2: 12,
      },
      {
        name: "1979M3",
        line1: 7.25,
        line2: 11.5,
      },
      {
        name: "1979M4",
        line1: 7.25,
        line2: 11.6,
      },
      {
        name: "1979M5",
        line1: 7.25,
        line2: 11.5,
      },
      {
        name: "1979M6",
        line1: 7.25,
        line2: 11.4,
      },
      {
        name: "1979M7",
        line1: 7.25,
        line2: 11.2,
      },
      {
        name: "1979M8",
        line1: 7.25,
        line2: 11,
      },
      {
        name: "1979M9",
        line1: 7.25,
        line2: 11.1,
      },
      {
        name: "1979M10",
        line1: 7.25,
        line2: 11.2,
      },
      {
        name: "1979M11",
        line1: 8.5,
        line2: 11.2,
      },
      {
        name: "1979M12",
        line1: 8.5,
        line2: 10.9,
      },
      {
        name: "1980",
        line1: 8.5,
        line2: 11.4,
      },
      {
        name: "1980M2",
        line1: 9.25,
        line2: 11.5,
      },
      {
        name: "1980M3",
        line1: 9.25,
        line2: 11.7,
      },
      {
        name: "1980M4",
        line1: 9.25,
        line2: 11.6,
      },
      {
        name: "1980M5",
        line1: 9.25,
        line2: 11.7,
      },
      {
        name: "1980M6",
        line1: 9.25,
        line2: 11.7,
      },
      {
        name: "1980M7",
        line1: 9.25,
        line2: 11.5,
      },
      {
        name: "1980M8",
        line1: 9.25,
        line2: 11.4,
      },
      {
        name: "1980M9",
        line1: 9.25,
        line2: 11.5,
      },
      {
        name: "1980M10",
        line1: 9.25,
        line2: 11.6,
      },
      {
        name: "1980M11",
        line1: 9.25,
        line2: 11.7,
      },
      {
        name: "1980M12",
        line1: 9.25,
        line2: 11.9,
      },
      {
        name: "1981M1",
        line1: 9.25,
        line2: 12.01,
      },
      {
        name: "1981M2",
        line1: 9.25,
        line2: 12.02,
      },
      {
        name: "1981M3",
        line1: 9.25,
        line2: 11.74,
      },
      {
        name: "1981M4",
        line1: 9.25,
        line2: 12.05,
      },
      {
        name: "1981M5",
        line1: 9.25,
        line2: 12.97,
      },
      {
        name: "1981M6",
        line1: 9.25,
        line2: 12.33,
      },
      {
        name: "1981M7",
        line1: 9.25,
        line2: 12.65,
      },
      {
        name: "1981M8",
        line1: 9.25,
        line2: 12.58,
      },
      {
        name: "1981M9",
        line1: 9.25,
        line2: 12.67,
      },
      {
        name: "1981M10",
        line1: 9.25,
        line2: 12.66,
      },
      {
        name: "1981M11",
        line1: 9.25,
        line2: 12.52,
      },
      {
        name: "1981M12",
        line1: 9.25,
        line2: 12.54,
      },
      {
        name: "1982M1",
        line1: 9.25,
        line2: 12.62,
      },
      {
        name: "1982M2",
        line1: 9.25,
        line2: 12.6,
      },
      {
        name: "1982M3",
        line1: 9.25,
        line2: 12.62,
      },
      {
        name: "1982M4",
        line1: 9.25,
        line2: 12.49,
      },
      {
        name: "1982M5",
        line1: 9.25,
        line2: 12.19,
      },
      {
        name: "1982M6",
        line1: 8.5,
        line2: 12.19,
      },
      {
        name: "1982M7",
        line1: 8.5,
        line2: 12.26,
      },
      {
        name: "1982M8",
        line1: 8.5,
        line2: 12.24,
      },
      {
        name: "1982M9",
        line1: 8.5,
        line2: 12.34,
      },
      {
        name: "1982M10",
        line1: 8.5,
        line2: 12.42,
      },
      {
        name: "1982M11",
        line1: 8.5,
        line2: 12.4,
      },
      {
        name: "1982M12",
        line1: 8.5,
        line2: 12.22,
      },
      {
        name: "1983M1",
        line1: 8.5,
        line2: 12.21,
      },
      {
        name: "1983M2",
        line1: 8.5,
        line2: 12.2,
      },
      {
        name: "1983M3",
        line1: 8.5,
        line2: 12.44,
      },
      {
        name: "1983M4",
        line1: 8.5,
        line2: 12.62,
      },
      {
        name: "1983M5",
        line1: 8.5,
        line2: 13.26,
      },
      {
        name: "1983M6",
        line1: 8.5,
        line2: 13.3,
      },
      {
        name: "1983M7",
        line1: 9.5,
        line2: 13.4,
      },
      {
        name: "1983M8",
        line1: 9.5,
        line2: 12.96,
      },
      {
        name: "1983M9",
        line1: 9.5,
        line2: 13.36,
      },
      {
        name: "1983M10",
        line1: 9.5,
        line2: 13.6,
      },
      {
        name: "1983M11",
        line1: 9.5,
        line2: 13.65,
      },
      {
        name: "1983M12",
        line1: 9.5,
        line2: 13.75,
      },
      {
        name: "1984M1",
        line1: 9.5,
        line2: 13.87,
      },
      {
        name: "1984M2",
        line1: 9.5,
        line2: 14.1,
      },
      {
        name: "1984M3",
        line1: 9.5,
        line2: 14.05,
      },
      {
        name: "1984M4",
        line1: 9.5,
        line2: 15.28,
      },
      {
        name: "1984M5",
        line1: 9.5,
        line2: 14.81,
      },
      {
        name: "1984M6",
        line1: 9.5,
        line2: 14.15,
      },
      {
        name: "1984M7",
        line1: 9.5,
        line2: 14.09,
      },
      {
        name: "1984M8",
        line1: 9.5,
        line2: 14.41,
      },
      {
        name: "1984M9",
        line1: 9.5,
        line2: 14.13,
      },
      {
        name: "1984M10",
        line1: 9.5,
        line2: 13.6,
      },
      {
        name: "1984M11",
        line1: 9.5,
        line2: 12.62,
      },
      {
        name: "1984M12",
        line1: 9.5,
        line2: 12.97,
      },
      {
        name: "1985M1",
        line1: 9.5,
        line2: 13.18,
      },
      {
        name: "1985M2",
        line1: 9,
        line2: 12.83,
      },
      {
        name: "1985M3",
        line1: 9,
        line2: 12.5,
      },
      {
        name: "1985M4",
        line1: 9,
        line2: 12.5,
      },
      {
        name: "1985M5",
        line1: 9,
        line2: 12.6,
      },
      {
        name: "1985M6",
        line1: 9,
        line2: 12.77,
      },
      {
        name: "1985M7",
        line1: 9,
        line2: 13.05,
      },
      {
        name: "1985M8",
        line1: 9,
        line2: 13.21,
      },
      {
        name: "1985M9",
        line1: 9,
        line2: 13.01,
      },
      {
        name: "1985M10",
        line1: 9,
        line2: 12.74,
      },
      {
        name: "1985M11",
        line1: 9,
        line2: 12.3,
      },
      {
        name: "1985M12",
        line1: 9,
        line2: 11.76,
      },
      {
        name: "1986M1",
        line1: 8.5,
        line2: 11.75,
      },
      {
        name: "1986M2",
        line1: 8.5,
        line2: 11.1,
      },
      {
        name: "1986M3",
        line1: 8,
        line2: 10.91,
      },
      {
        name: "1986M4",
        line1: 8,
        line2: 11.08,
      },
      {
        name: "1986M5",
        line1: 7,
        line2: 11.74,
      },
      {
        name: "1986M6",
        line1: 7,
        line2: 11.43,
      },
      {
        name: "1986M7",
        line1: 7,
        line2: 11.43,
      },
      {
        name: "1986M8",
        line1: 7,
        line2: 12.16,
      },
      {
        name: "1986M9",
        line1: 7,
        line2: 11.84,
      },
      {
        name: "1986M10",
        line1: 7,
        line2: 12.56,
      },
      {
        name: "1986M11",
        line1: 7,
        line2: 11.92,
      },
      {
        name: "1986M12",
        line1: 7,
        line2: 11.96,
      },
      {
        name: "1987M1",
        line1: 7,
        line2: 12.13,
      },
      {
        name: "1987M2",
        line1: 7,
        line2: 12.13,
      },
      {
        name: "1987M3",
        line1: 7,
        line2: 11.65,
      },
      {
        name: "1987M4",
        line1: 7,
        line2: 11.44,
      },
      {
        name: "1987M5",
        line1: 7,
        line2: 10.8,
      },
      {
        name: "1987M6",
        line1: 7,
        line2: 10.95,
      },
      {
        name: "1987M7",
        line1: 7,
        line2: 11.23,
      },
      {
        name: "1987M8",
        line1: 7,
        line2: 11,
      },
      {
        name: "1987M9",
        line1: 7,
        line2: 10.71,
      },
      {
        name: "1987M10",
        line1: 7,
        line2: 11.11,
      },
      {
        name: "1987M11",
        line1: 7,
        line2: 10.46,
      },
      {
        name: "1987M12",
        line1: 7,
        line2: 10.35,
      },
      {
        name: "1988M1",
        line1: 7,
        line2: 10.48,
      },
      {
        name: "1988M2",
        line1: 7,
        line2: 9.92,
      },
      {
        name: "1988M3",
        line1: 7,
        line2: 10.02,
      },
      {
        name: "1988M4",
        line1: 7,
        line2: 9.96,
      },
      {
        name: "1988M5",
        line1: 8,
        line2: 10.34,
      },
      {
        name: "1988M6",
        line1: 8,
        line2: 10.29,
      },
      {
        name: "1988M7",
        line1: 8,
        line2: 10.56,
      },
      {
        name: "1988M8",
        line1: 8,
        line2: 10.72,
      },
      {
        name: "1988M9",
        line1: 8,
        line2: 10.63,
      },
      {
        name: "1988M10",
        line1: 8,
        line2: 10.63,
      },
      {
        name: "1988M11",
        line1: 8,
        line2: 11.59,
      },
      {
        name: "1988M12",
        line1: 8,
        line2: 11.55,
      },
      {
        name: "1989M1",
        line1: 7.5,
        line2: 11.6,
      },
      {
        name: "1989M2",
        line1: 7.5,
        line2: 11.74,
      },
      {
        name: "1989M3",
        line1: 7.5,
        line2: 11.75,
      },
      {
        name: "1989M4",
        line1: 7.5,
        line2: 11.77,
      },
      {
        name: "1989M5",
        line1: 7.5,
        line2: 11.82,
      },
      {
        name: "1989M6",
        line1: 7.5,
        line2: 12.34,
        line3: 9.89,
      },
      {
        name: "1989M7",
        line1: 7.5,
        line2: 12.09,
        line3: 9.92,
      },
      {
        name: "1989M8",
        line1: 7.5,
        line2: 11.91,
        line3: 9.96,
      },
      {
        name: "1989M9",
        line1: 7.5,
        line2: 12.2,
        line3: 10.02,
      },
      {
        name: "1989M10",
        line1: 7.5,
        line2: 12.37,
        line3: 10.08,
      },
      {
        name: "1989M11",
        line1: 8.5,
        line2: 12.57,
        line3: 10.99,
      },
      {
        name: "1989M12",
        line1: 8.5,
        line2: 12.91,
        line3: 11.08,
      },
      {
        name: "1990",
        line1: 8.5,
        line2: 12.68,
        line3: 11.12,
      },
      {
        name: "1990M2",
        line1: 8.5,
        line2: 13.16,
        line3: 11.12,
      },
      {
        name: "1990M3",
        line1: 8.5,
        line2: 13.16,
        line3: 11.15,
      },
      {
        name: "1990M4",
        line1: 8.5,
        line2: 13.44,
        line3: 11.16,
      },
      {
        name: "1990M5",
        line1: 8.5,
        line2: 13.1,
        line3: 11.24,
      },
      {
        name: "1990M6",
        line1: 8.5,
        line2: 13.11,
        line3: 11.24,
      },
      {
        name: "1990M7",
        line1: 8.5,
        line2: 13.4,
        line3: 11.27,
      },
      {
        name: "1990M8",
        line1: 8.5,
        line2: 13.24,
        line3: 11.32,
      },
      {
        name: "1990M9",
        line1: 8.5,
        line2: 13.35,
        line3: 11.36,
      },
      {
        name: "1990M10",
        line1: 8.5,
        line2: 13.47,
        line3: 11.42,
      },
      {
        name: "1990M11",
        line1: 8.5,
        line2: 13.11,
        line3: 11.45,
      },
      {
        name: "1990M12",
        line1: 8.5,
        line2: 13.54,
        line3: 11.48,
      },
      {
        name: "1991M1",
        line1: 8.5,
        line2: 12.8,
        line3: 11.51,
      },
      {
        name: "1991M2",
        line1: 8.5,
        line2: 12.3,
        line3: 11.53,
      },
      {
        name: "1991M3",
        line1: 8.5,
        line2: 12.21,
        line3: 11.56,
      },
      {
        name: "1991M4",
        line1: 8.5,
        line2: 12.19,
        line3: 11.59,
      },
      {
        name: "1991M5",
        line1: 8.5,
        line2: 11.31,
        line3: 11.61,
      },
      {
        name: "1991M6",
        line1: 8.5,
        line2: 11.04,
        line3: 11.6,
      },
      {
        name: "1991M7",
        line1: 8.5,
        line2: 11.15,
        line3: 11.58,
      },
      {
        name: "1991M8",
        line1: 8.5,
        line2: 11.06,
        line3: 11.59,
      },
      {
        name: "1991M9",
        line1: 8.5,
        line2: 11.01,
        line3: 11.63,
      },
      {
        name: "1991M10",
        line1: 8.5,
        line2: 10.8,
        line3: 11.68,
      },
      {
        name: "1991M11",
        line1: 8.5,
        line2: 11.35,
        line3: 11.71,
      },
      {
        name: "1991M12",
        line1: 8.5,
        line2: 12.06,
        line3: 11.75,
      },
      {
        name: "1992M1",
        line1: 8.5,
        line2: 11.76,
        line3: 11.74,
      },
      {
        name: "1992M2",
        line1: 8.5,
        line2: 11.18,
        line3: 11.74,
      },
      {
        name: "1992M3",
        line1: 8.5,
        line2: 11.25,
        line3: 11.76,
      },
      {
        name: "1992M4",
        line1: 8.5,
        line2: 11.86,
        line3: 11.79,
      },
      {
        name: "1992M5",
        line1: 9.5,
        line2: 11.82,
        line3: 12.22,
      },
      {
        name: "1992M6",
        line1: 9.5,
        line2: 12.19,
        line3: 12.26,
      },
      {
        name: "1992M7",
        line1: 9.5,
        line2: 12.11,
        line3: 12.27,
      },
      {
        name: "1992M8",
        line1: 9.5,
        line2: 12.92,
        line3: 12.33,
      },
      {
        name: "1992M9",
        line1: 9.5,
        line2: 13.53,
        line3: 12.38,
      },
      {
        name: "1992M10",
        line1: 9.5,
        line2: 12.99,
        line3: 12.42,
      },
      {
        name: "1992M11",
        line1: 9.5,
        line2: 12.04,
        line3: 12.4,
      },
      {
        name: "1992M12",
        line1: 9.5,
        line2: 10.99,
        line3: 12.33,
      },
      {
        name: "1993M1",
        line1: 8.5,
        line2: 10.92,
        line3: 11.84,
      },
      {
        name: "1993M2",
        line1: 7.5,
        line2: 10.27,
        line3: 11.24,
      },
      {
        name: "1993M3",
        line1: 7.5,
        line2: 9.87,
        line3: 11.11,
      },
      {
        name: "1993M4",
        line1: 7.5,
        line2: 9.77,
        line3: 11.01,
      },
      {
        name: "1993M5",
        line1: 7,
        line2: 9.46,
        line3: 10.62,
      },
      {
        name: "1993M6",
        line1: 7,
        line2: 9.05,
        line3: 10.52,
      },
      {
        name: "1993M7",
        line1: 6.5,
        line2: 8.6,
        line3: 10.17,
      },
      {
        name: "1993M8",
        line1: 6,
        line2: 7.87,
        line3: 9.74,
      },
      {
        name: "1993M9",
        line1: 6,
        line2: 7.99,
        line3: 9.55,
      },
      {
        name: "1993M10",
        line1: 6,
        line2: 7.67,
        line3: 9.47,
      },
      {
        name: "1993M11",
        line1: 6,
        line2: 7.39,
        line3: 9.4,
      },
      {
        name: "1993M12",
        line1: 5.5,
        line2: 7.03,
        line3: 9.04,
      },
      {
        name: "1994M1",
        line1: 5.5,
        line2: 6.54,
        line3: 8.9,
      },
      {
        name: "1994M2",
        line1: 5.25,
        line2: 6.58,
        line3: 8.68,
      },
      {
        name: "1994M3",
        line1: 5.25,
        line2: 7.45,
        line3: 8.54,
      },
      {
        name: "1994M4",
        line1: 5.25,
        line2: 8.29,
        line3: 8.47,
      },
      {
        name: "1994M5",
        line1: 5.25,
        line2: 8.52,
        line3: 8.41,
      },
      {
        name: "1994M6",
        line1: 5.25,
        line2: 9.53,
        line3: 8.36,
      },
      {
        name: "1994M7",
        line1: 5.25,
        line2: 9.89,
        line3: 8.49,
      },
      {
        name: "1994M8",
        line1: 5.25,
        line2: 10.59,
        line3: 8.48,
      },
      {
        name: "1994M9",
        line1: 5.25,
        line2: 10.53,
        line3: 8.48,
      },
      {
        name: "1994M10",
        line1: 5.25,
        line2: 10.08,
        line3: 8.47,
      },
      {
        name: "1994M11",
        line1: 5.25,
        line2: 10.23,
        line3: 8.46,
      },
      {
        name: "1994M12",
        line1: 5.25,
        line2: 10.21,
        line3: 8.47,
      },
      {
        name: "1995M1",
        line1: 5.25,
        line2: 10.24,
        line3: 8.51,
      },
      {
        name: "1995M2",
        line1: 5.25,
        line2: 10.22,
        line3: 8.52,
      },
      {
        name: "1995M3",
        line1: 5.25,
        line2: 10.18,
        line3: 8.54,
      },
      {
        name: "1995M4",
        line1: 5.25,
        line2: 9.42,
        line3: 8.54,
      },
      {
        name: "1995M5",
        line1: 5.25,
        line2: 8.84,
        line3: 8.55,
      },
      {
        name: "1995M6",
        line1: 5.25,
        line2: 8.7,
        line3: 8.52,
      },
      {
        name: "1995M7",
        line1: 5.25,
        line2: 8.74,
        line3: 8.51,
      },
      {
        name: "1995M8",
        line1: 5.25,
        line2: 8.34,
        line3: 8.5,
      },
      {
        name: "1995M9",
        line1: 5.25,
        line2: 7.98,
        line3: 8.47,
      },
      {
        name: "1995M10",
        line1: 5.25,
        line2: 7.95,
        line3: 8.36,
      },
      {
        name: "1995M11",
        line1: 5,
        line2: 7.52,
        line3: 8.23,
      },
      {
        name: "1995M12",
        line1: 4.75,
        line2: 7.38,
        line3: 7.99,
      },
      {
        name: "1996M1",
        line1: 4.75,
        line2: 7.03,
        line3: 7.7,
      },
      {
        name: "1996M2",
        line1: 4.5,
        line2: 7.67,
        line3: 7.48,
      },
      {
        name: "1996M3",
        line1: 4.5,
        line2: 7.71,
        line3: 7.42,
      },
      {
        name: "1996M4",
        line1: 4.5,
        line2: 7.47,
        line3: 7.31,
      },
      {
        name: "1996M5",
        line1: 4.5,
        line2: 7.36,
        line3: 7.19,
      },
      {
        name: "1996M6",
        line1: 4.5,
        line2: 7.21,
        line3: 7.12,
      },
      {
        name: "1996M7",
        line1: 4.5,
        line2: 7.07,
        line3: 7.07,
      },
      {
        name: "1996M8",
        line1: 4.5,
        line2: 7.16,
        line3: 7.01,
      },
      {
        name: "1996M9",
        line1: 4,
        line2: 6.92,
        line3: 6.79,
      },
      {
        name: "1996M10",
        line1: 4,
        line2: 6.51,
        line3: 6.52,
      },
      {
        name: "1996M11",
        line1: 4,
        line2: 6.47,
        line3: 6.46,
      },
      {
        name: "1996M12",
        line1: 4,
        line2: 6.34,
        line3: 6.37,
      },
      {
        name: "1997M1",
        line1: 4,
        line2: 6.14,
        line3: 6.33,
      },
      {
        name: "1997M2",
        line1: 4,
        line2: 5.91,
        line3: 6.29,
      },
      {
        name: "1997M3",
        line1: 4,
        line2: 6.12,
        line3: 6.25,
      },
      {
        name: "1997M4",
        line1: 4,
        line2: 6.36,
        line3: 6.2,
      },
      {
        name: "1997M5",
        line1: 4,
        line2: 6.16,
        line3: 6.16,
      },
      {
        name: "1997M6",
        line1: 4,
        line2: 6.09,
        line3: 6.12,
      },
      {
        name: "1997M7",
        line1: 4,
        line2: 5.86,
        line3: 6.09,
      },
      {
        name: "1997M8",
        line1: 4,
        line2: 5.9,
        line3: 6.07,
      },
      {
        name: "1997M9",
        line1: 4,
        line2: 5.83,
        line3: 6.06,
      },
      {
        name: "1997M10",
        line1: 4,
        line2: 5.75,
        line3: 6.07,
      },
      {
        name: "1997M11",
        line1: 4,
        line2: 5.79,
        line3: 6.09,
      },
      {
        name: "1997M12",
        line1: 4,
        line2: 5.58,
        line3: 6.15,
      },
      {
        name: "1998M1",
        line1: 4,
        line2: 5.3,
        line3: 6.15,
      },
      {
        name: "1998M2",
        line1: 4,
        line2: 5.16,
        line3: 6.14,
      },
      {
        name: "1998M3",
        line1: 4,
        line2: 5.04,
        line3: 6.02,
      },
      {
        name: "1998M4",
        line1: 4,
        line2: 5.01,
        line3: 6.01,
      },
      {
        name: "1998M5",
        line1: 4,
        line2: 5.08,
        line3: 5.97,
      },
      {
        name: "1998M6",
        line1: 4,
        line2: 4.95,
        line3: 5.97,
      },
      {
        name: "1998M7",
        line1: 4,
        line2: 4.86,
        line3: 5.95,
      },
      {
        name: "1998M8",
        line1: 4,
        line2: 4.68,
        line3: 5.93,
      },
      {
        name: "1998M9",
        line1: 4,
        line2: 4.45,
        line3: 5.89,
      },
      {
        name: "1998M10",
        line1: 4,
        line2: 4.45,
        line3: 5.82,
      },
      {
        name: "1998M11",
        line1: 4,
        line2: 4.41,
        line3: 5.74,
      },
      {
        name: "1998M12",
        line1: 3.5,
        line2: 4.06,
        line3: 5.63,
      },
      {
        name: "1999M1",
        line1: 3.5,
        line2: 3.91,
        line3: 5.57,
      },
      {
        name: "1999M2",
        line1: 3.5,
        line2: 4.04,
        line3: 5.42,
      },
      {
        name: "1999M3",
        line1: 3.5,
        line2: 4.26,
        line3: 5.32,
      },
      {
        name: "1999M4",
        line1: 3.5,
        line2: 4.07,
        line3: 5.24,
      },
      {
        name: "1999M5",
        line1: 3.5,
        line2: 4.24,
        line3: 5.11,
      },
      {
        name: "1999M6",
        line1: 3.5,
        line2: 4.58,
        line3: 4.92,
      },
      {
        name: "1999M7",
        line1: 2.75,
        line2: 4.92,
        line3: 4.81,
      },
      {
        name: "1999M8",
        line1: 2.75,
        line2: 5.15,
        line3: 4.78,
      },
      {
        name: "1999M9",
        line1: 2.75,
        line2: 5.32,
        line3: 4.76,
      },
      {
        name: "1999M10",
        line1: 2.75,
        line2: 5.56,
        line3: 4.8,
      },
      {
        name: "1999M11",
        line1: 2.75,
        line2: 5.27,
        line3: 5,
      },
      {
        name: "1999M12",
        line1: 2.75,
        line2: 5.37,
        line3: 5.02,
      },
      {
        name: "2000",
        line1: 3.5,
        line2: 5.75,
        line3: 5.1,
      },
      {
        name: "2000M2",
        line1: 3.5,
        line2: 5.72,
        line3: 5.17,
      },
      {
        name: "2000M3",
        line1: 3.5,
        line2: 5.5,
        line3: 5.42,
      },
      {
        name: "2000M4",
        line1: 3.5,
        line2: 5.46,
        line3: 5.48,
      },
      {
        name: "2000M5",
        line1: 3.5,
        line2: 5.59,
        line3: 5.63,
      },
      {
        name: "2000M6",
        line1: 3.5,
        line2: 5.39,
        line3: 5.94,
      },
      {
        name: "2000M7",
        line1: 4.5,
        line2: 5.48,
        line3: 6.07,
      },
      {
        name: "2000M8",
        line1: 4.5,
        line2: 5.43,
        line3: 6.11,
      },
      {
        name: "2000M9",
        line1: 4.5,
        line2: 5.49,
        line3: 6.42,
      },
      {
        name: "2000M10",
        line1: 4.5,
        line2: 5.45,
        line3: 6.46,
      },
      {
        name: "2000M11",
        line1: 4.5,
        line2: 5.4,
        line3: 6.48,
      },
      {
        name: "2000M12",
        line1: 4.5,
        line2: 5.14,
        line3: 6.49,
      },
      {
        name: "2001M1",
        line1: 5.25,
        line2: 5.02,
        line3: 6.52,
      },
      {
        name: "2001M2",
        line1: 5.25,
        line2: 5.02,
        line3: 6.52,
      },
      {
        name: "2001M3",
        line1: 5.25,
        line2: 4.94,
        line3: 6.5,
      },
      {
        name: "2001M4",
        line1: 5.25,
        line2: 5.1,
        line3: 6.34,
      },
      {
        name: "2001M5",
        line1: 5.25,
        line2: 5.29,
        line3: 6.31,
      },
      {
        name: "2001M6",
        line1: 5.25,
        line2: 5.26,
        line3: 6.27,
      },
      {
        name: "2001M7",
        line1: 4.5,
        line2: 5.27,
        line3: 6.2,
      },
      {
        name: "2001M8",
        line1: 4.5,
        line2: 5.06,
        line3: 6.14,
      },
      {
        name: "2001M9",
        line1: 4.5,
        line2: 5.06,
        line3: 5.91,
      },
      {
        name: "2001M10",
        line1: 4.5,
        line2: 4.83,
        line3: 5.59,
      },
      {
        name: "2001M11",
        line1: 4.5,
        line2: 4.69,
        line3: 5.4,
      },
      {
        name: "2001M12",
        line1: 4.5,
        line2: 4.98,
        line3: 5.27,
      },
      {
        name: "2002M1",
        line1: 3.5,
        line2: 5.05,
        line3: 5.18,
      },
      {
        name: "2002M2",
        line1: 3.5,
        line2: 5.1,
        line3: 5.14,
      },
      {
        name: "2002M3",
        line1: 3.5,
        line2: 5.33,
        line3: 5.1,
      },
      {
        name: "2002M4",
        line1: 3.5,
        line2: 5.32,
        line3: 5.07,
      },
      {
        name: "2002M5",
        line1: 3.5,
        line2: 5.4,
        line3: 5.04,
      },
      {
        name: "2002M6",
        line1: 3.5,
        line2: 5.26,
        line3: 5.09,
      },
      {
        name: "2002M7",
        line1: 4,
        line2: 5.1,
        line3: 5.08,
      },
      {
        name: "2002M8",
        line1: 4,
        line2: 4.81,
        line3: 5.04,
      },
      {
        name: "2002M9",
        line1: 4,
        line2: 4.61,
        line3: 5,
      },
      {
        name: "2002M10",
        line1: 4,
        line2: 4.68,
        line3: 4.87,
      },
      {
        name: "2002M11",
        line1: 4,
        line2: 4.66,
        line3: 4.8,
      },
      {
        name: "2002M12",
        line1: 4,
        line2: 4.45,
        line3: 4.74,
      },
      {
        name: "2003M1",
        line1: 3.25,
        line2: 4.26,
        line3: 4.62,
      },
      {
        name: "2003M2",
        line1: 3.25,
        line2: 4.06,
        line3: 4.53,
      },
      {
        name: "2003M3",
        line1: 3.25,
        line2: 4.09,
        line3: 4.3,
      },
      {
        name: "2003M4",
        line1: 3.25,
        line2: 4.23,
        line3: 4.21,
      },
      {
        name: "2003M5",
        line1: 3.25,
        line2: 3.91,
        line3: 4.1,
      },
      {
        name: "2003M6",
        line1: 3.25,
        line2: 3.7,
        line3: 3.86,
      },
      {
        name: "2003M7",
        line1: 2.5,
        line2: 4.01,
        line3: 3.76,
      },
      {
        name: "2003M8",
        line1: 2.5,
        line2: 4.18,
        line3: 3.7,
      },
      {
        name: "2003M9",
        line1: 2.5,
        line2: 4.2,
        line3: 3.65,
      },
      {
        name: "2003M10",
        line1: 2.5,
        line2: 4.26,
        line3: 3.6,
      },
      {
        name: "2003M11",
        line1: 2.5,
        line2: 4.39,
        line3: 3.57,
      },
      {
        name: "2003M12",
        line1: 2.5,
        line2: 4.33,
        line3: 3.55,
      },
      {
        name: "2004M1",
        line1: 2.25,
        line2: 4.16,
        line3: 3.52,
      },
      {
        name: "2004M2",
        line1: 2.25,
        line2: 4.11,
        line3: 3.49,
      },
      {
        name: "2004M3",
        line1: 2.25,
        line2: 3.92,
        line3: 3.45,
      },
      {
        name: "2004M4",
        line1: 2.25,
        line2: 4.1,
        line3: 3.41,
      },
      {
        name: "2004M5",
        line1: 2.25,
        line2: 4.25,
        line3: 3.4,
      },
      {
        name: "2004M6",
        line1: 2.25,
        line2: 4.48,
        line3: 3.41,
      },
      {
        name: "2004M7",
        line1: 2.25,
        line2: 4.36,
        line3: 3.41,
      },
      {
        name: "2004M8",
        line1: 2.25,
        line2: 4.21,
        line3: 3.4,
      },
      {
        name: "2004M9",
        line1: 2.25,
        line2: 4.14,
        line3: 3.4,
      },
      {
        name: "2004M10",
        line1: 2.25,
        line2: 4.01,
        line3: 3.39,
      },
      {
        name: "2004M11",
        line1: 2.25,
        line2: 3.89,
        line3: 3.38,
      },
      {
        name: "2004M12",
        line1: 2.25,
        line2: 3.68,
        line3: 3.37,
      },
      {
        name: "2005M1",
        line1: 2.25,
        line2: 3.6,
        line3: 3.36,
      },
      {
        name: "2005M2",
        line1: 2.25,
        line2: 3.57,
        line3: 3.36,
      },
      {
        name: "2005M3",
        line1: 2.25,
        line2: 3.72,
        line3: 3.35,
      },
      {
        name: "2005M4",
        line1: 2.25,
        line2: 3.51,
        line3: 3.34,
      },
      {
        name: "2005M5",
        line1: 2.25,
        line2: 3.33,
        line3: 3.31,
      },
      {
        name: "2005M6",
        line1: 2.25,
        line2: 3.16,
        line3: 3.27,
      },
      {
        name: "2005M7",
        line1: 2.25,
        line2: 3.18,
        line3: 3.24,
      },
      {
        name: "2005M8",
        line1: 2.25,
        line2: 3.21,
        line3: 3.22,
      },
      {
        name: "2005M9",
        line1: 2.25,
        line2: 3.04,
        line3: 3.19,
      },
      {
        name: "2005M10",
        line1: 2.25,
        line2: 3.19,
        line3: 3.18,
      },
      {
        name: "2005M11",
        line1: 2.25,
        line2: 3.4,
        line3: 3.19,
      },
      {
        name: "2005M12",
        line1: 2.25,
        line2: 3.3,
        line3: 3.22,
      },
      {
        name: "2006M1",
        line1: 2.5,
        line2: 3.28,
        line3: 3.24,
      },
      {
        name: "2006M2",
        line1: 2.5,
        line2: 3.44,
        line3: 3.27,
      },
      {
        name: "2006M3",
        line1: 2.5,
        line2: 3.62,
        line3: 3.33,
      },
      {
        name: "2006M4",
        line1: 2.5,
        line2: 3.88,
        line3: 3.42,
      },
      {
        name: "2006M5",
        line1: 2.5,
        line2: 3.94,
        line3: 3.51,
      },
      {
        name: "2006M6",
        line1: 2.5,
        line2: 4.02,
        line3: 3.64,
      },
      {
        name: "2006M7",
        line1: 3.25,
        line2: 4.06,
        line3: 3.73,
      },
      {
        name: "2006M8",
        line1: 3.25,
        line2: 3.94,
        line3: 3.82,
      },
      {
        name: "2006M9",
        line1: 3.25,
        line2: 3.8,
        line3: 3.96,
      },
      {
        name: "2006M10",
        line1: 3.25,
        line2: 3.84,
        line3: 4.04,
      },
      {
        name: "2006M11",
        line1: 3.25,
        line2: 3.75,
        line3: 4.13,
      },
      {
        name: "2006M12",
        line1: 3.25,
        line2: 3.82,
        line3: 4.19,
      },
      {
        name: "2007M1",
        line1: 3.75,
        line2: 4.05,
        line3: 4.32,
      },
      {
        name: "2007M2",
        line1: 3.75,
        line2: 4.08,
        line3: 4.39,
      },
      {
        name: "2007M3",
        line1: 3.75,
        line2: 3.98,
        line3: 4.45,
      },
      {
        name: "2007M4",
        line1: 3.75,
        line2: 4.2,
        line3: 4.51,
      },
      {
        name: "2007M5",
        line1: 3.75,
        line2: 4.33,
        line3: 4.59,
      },
      {
        name: "2007M6",
        line1: 3.75,
        line2: 4.62,
        line3: 4.72,
      },
      {
        name: "2007M7",
        line1: 4.25,
        line2: 4.59,
        line3: 4.8,
      },
      {
        name: "2007M8",
        line1: 4.25,
        line2: 4.39,
        line3: 4.85,
      },
      {
        name: "2007M9",
        line1: 4.25,
        line2: 4.34,
        line3: 4.9,
      },
      {
        name: "2007M10",
        line1: 4.25,
        line2: 4.38,
        line3: 4.94,
      },
      {
        name: "2007M11",
        line1: 4.25,
        line2: 4.22,
        line3: 4.96,
      },
      {
        name: "2007M12",
        line1: 4.25,
        line2: 4.34,
        line3: 4.99,
      },
      {
        name: "2008M1",
        line1: 4.75,
        line2: 4.14,
        line3: 5,
      },
      {
        name: "2008M2",
        line1: 4.75,
        line2: 4.06,
        line3: 4.99,
      },
      {
        name: "2008M3",
        line1: 4.75,
        line2: 4,
        line3: 5,
      },
      {
        name: "2008M4",
        line1: 4.75,
        line2: 4.22,
        line3: 5.02,
      },
      {
        name: "2008M5",
        line1: 4.75,
        line2: 4.47,
        line3: 5.1,
      },
      {
        name: "2008M6",
        line1: 4.75,
        line2: 4.78,
        line3: 5.17,
      },
      {
        name: "2008M7",
        line1: 4.75,
        line2: 4.77,
        line3: 5.39,
      },
      {
        name: "2008M8",
        line1: 4.75,
        line2: 4.47,
        line3: 5.41,
      },
      {
        name: "2008M9",
        line1: 4.75,
        line2: 4.43,
        line3: 5.44,
      },
      {
        name: "2008M10",
        line1: 4.75,
        line2: 4.33,
        line3: 5.46,
      },
      {
        name: "2008M11",
        line1: 4.75,
        line2: 4.09,
        line3: 5.4,
      },
      {
        name: "2008M12",
        line1: 4.75,
        line2: 3.72,
        line3: 4.83,
      },
      {
        name: "2009M1",
        line1: 5,
        line2: 3.87,
        line3: 4.32,
      },
      {
        name: "2009M2",
        line1: 5,
        line2: 3.93,
        line3: 3.92,
      },
      {
        name: "2009M3",
        line1: 5,
        line2: 3.81,
        line3: 3.53,
      },
      {
        name: "2009M4",
        line1: 5,
        line2: 3.78,
        line3: 3.29,
      },
      {
        name: "2009M5",
        line1: 5,
        line2: 3.91,
        line3: 3.06,
      },
      {
        name: "2009M6",
        line1: 5,
        line2: 3.98,
        line3: 2.89,
      },
      {
        name: "2009M7",
        line1: 1.75,
        line2: 3.77,
        line3: 2.68,
      },
      {
        name: "2009M8",
        line1: 1.75,
        line2: 3.63,
        line3: 2.53,
      },
      {
        name: "2009M9",
        line1: 1.75,
        line2: 3.62,
        line3: 2.37,
      },
      {
        name: "2009M10",
        line1: 1.75,
        line2: 3.57,
        line3: 2.27,
      },
      {
        name: "2009M11",
        line1: 1.75,
        line2: 3.53,
        line3: 2.17,
      },
      {
        name: "2009M12",
        line1: 1.75,
        line2: 3.46,
        line3: 2.12,
      },
      {
        name: "2010",
        line1: 1.25,
        line2: 3.49,
        line3: 2.06,
      },
      {
        name: "2010M2",
        line1: 1.25,
        line2: 3.38,
        line3: 2,
      },
      {
        name: "2010M3",
        line1: 1.25,
        line2: 3.26,
        line3: 1.96,
      },
      {
        name: "2010M4",
        line1: 1.25,
        line2: 3.36,
        line3: 1.94,
      },
      {
        name: "2010M5",
        line1: 1.25,
        line2: 3.03,
        line3: 1.92,
      },
      {
        name: "2010M6",
        line1: 1.25,
        line2: 2.92,
        line3: 1.92,
      },
      {
        name: "2010M7",
        line1: 1.25,
        line2: 2.85,
        line3: 1.94,
      },
      {
        name: "2010M8",
        line1: 1.25,
        line2: 2.62,
        line3: 1.96,
      },
      {
        name: "2010M9",
        line1: 1.25,
        line2: 2.58,
        line3: 1.98,
      },
      {
        name: "2010M10",
        line1: 1.25,
        line2: 2.63,
        line3: 2.01,
      },
      {
        name: "2010M11",
        line1: 1.25,
        line2: 2.82,
        line3: 2.03,
      },
      {
        name: "2010M12",
        line1: 1.25,
        line2: 3.19,
        line3: 2.05,
      },
      {
        name: "2011M1",
        line1: 1.5,
        line2: 3.27,
        line3: 2.06,
      },
      {
        name: "2011M2",
        line1: 1.5,
        line2: 3.41,
        line3: 2.09,
      },
      {
        name: "2011M3",
        line1: 1.5,
        line2: 3.45,
        line3: 2.14,
      },
      {
        name: "2011M4",
        line1: 1.5,
        line2: 3.57,
        line3: 2.21,
      },
      {
        name: "2011M5",
        line1: 1.5,
        line2: 3.32,
        line3: 2.29,
      },
      {
        name: "2011M6",
        line1: 1.5,
        line2: 3.29,
        line3: 2.36,
      },
      {
        name: "2011M7",
        line1: 2,
        line2: 3.16,
        line3: 2.43,
      },
      {
        name: "2011M8",
        line1: 2,
        line2: 2.68,
        line3: 2.46,
      },
      {
        name: "2011M9",
        line1: 2,
        line2: 2.35,
        line3: 2.49,
      },
      {
        name: "2011M10",
        line1: 2,
        line2: 2.51,
        line3: 2.51,
      },
      {
        name: "2011M11",
        line1: 2,
        line2: 2.54,
        line3: 2.51,
      },
      {
        name: "2011M12",
        line1: 2,
        line2: 2.52,
        line3: 2.51,
      },
      {
        name: "2012M1",
        line1: 2,
        line2: 2.28,
        line3: 2.47,
      },
      {
        name: "2012M2",
        line1: 2,
        line2: 2.34,
        line3: 2.42,
      },
      {
        name: "2012M3",
        line1: 2,
        line2: 2.3,
        line3: 2.34,
      },
      {
        name: "2012M4",
        line1: 2,
        line2: 2.15,
        line3: 2.26,
      },
      {
        name: "2012M5",
        line1: 2,
        line2: 1.82,
        line3: 2.18,
      },
      {
        name: "2012M6",
        line1: 2,
        line2: 1.76,
        line3: 2.11,
      },
      {
        name: "2012M7",
        line1: 1.5,
        line2: 1.55,
        line3: 2.02,
      },
      {
        name: "2012M8",
        line1: 1.5,
        line2: 1.54,
        line3: 1.93,
      },
      {
        name: "2012M9",
        line1: 1.5,
        line2: 1.82,
        line3: 1.83,
      },
      {
        name: "2012M10",
        line1: 1.5,
        line2: 1.78,
        line3: 1.75,
      },
      {
        name: "2012M11",
        line1: 1.5,
        line2: 1.67,
        line3: 1.68,
      },
      {
        name: "2012M12",
        line1: 1.5,
        line2: 1.59,
        line3: 1.63,
      },
      {
        name: "2013M1",
        line1: 0.75,
        line2: 1.74,
        line3: 1.59,
      },
      {
        name: "2013M2",
        line1: 0.75,
        line2: 1.81,
        line3: 1.56,
      },
      {
        name: "2013M3",
        line1: 0.75,
        line2: 1.61,
        line3: 1.53,
      },
      {
        name: "2013M4",
        line1: 0.75,
        line2: 1.51,
        line3: 1.51,
      },
      {
        name: "2013M5",
        line1: 0.75,
        line2: 1.6,
        line3: 1.48,
      },
      {
        name: "2013M6",
        line1: 0.75,
        line2: 1.88,
        line3: 1.46,
      },
      {
        name: "2013M7",
        line1: 0.5,
        line2: 1.91,
        line3: 1.45,
      },
      {
        name: "2013M8",
        line1: 0.5,
        line2: 2.07,
        line3: 1.45,
      },
      {
        name: "2013M9",
        line1: 0.5,
        line2: 2.19,
        line3: 1.46,
      },
      {
        name: "2013M10",
        line1: 0.5,
        line2: 2.04,
        line3: 1.46,
      },
      {
        name: "2013M11",
        line1: 0.5,
        line2: 1.94,
        line3: 1.47,
      },
      {
        name: "2013M12",
        line1: 0.5,
        line2: 2.03,
        line3: 1.48,
      },
      {
        name: "2014M1",
        line1: 0.5,
        line2: 1.99,
        line3: 1.49,
      },
      {
        name: "2014M2",
        line1: 0.5,
        line2: 1.92,
        line3: 1.5,
      },
      {
        name: "2014M3",
        line1: 0.5,
        line2: 1.9,
        line3: 1.51,
      },
      {
        name: "2014M4",
        line1: 0.5,
        line2: 1.84,
        line3: 1.52,
      },
      {
        name: "2014M5",
        line1: 0.5,
        line2: 1.69,
        line3: 1.54,
      },
      {
        name: "2014M6",
        line1: 0.5,
        line2: 1.57,
        line3: 1.54,
      },
      {
        name: "2014M7",
        line1: 0.5,
        line2: 1.36,
        line3: 1.53,
      },
      {
        name: "2014M8",
        line1: 0.5,
        line2: 1.18,
        line3: 1.51,
      },
      {
        name: "2014M9",
        line1: 0.5,
        line2: 1.13,
        line3: 1.49,
      },
      {
        name: "2014M10",
        line1: 0.5,
        line2: 1.01,
        line3: 1.47,
      },
      {
        name: "2014M11",
        line1: 0.5,
        line2: 0.9,
        line3: 1.45,
      },
      {
        name: "2014M12",
        line1: 0.5,
        line2: 0.89,
        line3: 1.44,
      },
      {
        name: "2015M1",
        line1: 0.25,
        line2: 0.62,
        line3: 1.43,
      },
      {
        name: "2015M2",
        line1: 0.25,
        line2: 0.48,
        line3: 1.41,
      },
      {
        name: "2015M3",
        line1: 0.25,
        line2: 0.39,
        line3: 1.39,
      },
      {
        name: "2015M4",
        line1: 0.25,
        line2: 0.27,
        line3: 1.37,
      },
      {
        name: "2015M5",
        line1: 0.25,
        line2: 0.71,
        line3: 1.34,
      },
      {
        name: "2015M6",
        line1: 0.25,
        line2: 1.01,
        line3: 1.32,
      },
      {
        name: "2015M7",
        line1: 0.25,
        line2: 0.95,
        line3: 1.3,
      },
      {
        name: "2015M8",
        line1: 0.25,
        line2: 0.82,
        line3: 1.29,
      },
      {
        name: "2015M9",
        line1: 0.25,
        line2: 0.95,
        line3: 1.28,
      },
      {
        name: "2015M10",
        line1: 0.25,
        line2: 0.81,
        line3: 1.27,
      },
      {
        name: "2015M11",
        line1: 0.25,
        line2: 0.81,
        line3: 1.26,
      },
      {
        name: "2015M12",
        line1: 0.25,
        line2: 0.86,
        line3: 1.24,
      },
      {
        name: "2016M1",
        line1: 0,
        line2: 0.78,
        line3: 1.23,
      },
      {
        name: "2016M2",
        line1: 0,
        line2: 0.53,
        line3: 1.2,
      },
      {
        name: "2016M3",
        line1: 0,
        line2: 0.53,
        line3: 1.18,
      },
      {
        name: "2016M4",
        line1: 0,
        line2: 0.46,
        line3: 1.16,
      },
      {
        name: "2016M5",
        line1: 0,
        line2: 0.45,
        line3: 1.15,
      },
      {
        name: "2016M6",
        line1: 0,
        line2: 0.33,
        line3: 1.14,
      },
      {
        name: "2016M7",
        line1: 0,
        line2: 0.12,
        line3: 1.13,
      },
      {
        name: "2016M8",
        line1: 0,
        line2: 0.06,
        line3: 1.12,
      },
      {
        name: "2016M9",
        line1: 0,
        line2: 0.08,
        line3: 1.11,
      },
      {
        name: "2016M10",
        line1: 0,
        line2: 0.17,
        line3: 1.1,
      },
      {
        name: "2016M11",
        line1: 0,
        line2: 0.41,
        line3: 1.08,
      },
      {
        name: "2016M12",
        line1: 0,
        line2: 0.46,
        line3: 1.07,
      },
      {
        name: "2017M1",
        line1: 0,
        line2: 0.5,
        line3: 1.07,
      },
      {
        name: "2017M2",
        line1: 0,
        line2: 0.52,
        line3: 1.06,
      },
      {
        name: "2017M3",
        line1: 0,
        line2: 0.51,
        line3: 1.06,
      },
      {
        name: "2017M4",
        line1: 0,
        line2: 0.38,
        line3: 1.05,
      },
      {
        name: "2017M5",
        line1: 0,
        line2: 0.49,
        line3: 1.05,
      },
      {
        name: "2017M6",
        line1: 0,
        line2: 0.56,
        line3: 1.04,
      },
      {
        name: "2017M7",
        line1: 0,
        line2: 0.76,
        line3: 1.04,
      },
      {
        name: "2017M8",
        line1: 0,
        line2: 0.65,
        line3: 1.04,
      },
      {
        name: "2017M9",
        line1: 0,
        line2: 0.58,
        line3: 1.03,
      },
      {
        name: "2017M10",
        line1: 0,
        line2: 0.59,
        line3: 1.03,
      },
      {
        name: "2017M11",
        line1: 0,
        line2: 0.52,
        line3: 1.02,
      },
      {
        name: "2017M12",
        line1: 0,
        line2: 0.5,
        line3: 1.02,
      },
      {
        name: "2018M1",
        line1: -0.25,
        line2: 0.67,
        line3: 1.01,
      },
      {
        name: "2018M2",
        line1: -0.25,
        line2: 0.84,
        line3: 1.01,
      },
      {
        name: "2018M3",
        line1: -0.25,
        line2: 0.72,
        line3: 1,
      },
      {
        name: "2018M4",
        line1: -0.25,
        line2: 0.69,
        line3: 1,
      },
      {
        name: "2018M5",
        line1: -0.25,
        line2: 0.7,
        line3: 0.99,
      },
      {
        name: "2018M6",
        line1: -0.25,
        line2: 0.63,
        line3: 0.99,
      },
      {
        name: "2018M7",
        line1: -0.25,
        line2: 0.53,
        line3: 0.98,
      },
      {
        name: "2018M8",
        line1: -0.25,
        line2: 0.56,
        line3: 0.98,
      },
      {
        name: "2018M9",
        line1: -0.25,
        line2: 0.66,
        line3: 0.98,
      },
      {
        name: "2018M10",
        line1: -0.25,
        line2: 0.73,
        line3: 0.98,
      },
      {
        name: "2018M11",
        line1: -0.25,
        line2: 0.66,
        line3: 0.98,
      },
      {
        name: "2018M12",
        line1: -0.25,
        line2: 0.55,
        line3: 0.98,
      },
      {
        name: "2019M1",
        line1: -0.25,
        line2: 0.49,
        line3: 0.98,
      },
      {
        name: "2019M2",
        line1: -0.25,
        line2: 0.38,
        line3: 0.97,
      },
      {
        name: "2019M3",
        line1: -0.25,
        line2: 0.39,
        line3: 0.97,
      },
      {
        name: "2019M4",
        line1: -0.25,
        line2: 0.34,
        line3: 0.97,
      },
      {
        name: "2019M5",
        line1: -0.25,
        line2: 0.25,
        line3: 0.97,
      },
      {
        name: "2019M6",
        line1: -0.25,
        line2: 0.05,
        line3: 0.96,
      },
      {
        name: "2019M7",
        line1: 0,
        line2: -0.09,
        line3: 0.95,
      },
      {
        name: "2019M8",
        line1: 0,
        line2: -0.35,
        line3: 0.94,
      },
      {
        name: "2019M9",
        line1: 0,
        line2: -0.3,
        line3: 0.92,
      },
      {
        name: "2019M10",
        line1: 0,
        line2: -0.21,
        line3: 0.91,
      },
      {
        name: "2019M11",
        line1: 0,
        line2: -0.08,
        line3: 0.91,
      },
      {
        name: "2019M12",
        line1: 0,
        line2: -0.03,
        line3: 0.9,
      },
      {
        name: "2020",
        line1: -0.25,
        line2: -0.07,
        line3: 0.89,
      },
      {
        name: "2020M2",
        line1: -0.25,
        line2: -0.23,
        line3: 0.89,
      },
      {
        name: "2020M3",
        line1: -0.25,
        line2: -0.12,
        line3: 0.88,
      },
      {
        name: "2020M4",
        line1: -0.25,
        line2: 0.03,
        line3: 0.88,
      },
      {
        name: "2020M5",
        line1: -0.25,
        line2: -0.12,
        line3: 0.89,
      },
      {
        name: "2020M6",
        line1: -0.25,
        line2: -0.17,
        line3: 0.88,
      },
      {
        name: "2020M7",
        line1: -0.25,
        line2: -0.21,
        line3: 0.88,
      },
      {
        name: "2020M8",
        line1: -0.25,
        line2: -0.25,
        line3: 0.87,
      },
      {
        name: "2020M9",
        line1: -0.25,
        line2: -0.3,
        line3: 0.86,
      },
      {
        name: "2020M10",
        line1: -0.25,
        line2: -0.38,
        line3: 0.85,
      },
      {
        name: "2020M11",
        line1: -0.25,
        line2: -0.4,
        line3: 0.85,
      },
      {
        name: "2020M12",
        line1: -0.25,
        line2: -0.41,
        line3: 0.84,
      },
      {
        name: "2021M1",
        line1: -0.5,
        line2: -0.38,
        line3: 0.83,
      },
      {
        name: "2021M2",
        line1: -0.5,
        line2: -0.22,
        line3: 0.82,
      },
      {
        name: "2021M3",
        line1: -0.5,
        line2: -0.12,
        line3: 0.82,
      },
      {
        name: "2021M4",
        line1: -0.5,
        line2: -0.08,
        line3: 0.81,
      },
      {
        name: "2021M5",
        line1: -0.5,
        line2: 0.05,
        line3: 0.8,
      },
      {
        name: "2021M6",
        line1: -0.5,
        line2: -0.02,
        line3: 0.8,
      },
      {
        name: "2021M7",
        line1: -0.5,
        line2: -0.1,
        line3: 0.79,
      },
      {
        name: "2021M8",
        line1: -0.5,
        line2: -0.22,
        line3: 0.79,
      },
      {
        name: "2021M9",
        line1: -0.5,
        line2: -0.08,
        line3: 0.79,
      },
      {
        name: "2021M10",
        line1: -0.5,
        line2: 0.08,
        line3: 0.79,
      },
      {
        name: "2021M11",
        line1: -0.5,
        line2: 0,
        line3: 0.79,
      },
      {
        name: "2021M12",
        line1: -0.5,
        line2: -0.06,
        line3: 0.79,
      },
    ],
  },
  {
    name: "kuvio-4.5",
    height: 450,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "%-muutos",
    yDomain: [-60, 180],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: 40,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 45,
    yAxisWidth: 55,
    margins: [0, 25, 15, 5],
    tickDx: 0,
    type: ["category", "number"],
    manualTicksX: [
      1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020, 2022,
    ],
    manualTicksY: [-80, -40, 0, 40, 80, 120, 160, 200],
    colorPicker: ["#34d8eb", "#65b82e"],
    fontSize: "1rem",
    names: ["Inflaatio vuodessa %", "Osakeindeksin vuosimuutos %*"],
    data: [
      {
        name: 1920,
        line1: 0,
        line2: -1.6,
      },
      {
        name: 1921,
        line1: 24.5,
        line2: -31,
      },
      {
        name: 1922,
        line1: -2.6,
        line2: -13.3,
      },
      {
        name: 1923,
        line1: 0.9,
        line2: 10.5,
      },
      {
        name: 1924,
        line1: 1.7,
        line2: -15,
      },
      {
        name: 1925,
        line1: 4.3,
        line2: -2.2,
      },
      {
        name: 1926,
        line1: -2.5,
        line2: 27.7,
      },
      {
        name: 1927,
        line1: 1.7,
        line2: 53.3,
      },
      {
        name: 1928,
        line1: 2.5,
        line2: 8.1,
      },
      {
        name: 1929,
        line1: -0.8,
        line2: -20.7,
      },
      {
        name: 1930,
        line1: -8.1,
        line2: -4.7,
      },
      {
        name: 1931,
        line1: -8,
        line2: -18.9,
      },
      {
        name: 1932,
        line1: -1,
        line2: -5.1,
      },
      {
        name: 1933,
        line1: -2.9,
        line2: 7.9,
      },
      {
        name: 1934,
        line1: -1,
        line2: 24.6,
      },
      {
        name: 1935,
        line1: 1,
        line2: 7.7,
      },
      {
        name: 1936,
        line1: 0,
        line2: 17.1,
      },
      {
        name: 1937,
        line1: 5,
        line2: 23.8,
      },
      {
        name: 1938,
        line1: 2.9,
        line2: -10.7,
      },
      {
        name: 1939,
        line1: 1.9,
        line2: 0.4,
      },
      {
        name: 1940,
        line1: 19.1,
        line2: 3.9,
      },
      {
        name: 1941,
        line1: 18.3,
        line2: 36,
      },
      {
        name: 1942,
        line1: 18.1,
        line2: 37.1,
      },
      {
        name: 1943,
        line1: 13.1,
        line2: -1.4,
      },
      {
        name: 1944,
        line1: 5.8,
        line2: -4.2,
      },
      {
        name: 1945,
        line1: 40.2,
        line2: 72.9,
      },
      {
        name: 1946,
        line1: 59.6,
        line2: -12.5,
      },
      {
        name: 1947,
        line1: 29.8,
        line2: -15.1,
      },
      {
        name: 1948,
        line1: 34.6,
        line2: -14,
      },
      {
        name: 1949,
        line1: 1.8,
        line2: -2,
      },
      {
        name: 1950,
        line1: 13.9,
        line2: 59,
      },
      {
        name: 1951,
        line1: 16.5,
        line2: 28.5,
      },
      {
        name: 1952,
        line1: 4.1,
        line2: -15.7,
      },
      {
        name: 1953,
        line1: 1.3,
        line2: 14,
      },
      {
        name: 1954,
        line1: -1.6,
        line2: 15.6,
      },
      {
        name: 1955,
        line1: -3.4,
        line2: 47.5,
      },
      {
        name: 1956,
        line1: 11.7,
        line2: 41.8,
      },
      {
        name: 1957,
        line1: 13.5,
        line2: -12.6,
      },
      {
        name: 1958,
        line1: 9.1,
        line2: -10.5,
      },
      {
        name: 1959,
        line1: 1.5,
        line2: 14.6,
      },
      {
        name: 1960,
        line1: 3.3,
        line2: 20.9,
      },
      {
        name: 1961,
        line1: 1.8,
        line2: -0.9,
      },
      {
        name: 1962,
        line1: 4.4,
        line2: -0.9,
      },
      {
        name: 1963,
        line1: 4.9,
        line2: 4.1,
      },
      {
        name: 1964,
        line1: 10.4,
        line2: 1.2,
      },
      {
        name: 1965,
        line1: 4.8,
        line2: -6.1,
      },
      {
        name: 1966,
        line1: 3.9,
        line2: -7.1,
      },
      {
        name: 1967,
        line1: 5.6,
        line2: -10.2,
      },
      {
        name: 1968,
        line1: 8.4,
        line2: 25.7,
      },
      {
        name: 1969,
        line1: 2.3,
        line2: 31.9,
      },
      {
        name: 1970,
        line1: 2.7,
        line2: 14.5,
      },
      {
        name: 1971,
        line1: 6.5,
        line2: 7.2,
      },
      {
        name: 1972,
        line1: 7.2,
        line2: 48.4,
      },
      {
        name: 1973,
        line1: 11.7,
        line2: 63.4,
      },
      {
        name: 1974,
        line1: 17.4,
        line2: -6.4,
      },
      {
        name: 1975,
        line1: 17.8,
        line2: -11.7,
      },
      {
        name: 1976,
        line1: 14.3,
        line2: -12.9,
      },
      {
        name: 1977,
        line1: 12.7,
        line2: -16,
      },
      {
        name: 1978,
        line1: 7.6,
        line2: -1.1,
      },
      {
        name: 1979,
        line1: 7.3,
        line2: 20.9,
      },
      {
        name: 1980,
        line1: 11.6,
        line2: 6.5,
      },
      {
        name: 1981,
        line1: 12,
        line2: 3.8,
      },
      {
        name: 1982,
        line1: 9.3,
        line2: 35.8,
      },
      {
        name: 1983,
        line1: 8.5,
        line2: 47.1,
      },
      {
        name: 1984,
        line1: 7,
        line2: 34.9,
      },
      {
        name: 1985,
        line1: 5.9,
        line2: -11.6,
      },
      {
        name: 1986,
        line1: 2.9,
        line2: 55.5,
      },
      {
        name: 1987,
        line1: 4.2,
        line2: 53.7,
      },
      {
        name: 1988,
        line1: 5,
        line2: 38.8,
      },
      {
        name: 1989,
        line1: 6.6,
        line2: -16.2,
      },
      {
        name: 1990,
        line1: 6.1,
        line2: -34.8,
      },
      {
        name: 1991,
        line1: 4.2,
        line2: -21.8,
      },
      {
        name: 1992,
        line1: 2.5,
        line2: 6.1,
      },
      {
        name: 1993,
        line1: 2.1,
        line2: 90.8,
      },
      {
        name: 1994,
        line1: 1.1,
        line2: 16.7,
      },
      {
        name: 1995,
        line1: 1,
        line2: -7.7,
      },
      {
        name: 1996,
        line1: 0.6,
        line2: 46.5,
      },
      {
        name: 1997,
        line1: 1.2,
        line2: 32.3,
      },
      {
        name: 1998,
        line1: 1.4,
        line2: 68.5,
      },
      {
        name: 1999,
        line1: 1.2,
        line2: 162,
      },
      {
        name: 2000,
        line1: 3.4,
        line2: -10.6,
      },
      {
        name: 2001,
        line1: 2.5,
        line2: -32.4,
      },
      {
        name: 2002,
        line1: 1.6,
        line2: -34.4,
      },
      {
        name: 2003,
        line1: 0.9,
        line2: 4.4,
      },
      {
        name: 2004,
        line1: 0.2,
        line2: 3.3,
      },
      {
        name: 2005,
        line1: 0.9,
        line2: 31.1,
      },
      {
        name: 2006,
        line1: 1.8,
        line2: 17.9,
      },
      {
        name: 2007,
        line1: 2.5,
        line2: 20.5,
      },
      {
        name: 2008,
        line1: 4.1,
        line2: -53.4,
      },
      {
        name: 2009,
        line1: 0,
        line2: 19.5,
      },
      {
        name: 2010,
        line1: 1.2,
        line2: 18.7,
      },
      {
        name: 2011,
        line1: 3.5,
        line2: -30.1,
      },
      {
        name: 2012,
        line1: 2.8,
        line2: 8.3,
      },
      {
        name: 2013,
        line1: 1.5,
        line2: 26.5,
      },
      {
        name: 2014,
        line1: 1,
        line2: 5.7,
      },
      {
        name: 2015,
        line1: -0.2,
        line2: 10.8,
      },
      {
        name: 2016,
        line1: 0.3,
        line2: 3.6,
      },
      {
        name: 2017,
        line1: 0.7,
        line2: 6.6,
      },
      {
        name: 2018,
        line1: 1.1,
        line2: -8.2,
      },
      {
        name: 2019,
        line1: 1,
        line2: 14.1,
      },
      {
        name: 2020,
        line1: 0.3,
        line2: 9.4,
      },
      {
        name: 2021,
        line1: 2.2,
        line2: 18.3,
      },
    ],
  },
  {
    name: "kuvio-4.11",
    height: 450,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "%, suhteessa BKT:hen",
    yDomain: [0, 90],
    xDomain: ["dataMin", "dataMax"],
    xReference: 1999,
    yAxisOffset: 90,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 45,
    yAxisWidth: 55,
    margins: [40, 25, 15, 5],
    tickDx: 0,
    tickDy: 0,
    yInterval: 0,
    referenceLineLabel: "Euro otettiin käyttöön",
    type: ["number", "number"],
    manualTicksX: [
      1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020,
    ],
    manualTicksY: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
    colorPicker: ["#82ca9d", "#ff1900", "#0080ff", "#323329"],
    fontSize: "1rem",
    names: [
      "Keskuspankin velat pankeille",
      "Yleisön (suomalaiset) talletukset pankeissa",
      "Liikkeeseen laskettu setelistö",
      "Liikkeeseen lasketut kolikot",
    ],
    data: [
      {
        name: 1920,
        line1: 0.24,
        line2: 32.31,
        line3: 9.81,
      },
      {
        name: 1921,
        line1: 0.16,
        line2: 29.23,
        line3: 8.42,
      },
      {
        name: 1922,
        line1: 0.25,
        line2: 29.72,
        line3: 8.03,
      },
      {
        name: 1923,
        line1: 0.24,
        line2: 30.65,
        line3: 7.12,
      },
      {
        name: 1924,
        line1: 0.11,
        line2: 31.56,
        line3: 6.21,
      },
      {
        name: 1925,
        line1: 0.11,
        line2: 32.83,
        line3: 6.03,
      },
      {
        name: 1926,
        line1: 0.27,
        line2: 36.05,
        line3: 5.95,
      },
      {
        name: 1927,
        line1: 0.27,
        line2: 37.79,
        line3: 5.98,
      },
      {
        name: 1928,
        line1: 0.16,
        line2: 39.6,
        line3: 5.53,
      },
      {
        name: 1929,
        line1: 0.04,
        line2: 42.48,
        line3: 5.13,
      },
      {
        name: 1930,
        line1: 0,
        line2: 48.56,
        line3: 5.33,
      },
      {
        name: 1931,
        line1: 0.03,
        line2: 53.3,
        line3: 6.07,
      },
      {
        name: 1932,
        line1: 0,
        line2: 51.94,
        line3: 5.05,
      },
      {
        name: 1933,
        line1: 1.23,
        line2: 50.65,
        line3: 5.13,
      },
      {
        name: 1934,
        line1: 0.52,
        line2: 48.08,
        line3: 4.89,
      },
      {
        name: 1935,
        line1: 1.15,
        line2: 48.56,
        line3: 5.02,
      },
      {
        name: 1936,
        line1: 1.52,
        line2: 48.69,
        line3: 5.38,
      },
      {
        name: 1937,
        line1: 2.54,
        line2: 47.74,
        line3: 5.75,
      },
      {
        name: 1938,
        line1: 2.04,
        line2: 49.82,
        line3: 5.42,
      },
      {
        name: 1939,
        line1: 1.04,
        line2: 51,
        line3: 10.79,
      },
      {
        name: 1940,
        line1: 1.23,
        line2: 54.13,
        line3: 12.95,
      },
      {
        name: 1941,
        line1: 1.77,
        line2: 47.87,
        line3: 13.78,
      },
      {
        name: 1942,
        line1: 1.43,
        line2: 46.5,
        line3: 14.61,
      },
      {
        name: 1943,
        line1: 0.6,
        line2: 46.21,
        line3: 12.83,
      },
      {
        name: 1944,
        line1: 0.58,
        line2: 50.72,
        line3: 16.54,
      },
      {
        name: 1945,
        line1: 1.78,
        line2: 44.75,
        line3: 9.33,
      },
      {
        name: 1946,
        line1: 0.63,
        line2: 33.86,
        line3: 8.33,
      },
      {
        name: 1947,
        line1: 0.94,
        line2: 31.59,
        line3: 8.46,
      },
      {
        name: 1948,
        line1: 0.34,
        line2: 26.44,
        line3: 6.62,
      },
      {
        name: 1949,
        line1: 0.41,
        line2: 30.91,
        line3: 6.67,
      },
      {
        name: 1950,
        line1: 0.22,
        line2: 28.66,
        line3: 6.28,
      },
      {
        name: 1951,
        line1: 0.34,
        line2: 27.38,
        line3: 5.27,
      },
      {
        name: 1952,
        line1: 0.25,
        line2: 29.57,
        line3: 5.57,
      },
      {
        name: 1953,
        line1: 0.26,
        line2: 34.23,
        line3: 5.52,
      },
      {
        name: 1954,
        line1: 0.31,
        line2: 36.02,
        line3: 5.3,
      },
      {
        name: 1955,
        line1: 0.84,
        line2: 37.05,
        line3: 5.58,
      },
      {
        name: 1956,
        line1: 0.13,
        line2: 34.51,
        line3: 5.46,
      },
      {
        name: 1957,
        line1: 0.2,
        line2: 32.32,
        line3: 4.98,
      },
      {
        name: 1958,
        line1: 0.11,
        line2: 33.82,
        line3: 4.94,
      },
      {
        name: 1959,
        line1: 0.28,
        line2: 37.15,
        line3: 4.89,
      },
      {
        name: 1960,
        line1: 0.11,
        line2: 37.81,
        line3: 4.49,
      },
      {
        name: 1961,
        line1: 0.27,
        line2: 38.21,
        line3: 4.56,
      },
      {
        name: 1962,
        line1: 0.25,
        line2: 38.76,
        line3: 3.94,
      },
      {
        name: 1963,
        line1: 0.02,
        line2: 38.52,
        line3: 4.42,
      },
      {
        name: 1964,
        line1: 0.28,
        line2: 38.26,
        line3: 4.16,
      },
      {
        name: 1965,
        line1: 0.08,
        line2: 38.53,
        line3: 3.86,
      },
      {
        name: 1966,
        line1: 0.11,
        line2: 40.43,
        line3: 3.87,
      },
      {
        name: 1967,
        line1: 0.6,
        line2: 40.32,
        line3: 3.36,
      },
      {
        name: 1968,
        line1: 0.5,
        line2: 39.43,
        line3: 3.23,
      },
      {
        name: 1969,
        line1: 0.26,
        line2: 39.11,
        line3: 3.17,
      },
      {
        name: 1970,
        line1: 0.45,
        line2: 39.87,
        line3: 2.94,
      },
      {
        name: 1971,
        line1: 0.02,
        line2: 41.28,
        line3: 2.94,
      },
      {
        name: 1972,
        line1: 0,
        line2: 41.93,
        line3: 2.95,
      },
      {
        name: 1973,
        line1: 0.02,
        line2: 39.81,
        line3: 2.67,
      },
      {
        name: 1974,
        line1: 0.01,
        line2: 36.99,
        line3: 2.51,
      },
      {
        name: 1975,
        line1: 0.03,
        line2: 36.71,
        line3: 2.43,
      },
      {
        name: 1976,
        line1: 0.93,
        line2: 35.4,
        line3: 2.15,
      },
      {
        name: 1977,
        line1: 0.89,
        line2: 35.97,
        line3: 2.15,
      },
      {
        name: 1978,
        line1: 1.69,
        line2: 37.28,
        line3: 2.36,
      },
      {
        name: 1979,
        line1: 1.76,
        line2: 37.99,
        line3: 2.33,
      },
      {
        name: 1980,
        line1: 1.87,
        line2: 37.59,
        line3: 2.28,
        line4: 0.16,
      },
      {
        name: 1981,
        line1: 1.68,
        line2: 38.36,
        line3: 2.28,
        line4: 0.16,
      },
      {
        name: 1982,
        line1: 1.88,
        line2: 38.92,
        line3: 2.19,
        line4: 0.15,
      },
      {
        name: 1983,
        line1: 2.01,
        line2: 39.84,
        line3: 2.12,
        line4: 0.15,
      },
      {
        name: 1984,
        line1: 3.74,
        line2: 40.94,
        line3: 2.12,
        line4: 0.18,
      },
      {
        name: 1985,
        line1: 3.51,
        line2: 44.13,
        line3: 2.11,
        line4: 0.18,
      },
      {
        name: 1986,
        line1: 2.61,
        line2: 43.96,
        line3: 2.11,
        line4: 0.18,
      },
      {
        name: 1987,
        line1: 2.92,
        line2: 45.92,
        line3: 2.26,
        line4: 0.18,
      },
      {
        name: 1988,
        line1: 4.22,
        line2: 50.27,
        line3: 2.32,
        line4: 0.18,
      },
      {
        name: 1989,
        line1: 5.15,
        line2: 48.45,
        line3: 2.36,
        line4: 0.18,
      },
      {
        name: 1990,
        line1: 3.3,
        line2: 47.87,
        line3: 2.48,
        line4: 0.18,
      },
      {
        name: 1991,
        line1: 2.43,
        line2: 53.22,
        line3: 2.58,
        line4: 0.2,
      },
      {
        name: 1992,
        line1: 3.97,
        line2: 54.35,
        line3: 2.62,
        line4: 0.21,
      },
      {
        name: 1993,
        line1: 1.61,
        line2: 54.54,
        line3: 2.64,
        line4: 0.25,
      },
      {
        name: 1994,
        line1: 1.47,
        line2: 49.55,
        line3: 2.35,
        line4: 0.26,
      },
      {
        name: 1995,
        line1: 5.21,
        line2: 51.93,
        line3: 2.37,
        line4: 0.25,
      },
      {
        name: 1996,
        line1: 1.37,
        line2: 48.66,
        line3: 2.48,
        line4: 0.25,
      },
      {
        name: 1997,
        line1: 1.62,
        line2: 46.53,
        line3: 2.42,
        line4: 0.24,
      },
      {
        name: 1998,
        line1: 1.81,
        line2: 44.23,
        line3: 2.2,
        line4: 0.23,
      },
      {
        name: 1999,
        line1: 3.85,
        line2: 49.67,
        line3: 2.37,
        line4: 0.23,
      },
      {
        name: 2000,
        line1: 1.81,
        line2: 47.8,
        line3: 2.19,
        line4: 0.22,
      },
      {
        name: 2001,
        line1: 2.84,
        line2: 45.31,
        line3: 1.64,
        line4: 0.18,
      },
      {
        name: 2002,
        line1: 2.53,
        line2: 46.77,
        line3: 1.98,
        line4: 0.16,
      },
      {
        name: 2003,
        line1: 1.41,
        line2: 48.34,
        line3: 2.39,
        line4: 0.19,
      },
      {
        name: 2004,
        line1: 1.99,
        line2: 48.24,
        line3: 2.81,
        line4: 0.2,
      },
      {
        name: 2005,
        line1: 2.15,
        line2: 49.15,
        line3: 3.31,
        line4: 0.22,
      },
      {
        name: 2006,
        line1: 2.18,
        line2: 48.28,
        line3: 3.75,
        line4: 0.22,
      },
      {
        name: 2007,
        line1: 3.91,
        line2: 51.05,
        line3: 3.97,
        line4: 0.22,
      },
      {
        name: 2008,
        line1: 3.48,
        line2: 55.23,
        line3: 4.34,
        line4: 0.23,
      },
      {
        name: 2009,
        line1: 4.55,
        line2: 59.58,
        line3: 5.18,
        line4: 0.26,
      },
      {
        name: 2010,
        line1: 11.53,
        line2: 61.68,
        line3: 5.58,
        line4: 0.26,
      },
      {
        name: 2011,
        line1: 36.21,
        line2: 62.54,
        line3: 5.64,
        line4: 0.26,
      },
      {
        name: 2012,
        line1: 31.78,
        line2: 62.38,
        line3: 5.87,
        line4: 0.26,
      },
      {
        name: 2013,
        line1: 13.62,
        line2: 62.62,
        line3: 5.96,
        line4: 0.27,
      },
      {
        name: 2014,
        line1: 7.44,
        line2: 64.11,
        line3: 6.25,
        line4: 0.27,
      },
      {
        name: 2015,
        line1: 21.26,
        line2: 67.92,
        line3: 6.47,
        line4: 0.27,
      },
      {
        name: 2016,
        line1: 21.93,
        line2: 67.63,
        line3: 6.72,
        line4: 0.27,
      },
      {
        name: 2017,
        line1: 34.1,
        line2: 68.36,
        line3: 6.84,
        line4: 0.26,
      },
      {
        name: 2018,
        line1: 31.93,
        line2: 67.7,
        line3: 6.93,
        line4: 0.25,
      },
      {
        name: 2019,
        line1: 36.75,
        line2: 68.7,
        line3: 7.01,
        line4: 0.25,
      },
      {
        name: 2020,
        line1: 46.81,
        line2: 79.53,
        line3: 7.3,
        line4: 0.25,
      },
      {
        name: 2021,
        line1: 49.68,
        line2: 79.72,
        line3: 6.87,
        line4: 0.23,
      },
    ],
  },
  {
    name: "kuvio-7.9",
    width: "100%",
    yDomain2: [0, 2000],
    yDomain: [0, 40],
    xDomain: ["dataMin", "dataMax"],
    xAxisLabel: "Vuosi",
    yAxisLabel: "Köyhyys (%)",
    yAxisLabel2: "Miljoonaa",
    yAxisOffset: 50,
    yAxisOffset2: -40,
    type: ["category", "number"],
    yTickCount: 15,
    xTickCount: 15,
    minTickGapX: 17,
    rightYAxis: true,
    margins: [0, 40, 15, 0],
    yAxisWidth: 50,
    y2AxisWidth: 60,
    manualTicksY2: [0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000],
    manualTicksY: [0, 5, 10, 15, 20, 25, 30, 35, 40],
    whichYId: [1, 0],
    names: [
      "Ihmisten määrä, jotka elävät alle 1,90 dollarilla päivässä",
      "Alle 1,90 dollarilla elävien osuus koko väestöstä",
    ],
    data: [
      {
        name: 1990,
        line1: 1895,
        line2: 35.9,
      },
      {
        name: 1993,
        line1: 1878,
        line2: 33.9,
      },
      {
        name: 1996,
        line1: 1703,
        line2: 29.4,
      },
      {
        name: 1999,
        line1: 1729,
        line2: 28.6,
      },
      {
        name: 2002,
        line1: 1610,
        line2: 25.7,
      },
      {
        name: 2005,
        line1: 1352,
        line2: 20.8,
      },
      {
        name: 2008,
        line1: 1223,
        line2: 18.1,
      },
      {
        name: 2011,
        line1: 963,
        line2: 13.7,
      },
      {
        name: 2013,
        line1: 804,
        line2: 11.2,
      },
      {
        name: 2015,
        line1: 736,
        line2: 10,
      },
    ],
  },
]
